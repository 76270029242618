import * as React from "react"
import ReactDOM from "react-dom"
import { useEvent } from "react-use"
import { useMeasure } from "@digits-shared/hooks/useMeasure"
import { useModalRoot } from "@digits-shared/hooks/useModalRoot"
import styled, { css } from "styled-components"
import zIndexes from "src/shared/config/zIndexes"

const Mask = styled.div`
  z-index: ${zIndexes.modalOverlay + 1};
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
`

const FixedPosition = styled.div<ModalPosition>`
  position: fixed;
  z-index: ${zIndexes.modalOverlay + 1};

  ${({ top, left }) => css`
    top: ${top}px;
    left: ${left}px;
  `}
`

interface ModalPosition {
  top: number
  left: number
}

export const DateRangePortal: React.FC<React.PropsWithChildren<{ position?: ModalPosition }>> = ({
  position,
  children,
}) => {
  const modalRoot = useModalRoot()
  if (!position) return null

  const { top, left } = position
  return (
    <>
      {ReactDOM.createPortal(
        <Mask>
          <FixedPosition top={top} left={left}>
            {children}
          </FixedPosition>
        </Mask>,
        modalRoot
      )}
    </>
  )
}

export function useDateRangePortalState(positionRef: React.RefObject<HTMLElement | null>) {
  const [selectorRef, { height, width }] = useMeasure<HTMLDivElement>()
  const [position, setPosition] = React.useState<ModalPosition | undefined>(undefined)

  const positionPortal = React.useCallback(() => {
    if (positionRef.current) {
      let { bottom, left } = positionRef.current.getBoundingClientRect()
      if (left + width > window.innerWidth) {
        left = window.innerWidth - width - 20
      }

      if (bottom + height > window.innerHeight) {
        bottom = window.innerHeight - height - 30
      }

      setPosition({
        top: bottom,
        left: left,
      })
    }
  }, [height, positionRef, width])

  useEvent("resize", positionPortal)

  React.useEffect(() => {
    positionPortal()
  }, [positionPortal])

  return React.useMemo(() => ({ selectorRef, position }), [position, selectorRef])
}
