import * as React from "react"
import { useEscapeKeyCapture } from "@digits-shared/hooks/useEscapeKeyCapture"
import colors from "@digits-shared/themes/colors"
import styled from "styled-components"
import { ActionItemsHeader } from "src/frontend/components/Shared/NavSidebar/ActionItems/ActionItemsHeader"
import {
  ActionItemsContext,
  type GroupView,
} from "src/frontend/components/Shared/NavSidebar/ActionItems/ActionItemsSidebarContext"
import { GroupedActionItemsList } from "src/frontend/components/Shared/NavSidebar/ActionItems/List"
import { SIDEBAR_EXPANSION_WIDTH } from "src/frontend/components/Shared/NavSidebar/constants"

/*
  STYLES
*/

const ActionItemsContainer = styled.div`
  width: ${SIDEBAR_EXPANSION_WIDTH}px;
  height: 100%;
  border-radius: 0 16px 16px 0;
  box-shadow: inset 34px 0px 40px -21px ${colors.translucentSecondary10};
  display: flex;
  flex-direction: column;
`

/*
  COMPONENTS
*/

export const ActionItemsSidebarContent: React.FC<{ hideSidebar: () => void }> = ({
  hideSidebar,
}) => {
  const [groupView, setGroupView] = React.useState<GroupView>("Assigned")
  const state = React.useMemo(() => ({ groupView }), [groupView])

  useEscapeKeyCapture(
    hideSidebar,
    // needs to be nested within document to capture the escape event before DetailsView
    { target: document?.body }
  )

  return (
    <ActionItemsContext.Provider value={state}>
      <ActionItemsContainer>
        <ActionItemsHeader groupView={groupView} setGroupView={setGroupView} />
        <GroupedActionItemsList hideSidebar={hideSidebar} />
      </ActionItemsContainer>
    </ActionItemsContext.Provider>
  )
}
