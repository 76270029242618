import * as React from "react"
import colorHelper from "@digits-shared/helpers/colorHelper"
import { useIsPrintTheme } from "@digits-shared/themes"
import colors from "@digits-shared/themes/colors"
import styled from "styled-components"
import {
  STATEMENT_HOVERED_COLUMN_CLASSNAME,
  STATEMENT_WIDE_CONTAINER_SIZE,
} from "src/frontend/components/Shared/Reports/Report/Components/Statements/shared"

/*
  STYLES
*/

const Grid = styled.div`
  display: grid;
  grid-template-columns: var(--statement-grid-template);
`

export const HighlightColumn = styled.div`
  &:after {
    border-radius: 6px;
    position: absolute;
    top: var(--statement-header-height);
    width: calc(var(--statement-cell-width) + 8px);
    transform: translateX(-4px);
    transition: background-color 10ms 100ms;
    z-index: 1;
    pointer-events: none;
    background-color: ${colorHelper.hexToRgba(colors.secondary, 0.05)};
  }
  &${"." + STATEMENT_HOVERED_COLUMN_CLASSNAME}::after {
    content: "";
    height: calc(var(--statement-rows-height) - var(--statement-header-height) - 5px);
  }
`

const StickyColumn = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  height: 0;
  z-index: 2;
`

const StickyHeader = styled(StickyColumn)`
  z-index: 4;
`

const StickyGrid = styled(Grid)`
  position: relative;
  pointer-events: none;

  @container (max-width: ${STATEMENT_WIDE_CONTAINER_SIZE}px) {
    & {
      display: none;
    }
  }
`

const StickyBackground = styled.div`
  margin-right: 4px;
  background: ${colors.white90};
`

const StickyHeaderBackground = styled(StickyBackground)`
  height: var(--statement-header-height);
`

const StickyColumnBackground = styled(StickyBackground)`
  height: var(--statement-rows-height);
  box-shadow: -2px 4px 25px ${colorHelper.hexToRgba(colors.primary, 0.05)};

  display: none;
  @container (min-width: ${STATEMENT_WIDE_CONTAINER_SIZE}px) {
    .scroll-left & {
      display: block;
      box-shadow: -2px 4px 25px ${colorHelper.hexToRgba(colors.primary, 0.2)};
    }
  }
`

/*
  INTERFACES
*/

interface HighlightsProps extends React.JSX.IntrinsicAttributes {
  totalRows: number
  totalColumns: number
}

/*
  COMPONENTS
*/

/**
 * Renders highlight columns across all rows in the statement to create a crosshair effect.
 * When a cell is hovered, its entire column is highlighted to help users track values
 * across different rows. This creates a visual guide that makes it easier to follow
 * data in the same column, especially in dense financial statements.
 *
 * The component creates a grid of highlight columns that match the statement's structure,
 * with each column becoming visible when its corresponding cells are hovered.
 */
export const StatementColumnHighlights = React.forwardRef<HTMLDivElement, HighlightsProps>(
  ({ totalColumns, totalRows }, ref) => {
    if (useIsPrintTheme() || !totalRows || !totalColumns) return null

    return (
      <>
        <StatementStickyBackground ref={ref} totalRows={totalRows} totalColumns={totalColumns} />
        <Grid>
          {Array.from({ length: totalColumns + 2 }).map((_, index) => (
            <HighlightColumn key={index} />
          ))}
        </Grid>
      </>
    )
  }
)

/**
 * Creates a background layer between scrollable cells and sticky cells in the statement.
 * This prevents scrolled content from bleeding through sticky columns by providing
 * a solid background and shadow effect when scrolling horizontally.
 *
 * The component uses a small delay on mount to ensure proper height calculation,
 * as the sticky columns need to be excluded from the flow to avoid affecting
 * the overall height of the statement.
 */
const StatementStickyBackground = React.forwardRef<HTMLDivElement, HighlightsProps>(
  ({ totalColumns }, ref) => (
    <>
      <StickyColumn>
        <StickyGrid>
          <StickyColumnBackground />
          {Array.from({ length: totalColumns + 1 }).map((_, index) => (
            <div key={index} />
          ))}
        </StickyGrid>
      </StickyColumn>
      <StickyHeader ref={ref}>
        <StickyGrid>
          <StickyHeaderBackground />
          {Array.from({ length: totalColumns + 1 }).map((_, index) => (
            <div key={index} />
          ))}
        </StickyGrid>
      </StickyHeader>
    </>
  )
)
