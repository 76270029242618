import * as React from "react"
import {
  ActionItemKind,
  type ActionItemThreadFieldsFragment,
  type EntityUser,
  type ObjectIdentifier,
  ObjectKind,
} from "@digits-graphql/frontend/graphql-bearer"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import stringHelper from "@digits-shared/helpers/stringHelper"
import userHelper from "@digits-shared/helpers/userHelper"
import useSession from "@digits-shared/hooks/useSession"
import { ThreadActions } from "src/frontend/components/Shared/NavSidebar/ActionItems/Row/ActionItemActions"
import { ActionItemRow } from "src/frontend/components/Shared/NavSidebar/ActionItems/Row/ActionItemRow"
import { NameDateDetail } from "src/frontend/components/Shared/NavSidebar/ActionItems/Row/NameAndDate"
import type FrontendSession from "src/frontend/session"
import { useCurrentAffiliation } from "src/shared/hooks/useCurrentAffiliation"

/*
  COMPONENTS
*/

export const ThreadRow: React.FC<{
  actionItemId: string
  date: string
  author?: EntityUser | null
  threadId: string
  targetEntity: ActionItemThreadFieldsFragment["targetEntity"]
}> = ({ actionItemId, date, author, threadId, targetEntity }) => {
  const currentAffiliation = useCurrentAffiliation()
  const { currentLegalEntityId, user } = useSession<FrontendSession>()

  const isAuthorAffiliated = React.useMemo(() => {
    if (!currentAffiliation || !author) return true // Assume the common case.
    return currentAffiliation?.affiliatedUsers.some((affUser) => affUser.user.id === author.id)
  }, [author, currentAffiliation])

  const { title, iconUrl, categoryName, targetObject } = processThreadTarget(
    currentLegalEntityId,
    targetEntity,
    isAuthorAffiliated
  )

  return (
    <>
      <ActionItemRow
        title={title}
        actionItemKind={ActionItemKind.Comment}
        categoryName={categoryName}
        party={{
          name: author ? userHelper.displayName(author) : undefined,
          iconUrl: iconUrl || author?.avatarUrl,
        }}
      >
        {author && (
          <NameDateDetail
            date={date}
            secondary={
              user.id === author?.id ? "You" : `${userHelper.displayName(author, "abbreviateLast")}`
            }
          />
        )}
      </ActionItemRow>
      {targetObject && (
        <ThreadActions
          actionItemId={actionItemId}
          threadId={threadId}
          targetObject={targetObject}
        />
      )}
    </>
  )
}

function processThreadTarget(
  legalEntityId: string,
  targetEntity: ActionItemThreadFieldsFragment["targetEntity"],
  isAuthorAffiliated: boolean
): {
  title: string
  categoryName?: string | null
  iconUrl?: string | null
  targetObject?: ObjectIdentifier
} {
  switch (targetEntity.__typename) {
    case "EntityCategory":
      return {
        title: targetEntity.name ? `Commented on ${targetEntity.name}` : "Commented on a category",
        categoryName: targetEntity.name,
        targetObject: { kind: ObjectKind.Category, id: targetEntity.id, legalEntityId },
      }
    case "EntityParty":
      return {
        title: targetEntity.name ? `Commented on ${targetEntity.name}` : "Commented on a party",
        iconUrl: targetEntity.iconUrl,
        targetObject: { kind: ObjectKind.Party, id: targetEntity.id, legalEntityId },
      }
    case "EntityReportPackage":
      return {
        title: targetEntity.title ? `Commented on ${targetEntity.title}` : "Commented on a report",
        targetObject: { kind: ObjectKind.ReportPackage, id: targetEntity.id, legalEntityId },
      }
    case "EntityLedgerTransaction": {
      const transactionMoment = dateTimeHelper.dayjsFromTimestamp(targetEntity.timestamp)
      return {
        title: transactionMoment
          ? `Commented on a transaction from ${transactionMoment.format("MMMM D")}`
          : "Commented on a transaction",
        targetObject: { kind: ObjectKind.LedgerTransaction, id: targetEntity.id, legalEntityId },
      }
    }
    case "EntityWorkItem": {
      const verb = isAuthorAffiliated ? "Asked" : "Replied to"
      return {
        title: targetEntity.objectTransaction.party
          ? `${verb} a question on ${stringHelper.getIndefiniteArticle(targetEntity.objectTransaction.party?.name)} ${targetEntity.objectTransaction.party?.name} transaction`
          : `${verb} a question on a transaction`,
        iconUrl: targetEntity.objectTransaction.party?.iconUrl,
        targetObject: { kind: ObjectKind.WorkItem, id: targetEntity.id, legalEntityId },
      }
    }
    case "EntityDepartment": {
      return {
        title: targetEntity.name
          ? `Commented on ${targetEntity.name}`
          : "Commented on a department",
        targetObject: { kind: ObjectKind.Department, id: targetEntity.id, legalEntityId },
      }
    }
    case "EntityLocation": {
      return {
        title: targetEntity.name ? `Commented on ${targetEntity.name}` : "Commented on a location",
        targetObject: { kind: ObjectKind.Location, id: targetEntity.id, legalEntityId },
      }
    }
  }
  return { title: "New Comment" }
}
