import * as React from "react"
import { CategoryType } from "@digits-graphql/frontend/graphql-bearer"
import { InvertValuesContext } from "@digits-shared/components/Contexts/InvertValuesContext"
import { Amount } from "@digits-shared/components/UI/Table/Content"
import { DigitsTooltip } from "@digits-shared/DesignSystem/Tooltip"
import stringHelper from "@digits-shared/helpers/stringHelper"
import styled from "styled-components"
import { type ComponentSize } from "src/frontend/components/Shared/Layout/ComponentSize"
import { useConfigOriginOverride } from "src/frontend/components/Shared/Layout/hooks/useConfigOriginOverride"
import {
  type MatchedComponent,
  type SizingProps,
} from "src/frontend/components/Shared/Layout/types"
import { Title } from "src/frontend/components/Shared/Portals/Components/Lists/Shared"
import { useTopTransactionsLiveData } from "src/frontend/components/Shared/Portals/hooks/useTopTransactionsLiveData"
import {
  Date,
  Description,
  Title as RowTitle,
  TopTransactionsList,
} from "src/frontend/components/Shared/Reports/Report/Viewer/Layout/Shared/TopTransactionsList"
import { useTextIsOverflowing } from "src/frontend/hooks/useTextIsOverflowing"
import { FrontendPartyRole } from "src/frontend/types/FrontendPartyRole"
import { invertValuesForCategoryType } from "src/shared/helpers/categoryHelper"

const StyledTopTransactionsList = styled(TopTransactionsList)`
  ${RowTitle}, ${Amount} {
    color: #0c404b;
  }

  ${Description} {
    color: #0c404b99;
  }

  ${Date} {
    color: #0c404b99;
  }
`

/*
  INTERFACES
*/

export type TopTransasctionsProps = SizingProps & {
  component: MatchedComponent<"topTransactions">
  componentSize: ComponentSize
}

/*
  COMPONENTS
*/

export const TopTransactions: React.FC<TopTransasctionsProps> = ({ component, componentSize }) => {
  const config = useConfigOriginOverride(component.config.topTransactions, 1)
  const { textElementRef, isTextOverflowing } = useTextIsOverflowing<HTMLDivElement>()

  const role = React.useMemo(
    () => (config.partyRole ? FrontendPartyRole.findByRole(config.partyRole) : undefined),
    [config.partyRole]
  )

  const invertValues = React.useMemo(() => {
    if (role) {
      return role?.invertValues ?? false
    }

    return invertValuesForCategoryType(config.categoryType ?? CategoryType.UnknownType)
  }, [config.categoryType, role])

  const countDisplay = React.useMemo(() => {
    if (role) {
      return stringHelper.pluralize(
        config.limit,
        `${role.displayName} Transaction`,
        `${role.pluralDisplayName} Transactions`
      )
    }

    if (config.categoryType) {
      const typeName = stringHelper.camelCaseToSpaces(config.categoryType, true)

      return stringHelper.pluralize(
        config.limit,
        `${typeName} Transaction`,
        `${typeName} Transactions`
      )
    }

    return undefined
  }, [config.categoryType, config.limit, role])

  const { data, loading } = useTopTransactionsLiveData(config, component.config.type)

  if (!countDisplay) return null

  return (
    <InvertValuesContext.Provider value={invertValues}>
      <DigitsTooltip
        css="width: unset"
        content={<div>Top {countDisplay}</div>}
        disabled={!isTextOverflowing}
      >
        <Title ref={textElementRef} componentSize={componentSize}>
          Top {countDisplay}
        </Title>
      </DigitsTooltip>
      <StyledTopTransactionsList padding={10} transactions={data?.transactions} loading={loading} />
    </InvertValuesContext.Provider>
  )
}
