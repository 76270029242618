import React from "react"
import {
  ActionItemTargetMode,
  DirectionFromOrigin,
  Interval,
  useListActionItemsBadgeCountsQuery,
  useNotificationCreatedSubscription,
} from "@digits-graphql/frontend/graphql-bearer"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import useSession from "@digits-shared/hooks/useSession"
import { STATUS_OPEN } from "src/frontend/components/Shared/NavSidebar/ActionItems/constants"
import type FrontendSession from "src/frontend/session/FrontendSession"

export function useActionItemsBadgeCountsQuery(legalEntityIds: string[], skip?: boolean) {
  const variables = useActionItemsBadgeCountsQueryVariables(legalEntityIds)
  const result = useListActionItemsBadgeCountsQuery({
    variables,
    skip: skip || !legalEntityIds.length,
  })

  return React.useMemo(
    () => ({
      counts: result.data?.listActionItemsBadgeCounts,
      loading: result.loading,
      refetch: result.refetch,
    }),
    [result]
  )
}

export function useActionItemsBadgeCountsQueryVariables(legalEntityIdOrIds: string | string[]) {
  return React.useMemo(
    () => ({
      origin: dateTimeHelper.todayIntervalOrigin(Interval.Month, 48),
      direction: DirectionFromOrigin.Past,
      legalEntityIds: Array.isArray(legalEntityIdOrIds) ? legalEntityIdOrIds : [legalEntityIdOrIds],
      filter: {
        status: STATUS_OPEN,
        includeMode: [ActionItemTargetMode.Assign],
      },
    }),
    [legalEntityIdOrIds]
  )
}

export function useCurrentLegalEntityActionItemsBadgeCount() {
  const { currentLegalEntity } = useSession<FrontendSession>()
  const legalEntityIds = React.useMemo(
    () => (currentLegalEntity ? [currentLegalEntity.id] : []),
    [currentLegalEntity]
  )
  const { counts, refetch } = useActionItemsBadgeCountsQuery(legalEntityIds)
  useNotificationCreatedSubscription({
    onData: (subscriptionData) => {
      if (
        currentLegalEntity &&
        subscriptionData.data.data?.notificationCreated.id &&
        subscriptionData.data.data.notificationCreated.legalEntityId === currentLegalEntity.id
      ) {
        refetch()
      }
    },
  })
  // when there are no action items the counts array will be empty
  return counts?.[0]?.count ?? 0
}
