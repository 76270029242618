import React from "react"
import { type Assignee, ObjectKind } from "@digits-graphql/frontend/graphql-bearer"
import { svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgChevronDown } from "@digits-shared/components/SVGIcons/line/ChevronDown.svg"
import { Checkbox } from "@digits-shared/DesignSystem/FormElements/Checkbox"
import objectHelper from "@digits-shared/helpers/objectHelper"
import { useOnBodyClick } from "@digits-shared/hooks/useOnBodyClick"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled from "styled-components"
import useThreadContext from "src/shared/components/Comments/ThreadContext"
import {
  isOrganizationAssignee,
  isUserAssignee,
  useInferAssignee,
} from "src/shared/components/Comments/useInferAssignee"
import { ContentDecorator } from "src/shared/components/EditableContent/ContentDecorator"
import {
  isTypeaheadOrganization,
  type UserWithOrgTypeaheadResult,
} from "src/shared/components/Typeahead/sharedTypeahead"
import { Typeahead } from "src/shared/components/Typeahead/Typeahead"
import { useTypeaheadState } from "src/shared/components/Typeahead/useTypeaheadState"

/*
 STYLES
*/

export const AssigneeTypeaheadContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 3px 0 4px 0;
  margin: 6px 15px 0;
`

const AssigneeContainer = styled.div<{ disabled: boolean }>`
  display: flex;
  gap: 8px;
  align-items: center;
  color: ${colors.secondary};
  font-size: 12px;
  max-width: -webkit-fill-available;

  &[disabled] {
    opacity: 0.5;
    pointer-events: none;
  }
`

const AssigneeSelect = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  overflow: hidden;
`

const AssigneeNameContainer = styled.div`
  display: flex;
  padding: 2px 2px 2px 6px;
  align-items: center;
  gap: 2px;
  border-radius: 4px;
  background: ${colors.secondary10};
  font-weight: ${fonts.weight.heavy};
  cursor: pointer;
  overflow: hidden;
`

const AssigneeName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const EmptyAssignee = styled.div`
  cursor: pointer;
`

const Chevron = styled(SvgChevronDown)`
  width: 16px;
  height: 20px;
  ${svgPathStyles(colors.secondary)};
`

/*
 INTERFACES
*/

export interface AssigneeDelegate {
  getAssignee: () => Assignee | undefined
  onInput: (target: HTMLElement) => void
}

interface AssigneeProps {
  disabled?: boolean
  delegate: React.MutableRefObject<AssigneeDelegate | undefined>
}

/*
 COMPONENTS
*/

export const ThreadAssignee: React.FC<AssigneeProps> = ({ delegate, disabled = false }) => {
  const { targetObject, activeThreadDetails } = useThreadContext()
  const { isTypeaheadShown, onClick, showTypeahead, hideTypeahead, ref } =
    useTypeaheadState(disabled)
  useOnBodyClick(ref, hideTypeahead)

  const { inferredAssignee, toUserAssignee, toOrgAssignee, inferFromCommentTags } =
    useInferAssignee()

  const [userChosen, setUserChosen] = React.useState(false)
  const [assign, setAssign] = React.useState(!!inferredAssignee)
  const [selectedAssignee, setSelectedAssignee] = React.useState(inferredAssignee)

  React.useEffect(() => {
    if (!selectedAssignee && inferredAssignee) {
      setAssign(true)
      setSelectedAssignee(inferredAssignee)
    }
  }, [activeThreadDetails, inferredAssignee, selectedAssignee, userChosen])

  // Set the delegate
  React.useEffect(() => {
    delegate.current = {
      getAssignee: () => (assign ? selectedAssignee : undefined),
      onInput: (target: HTMLElement) => {
        if (userChosen) return

        // if comment box is cleared out, revert to inferred assignee
        if (!target.innerText.trim()) {
          setAssign(!!inferredAssignee)
          return setSelectedAssignee(inferredAssignee)
        }

        const text = ContentDecorator.encode(target)
        const inferred = inferFromCommentTags(text)
        if (inferred && !objectHelper.deepEqual(selectedAssignee, inferred)) {
          setAssign(!!inferred)
          setSelectedAssignee(inferred)
        }
      },
    }
  }, [assign, delegate, inferFromCommentTags, inferredAssignee, selectedAssignee, userChosen])

  const assigneeName = React.useMemo(() => {
    if (isOrganizationAssignee(selectedAssignee)) return selectedAssignee.organization.name
    if (isUserAssignee(selectedAssignee)) return selectedAssignee.user.name
    return undefined
  }, [selectedAssignee])

  const onCheckboxChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.target
      setAssign(checked)
      setUserChosen(true)
      if (checked && !selectedAssignee) {
        showTypeahead()
      }
    },
    [selectedAssignee, showTypeahead]
  )

  const onResultSelected = (data: UserWithOrgTypeaheadResult) => {
    const { entity } = data
    hideTypeahead()
    setUserChosen(true)
    setAssign(true)
    const assignee = isTypeaheadOrganization(entity)
      ? toOrgAssignee(entity.id)
      : toUserAssignee(entity.id)
    setSelectedAssignee(assignee)
  }

  return (
    <AssigneeTypeaheadContainer>
      <AssigneeContainer disabled={disabled}>
        <Checkbox checked={assign} disabled={disabled} onChange={onCheckboxChange} />
        {assigneeName && (
          <AssigneeSelect>
            <div css="white-space: nowrap">Assign to</div>
            <AssigneeNameContainer onClick={onClick}>
              <AssigneeName>{assigneeName}</AssigneeName> <Chevron />
            </AssigneeNameContainer>
          </AssigneeSelect>
        )}
        {!assigneeName && <EmptyAssignee onClick={onClick}>Assign to someone…</EmptyAssignee>}
      </AssigneeContainer>

      {isTypeaheadShown && (
        <Typeahead
          ref={ref}
          placeholder="Find assignee…"
          kind={ObjectKind.User}
          includeOrganization
          objectIdentifier={targetObject}
          onResultSelected={onResultSelected}
          close={hideTypeahead}
        />
      )}
    </AssigneeTypeaheadContainer>
  )
}
