import React from "react"
import { type EntityOrganization, ObjectKind } from "@digits-graphql/frontend/graphql-bearer"
import { defined } from "@digits-shared/helpers/filters"
import useSession from "@digits-shared/hooks/useSession"
import type FrontendSession from "src/frontend/session"
import { type UserWithOrgTypeaheadResult } from "src/shared/components/Typeahead/sharedTypeahead"
import { useCurrentAffiliation } from "src/shared/hooks/useCurrentAffiliation"

type OrgDisplayValues = { name: string; slug: string }

export function useTypeaheadOrganizations(text: string) {
  const {
    currentOrganization,
    isAffiliatedSession,
    currentLegalEntity,
    currentAffiliation: currentSessionAffiliation,
  } = useSession<FrontendSession>()
  const currentAffiliation = useCurrentAffiliation()
  const affiliationOrg = currentAffiliation?.organization
  const legalEntityOrg = isAffiliatedSession
    ? currentSessionAffiliation?.organization // _not_ .entity.organization which can be the affiliate org.
    : currentOrganization
  const org = affiliationOrg?.id && !isAffiliatedSession ? affiliationOrg : legalEntityOrg
  const display = affiliationOrg?.id && !isAffiliatedSession ? affiliationOrg : currentLegalEntity

  return React.useMemo(
    () => ({
      loading: false,
      results: buildOrgResults(text, org, display),
    }),
    [display, org, text]
  )
}

function orgToTypeaheadResult(
  organization: EntityOrganization,
  display: OrgDisplayValues
): UserWithOrgTypeaheadResult {
  return {
    id: organization.id,
    title: display.name,
    subtitle: `@${display.slug}`,
    searchValues: [display.name, display.slug].filter(defined),
    kind: ObjectKind.User, // ObjectKind org does not exist
    entity: organization,
  }
}

function buildOrgResults(
  query: string,
  organization: EntityOrganization | undefined,
  display: OrgDisplayValues
) {
  const searchTerm = query.trim()
  if (!organization) return []

  return [orgToTypeaheadResult(organization, display)].filter((data) =>
    data.searchValues.some((v) => v.toLowerCase().startsWith(searchTerm))
  )
}
