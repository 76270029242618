import * as React from "react"
import { useHighlight } from "src/frontend/components/OS/Details/Shared/hooks/useHighlight"
import {
  SharedBarChartStyles,
  SharedLineChartStyles,
} from "src/frontend/components/OS/Shared/Charts/styles"
import { ChartContainer } from "src/frontend/components/Shared/Layout/Components/Charts/shared"
import { ParentSizedTimeseriesBreakdownChart } from "src/frontend/components/Shared/Layout/Components/Charts/TimeseriesBreakdownChart"
import {
  type Timeseries,
  type TimeseriesValue,
} from "src/frontend/components/Shared/Layout/Components/Charts/toTimeseries"
import {
  ComponentDateSummary,
  ComponentSummary,
} from "src/frontend/components/Shared/Layout/Components/Headers/ComponentSummary"
import { TimeseriesBreakdownChartTile } from "src/frontend/components/Shared/Layout/Components/Tiles/TimeseriesBreakdownChartTile"
import { type ComponentSize } from "src/frontend/components/Shared/Layout/ComponentSize"
import { type SizingProps } from "src/frontend/components/Shared/Layout/types"

/*
  INTERFACES
*/

interface ChartComponentProps extends SizingProps {
  componentSize: ComponentSize
  skipAnimations: boolean
  totalTimeseries: Timeseries
  breakdownTimeseries: Timeseries[]

  title: React.ReactNode | undefined
  icon: React.ReactNode | undefined
  onMouseOver?: (value: TimeseriesValue, index: number) => void
  onMouseOut?: (value?: TimeseriesValue) => void
  onBarClick?: (value: TimeseriesValue, index: number) => void
}

/*
  COMPONENTS
*/

export const BreakdownChartComponent: React.FC<ChartComponentProps> = ({
  title,
  icon,
  onMouseOut,
  onMouseOver,
  onBarClick,
  totalTimeseries,
  breakdownTimeseries,
  componentSize,
  height,
  skipAnimations,
}) => {
  const highlight = useHighlight()
  const [selectedIndex, setSelectedIndex] = React.useState(highlight ?? undefined)

  const handleMouseOver = React.useCallback(
    (value: TimeseriesValue, index: number) => {
      setSelectedIndex(index)
      onMouseOver?.(value, index)
    },
    [onMouseOver]
  )

  const handleMouseOut = React.useCallback(
    (value?: TimeseriesValue) => {
      setSelectedIndex(highlight ?? undefined)
      onMouseOut?.(value)
    },
    [highlight, onMouseOut]
  )

  const handleBarClick = React.useCallback(
    (value: TimeseriesValue, index: number) => {
      setSelectedIndex(index)
      onBarClick?.(value, index)
    },
    [onBarClick]
  )

  if (componentSize.isSmall) {
    return (
      <>
        <ComponentDateSummary
          componentSize={componentSize}
          icon={icon}
          title={title}
          timeseries={totalTimeseries.values}
          selectedIndex={selectedIndex}
        />
        <ChartContainer height={height} width="auto">
          <TimeseriesBreakdownChartTile
            breakdownTimeseries={breakdownTimeseries}
            totalTimeseries={totalTimeseries}
            height={height}
            barChartStyle={SharedBarChartStyles}
            lineChartStyle={SharedLineChartStyles}
            onMouseOut={handleMouseOut}
            onMouseOver={handleMouseOver}
            onClick={handleBarClick}
            selectedIndex={selectedIndex}
          />
        </ChartContainer>
      </>
    )
  }

  return (
    <>
      <ComponentSummary
        componentSize={componentSize}
        icon={icon}
        title={title}
        timeseries={totalTimeseries.values}
        selectedIndex={selectedIndex}
      />
      <ChartContainer height={height} width="auto">
        <ParentSizedTimeseriesBreakdownChart
          breakdownTimeseries={breakdownTimeseries}
          totalTimeseries={totalTimeseries}
          barChartStyle={SharedBarChartStyles}
          lineChartStyle={SharedLineChartStyles}
          onClick={handleBarClick}
          onMouseOut={handleMouseOut}
          onMouseOver={handleMouseOver}
          skipAnimations={skipAnimations}
        />
      </ChartContainer>
    </>
  )
}
