import * as React from "react"
import { type ObjectIdentifier, ObjectKind } from "@digits-graphql/frontend/graphql-bearer"
import { SvgMessageDotsSquare } from "@digits-shared/components/SVGIcons/line/MessageDotsSquare.svg"
import { SvgMessageSquare02 } from "@digits-shared/components/SVGIcons/line/MessageSquare02.svg"
import { SvgMessageSquare02Solid } from "@digits-shared/components/SVGIcons/solid/MessageSquare02Solid.svg"
import {
  EXPANDABLE_CLASS_NAME,
  EXPANDED_CLASS_NAME,
  HOVERABLE_CLASS_NAME,
  SELECTED_CLASS_NAME,
} from "@digits-shared/components/UI/Table"
import useSession from "@digits-shared/hooks/useSession"
import { themedValue } from "@digits-shared/themes"
import colors from "@digits-shared/themes/colors"
import { themedSVGIconStyles, themedSVGPathStyles } from "@digits-shared/themes/svgIconStyles"
import styled, { css } from "styled-components"
import type FrontendSession from "src/frontend/session"

export const MENTION_CLASSNAME = "mention"

/*
 STYLES
*/

const CommentCount = styled.div<{ visible: boolean }>`
  position: absolute;
  bottom: 9px;
  left: 50%;
  transform: translateX(-50%);
  margin-left: 0.5px;
  text-align: center;
  font-size: 9px;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  border-radius: 50px;
`

const iconColor = themedValue({
  light: colors.translucentSecondary80,
  dark: colors.white,
})

export const CommentThreadIcon = styled.div<StyledIconProps>`
  display: inline-block;
  position: relative;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  user-select: none;
  cursor: pointer;

  ${({ hasMentions }) => css`
    color: ${colors.translucentSecondary80};

    .${HOVERABLE_CLASS_NAME}:not(.${EXPANDED_CLASS_NAME}):hover > td & {
      color: ${iconColor};
      fill: ${iconColor};
    }

    .${HOVERABLE_CLASS_NAME}:not(.${EXPANDED_CLASS_NAME}):hover > td &.${MENTION_CLASSNAME} {
      color: ${colors.black};
      fill: ${colors.neonGreen};
    }

    &:hover {
      color: ${iconColor};
      fill: ${iconColor};
    }

    .${EXPANDABLE_CLASS_NAME}.${EXPANDED_CLASS_NAME} > td &:hover {
      color: ${iconColor};
      fill: ${iconColor};
    }

    .${SELECTED_CLASS_NAME} > td & {
      color: ${iconColor};
      fill: ${iconColor};
    }
  `}
  .transaction.${EXPANDED_CLASS_NAME} & {
    opacity: 1;
  }

  tr:hover > td & {
    transition: opacity 300ms;
    opacity: 1;
  }
`

const svgStyles = css`
  height: 18px;
  width: 18px;
`

const CommentIcon = styled(SvgMessageSquare02)`
  ${svgStyles};
  ${themedSVGPathStyles(
    {
      light: colors.translucentSecondary80,
      dark: colors.white,
    },
    1.5
  )};
`

const CommentFirstIcon = styled(SvgMessageDotsSquare)`
  ${svgStyles};
  ${themedSVGPathStyles(
    {
      light: colors.translucentSecondary80,
      dark: colors.white,
    },
    1.5
  )};
`

const MentionedIcon = styled(SvgMessageSquare02Solid)`
  ${svgStyles};
  ${themedSVGIconStyles({
    light: colors.translucentSecondary80,
    dark: colors.white,
  })}
`

/*
 INTERFACE
*/

interface StyledIconProps {
  visible: boolean
  hasMentions: boolean
}

interface IconProps {
  commentCount: number
  tags?: ObjectIdentifier[] | null
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
}

/*
 COMPONENT
*/

export const ThreadIcon: React.FC<IconProps> = ({ commentCount, tags, onClick }) => {
  const session = useSession<FrontendSession>()
  const {
    user: { id: currentUserId },
  } = session

  const displayCount = commentCount > 99 ? "99+" : commentCount
  const hasMentions = !!tags?.find((t) => t.kind === ObjectKind.User && t.id === currentUserId)
  const className = hasMentions ? MENTION_CLASSNAME : ""

  return (
    <CommentThreadIcon
      onClick={onClick}
      hasMentions={hasMentions}
      visible={!!commentCount}
      className={className}
    >
      <StatusIcon commentCount={commentCount} hasMentions={hasMentions} />
      <CommentCount visible={!!commentCount}>{displayCount}</CommentCount>
    </CommentThreadIcon>
  )
}

const StatusIcon: React.FC<{ commentCount: number; hasMentions: boolean }> = ({
  commentCount,
  hasMentions,
}) => {
  if (hasMentions) {
    return <MentionedIcon />
  }

  return commentCount ? <CommentIcon /> : <CommentFirstIcon />
}
