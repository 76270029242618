import React from "react"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled from "styled-components"
import CommentInput from "src/shared/components/Comments/CommentBox/CommentInput"
import {
  type AssigneeDelegate,
  ThreadAssignee,
} from "src/shared/components/Comments/CommentBox/ThreadAssignee"
import useThreadContext from "src/shared/components/Comments/ThreadContext"
import { useCreateThread, useReplyThread } from "src/shared/components/Comments/threadMutationHooks"

/*
 STYLES
*/

const Cancel = styled.div`
  color: ${colors.havelockBlue};
  font-weight: ${fonts.weight.normal};
  font-size: 11px;
  cursor: pointer;
  text-align: left;
  visibility: hidden;
  transition: visibility 250ms 250ms;
  margin-left: auto;
`

const Box = styled.div`
  position: relative;
  margin-top: 16px;

  &:focus-within ${Cancel} {
    visibility: visible;
  }
`

export const User = styled.div`
  flex: 1;
  display: flex;
  gap: 6px;
  align-items: center;
  margin: 0 15px;
`

/*
 INTERFACES
*/

interface BoxProps {
  sendEnabled: boolean
  onPostingStateChange?: (isPosting: boolean) => void
  onSendStateChange?: (enabled: boolean) => void
  inlineSubmit?: boolean
  className?: string
  isLoading?: boolean
}

/*
 COMPONENTS
*/

export const CommentBox: React.FC<BoxProps> = ({
  className,
  sendEnabled,
  onPostingStateChange,
  onSendStateChange,
  inlineSubmit,
  isLoading,
}) => {
  const { canComment } = useThreadContext()
  const { submit, assignee, placeholder } = useSubmitThread()

  if (!canComment) return null

  return (
    <Box className={className}>
      <CommentInput
        placeholder={placeholder}
        onSubmit={submit}
        sendEnabled={sendEnabled}
        onPostingStateChange={onPostingStateChange}
        onSendStateChange={onSendStateChange}
        inlineSubmit={inlineSubmit}
        isLoading={isLoading}
        assigneeDelegate={assignee}
      />

      <ThreadAssignee delegate={assignee} disabled={!sendEnabled || isLoading} />
    </Box>
  )
}

function useSubmitThread() {
  const assignee = React.useRef<AssigneeDelegate>(undefined)
  const { activeThreadId, placeholder } = useThreadContext()
  const { createThread } = useCreateThread(assignee)
  const { replyThread } = useReplyThread(assignee)

  if (!activeThreadId) {
    return { submit: createThread, assignee, placeholder }
  }

  return { submit: replyThread, assignee, placeholder: "Reply and tag others with @…" }
}
