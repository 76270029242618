import * as React from "react"
import styled, { keyframes } from "styled-components"

const customFadeInAndOut = keyframes`
  0% { opacity: 0; }

  10% { opacity: 1; }

  80% { opacity: 0; }

  100% { opacity: 0; }
`

const PendingContainer = styled.div<Props>`
  display: inline-flex;
  align-items: flex-end;
  color: ${(props) => props.color};
  margin-left: 5px;
`

const PendingDot = styled.span`
  margin-top: -5px;
  animation: ${customFadeInAndOut} 1500ms ease-in-out infinite;
`

const PendingDot2 = styled(PendingDot)`
  animation-delay: 200ms;
`

const PendingDot3 = styled(PendingDot)`
  animation-delay: 400ms;
`

interface Props {
  color: string
  className?: string
}

const PendingIcon: React.FC<Props> = ({ color, className }: Props) => (
  <PendingContainer color={color} className={className}>
    <PendingDot>.</PendingDot>
    <PendingDot2>.</PendingDot2>
    <PendingDot3>.</PendingDot3>
  </PendingContainer>
)

export default PendingIcon
