import * as React from "react"
import { ActionItemKind, type EntityUser } from "@digits-graphql/frontend/graphql-bearer"
import userHelper from "@digits-shared/helpers/userHelper"
import { ReportActions } from "src/frontend/components/Shared/NavSidebar/ActionItems/Row/ActionItemActions"
import { ActionItemRow } from "src/frontend/components/Shared/NavSidebar/ActionItems/Row/ActionItemRow"
import { NameDateDetail } from "src/frontend/components/Shared/NavSidebar/ActionItems/Row/NameAndDate"
import { DIGITS_CANONICAL_USER_ID } from "src/shared/config/identifiers"

export const ReportRow: React.FC<{
  actionItemId: string
  title: string
  author?: EntityUser | null
  date: string
}> = ({ actionItemId, title, author, date }) => (
  <>
    <ActionItemRow
      actionItemKind={ActionItemKind.Report}
      title={title || "New Report"}
      party={{
        name: author?.givenName,
        iconUrl: author?.avatarUrl,
      }}
    >
      {author && (
        <NameDateDetail
          date={date}
          secondary={
            author.id === DIGITS_CANONICAL_USER_ID
              ? "Digits AI"
              : userHelper.displayName(author, "abbreviateLast")
          }
        />
      )}
    </ActionItemRow>
    <ReportActions actionItemId={actionItemId} />
  </>
)
