import * as React from "react"

export function useTextIsOverflowing<T extends HTMLElement>(): {
  textElementRef: React.RefObject<T | null>
  textElementDOMRect: DOMRect | undefined
  isTextOverflowing: boolean
} {
  const textElementRef = React.createRef<T>()
  const [isTextOverflowing, setIsTextOverflowing] = React.useState<boolean>(false)
  const [textElementDOMRect, setDOMRect] = React.useState<DOMRect>()

  React.useEffect(() => {
    const { current } = textElementRef
    if (!current) {
      setDOMRect(undefined)
      return setIsTextOverflowing(false)
    }

    const rect = current?.getBoundingClientRect()
    if (
      !textElementDOMRect ||
      textElementDOMRect.width !== rect.width ||
      textElementDOMRect.height !== rect.height
    ) {
      setDOMRect(rect)
    }

    if (current.offsetHeight < current.scrollHeight || current.offsetWidth < current.scrollWidth) {
      setIsTextOverflowing(true)
    } else {
      setIsTextOverflowing(false)
    }
  }, [textElementDOMRect, textElementRef])

  return {
    textElementRef,
    textElementDOMRect,
    isTextOverflowing,
  }
}
