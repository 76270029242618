import * as React from "react"
import { type LayoutComponent } from "@digits-graphql/frontend/graphql-bearer"
import { LazyComponentLoad } from "@digits-shared/components/UI/Elements/LazyComponentLoad"
import { usePopOverState } from "@digits-shared/hooks/usePopOverState"
import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { useInView } from "framer-motion"
import { DropType, TargetKind } from "src/frontend/components/Shared/DragAndDrop/dragAndDropShared"
import { type ComponentSize } from "src/frontend/components/Shared/Layout/ComponentSize"
import {
  CHART_COMPONENT_HEIGHT,
  ComponentWrapper,
  useLazyComponentSize,
} from "src/frontend/components/Shared/Layout/Shared"
import { DeleteComponentStep } from "src/frontend/components/Shared/Layout/types"
import { ComponentHoveredContext } from "src/frontend/components/Shared/Portals/Components/ComponentHoveredContext"
import {
  AnimatedPoof,
  poofSpriteURL,
} from "src/frontend/components/Shared/Portals/Components/DeleteAnimation"
import { MatchComponent } from "src/frontend/components/Shared/Portals/Components/MatchComponent"
import { useComponentDeleteAnimation } from "src/frontend/components/Shared/Portals/Components/useComponentDeleteAnimation"
import { usePortalStore } from "src/frontend/components/Shared/Portals/State/portalStore"
import { useDragAndDropDisabled } from "src/frontend/components/Shared/Portals/State/useDragAndDropDisabled"
import { useIsEditLayoutActive } from "src/frontend/components/Shared/Portals/State/useIsEditLayoutActive"

/*
  INTERFACES
*/

interface DraggableComponentProps {
  className?: string
  component: LayoutComponent
  componentSize: ComponentSize
  width: number
  sectionId: string
  rowId: string
  forceDisabled?: boolean
}

/*
  COMPONENTS
*/

export const DraggableComponent: React.FC<DraggableComponentProps> = ({
  className,
  component,
  componentSize,
  width,
  sectionId,
  rowId,
  forceDisabled,
}) => {
  const globalDisabled = useDragAndDropDisabled()
  const disabled = forceDisabled || globalDisabled
  const wrapperRef = React.useRef<HTMLDivElement | null>(null)
  const inView = useInView(wrapperRef)

  const activeComponentId = usePortalStore((state) => state.activeComponentId)
  const isEditLayoutActive = useIsEditLayoutActive()
  const isDragging = component.componentId === activeComponentId

  const { attributes, listeners, setNodeRef, transform, isSorting, transition } = useSortable({
    id: component.componentId,
    disabled,
    data: {
      id: component.componentId,
      component,
      sectionId,
      rowId,
      targetKind: TargetKind.Component,
      type: DropType.Component,
    },
  })

  const { step, componentCss, onShrinkEnd, onDeleteAnimationEnd } = useComponentDeleteAnimation(
    component.componentId
  )

  const dragAndDropStyle = React.useMemo(
    () => ({
      transform: isSorting ? undefined : CSS.Transform.toString(transform),
      transition,
    }),
    [isSorting, transform, transition]
  )

  const {
    isPopOverOpen: hovered,
    onMouseEnter: setHovered,
    onMouseLeave: unsetHovered,
  } = usePopOverState()
  const componentHoveredContextVal = React.useMemo(
    () => ({
      hovered,
    }),
    [hovered]
  )
  const lazyComponentSize = useLazyComponentSize(component, width)

  return (
    <ComponentHoveredContext.Provider value={componentHoveredContextVal}>
      {/* css prop may have to be applied to another inner element? */}
      <ComponentWrapper
        className={className}
        deleteAnimationStep={step}
        width={width}
        style={dragAndDropStyle}
        ref={(el) => {
          setNodeRef(el)
          wrapperRef.current = el
        }}
        data-component-type={component.config.type}
        isDisabled={disabled}
        isDragging={isDragging}
        onAnimationEnd={onShrinkEnd}
        onMouseEnter={setHovered}
        onMouseLeave={unsetHovered}
        hovered={hovered}
        {...listeners}
        {...attributes}
      >
        {React.useMemo(
          () => (
            <LazyComponentLoad css={lazyComponentSize}>
              <MatchComponent
                css={componentCss}
                rowId={rowId}
                component={component}
                componentSize={componentSize}
                height={CHART_COMPONENT_HEIGHT}
                isDraggable={!activeComponentId && !disabled}
                isDragging={isDragging}
                skipAnimations={isEditLayoutActive || isDragging || !inView}
              />
            </LazyComponentLoad>
          ),
          [
            activeComponentId,
            component,
            componentCss,
            componentSize,
            disabled,
            inView,
            isDragging,
            isEditLayoutActive,
            lazyComponentSize,
            rowId,
          ]
        )}
        {step === DeleteComponentStep.Poof && (
          <AnimatedPoof spriteURL={poofSpriteURL} onAnimationEnd={onDeleteAnimationEnd} />
        )}
      </ComponentWrapper>
    </ComponentHoveredContext.Provider>
  )
}
