import * as React from "react"
import { SharedLineChartStyles } from "src/frontend/components/OS/Shared/Charts/styles"
import { ChartContainer } from "src/frontend/components/Shared/Layout/Components/Charts/shared"
import { ParentSizedTimeseriesLineChart } from "src/frontend/components/Shared/Layout/Components/Charts/TimeseriesLineChart"
import { type TimeseriesValue } from "src/frontend/components/Shared/Layout/Components/Charts/toTimeseries"
import {
  ComponentDateSummary,
  ComponentSummary,
} from "src/frontend/components/Shared/Layout/Components/Headers/ComponentSummary"
import { TimeseriesLineChartTile } from "src/frontend/components/Shared/Layout/Components/Tiles/TimeseriesLineChartTile"
import { type ComponentSize } from "src/frontend/components/Shared/Layout/ComponentSize"
import { type SizingProps } from "src/frontend/components/Shared/Layout/types"

/*
  INTERFACES
*/

interface LineChartComponentProps extends SizingProps {
  componentSize: ComponentSize
  title: React.ReactNode | undefined
  icon: React.ReactNode | undefined
  timeseries: TimeseriesValue[]
  skipAnimations: boolean
  onMouseOver: (value: TimeseriesValue, index: number) => void
  onMouseOut: (value?: TimeseriesValue) => void
  onClick?: (value: TimeseriesValue, index: number) => void
  selectedIndex?: number
}

/*
  COMPONENTS
*/

export const LineChartComponent = React.memo<LineChartComponentProps>(
  ({
    componentSize,
    title,
    icon,
    timeseries,
    height,
    skipAnimations,
    onMouseOver,
    onMouseOut,
    onClick,
    selectedIndex,
  }) => {
    const preselected = selectedIndex ? timeseries[selectedIndex] : undefined
    if (componentSize.isSmall) {
      return (
        <>
          <ComponentDateSummary
            componentSize={componentSize}
            title={title}
            icon={icon}
            timeseries={timeseries}
            selectedIndex={selectedIndex}
          />
          <TimeseriesLineChartTile
            timeseries={timeseries}
            skipAnimations={skipAnimations}
            selectedIndex={selectedIndex}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
            onClick={onClick}
            height={height}
            chartStyle={SharedLineChartStyles}
          />
        </>
      )
    }

    return (
      <>
        <ComponentSummary
          componentSize={componentSize}
          title={title}
          icon={icon}
          timeseries={timeseries}
          selectedIndex={selectedIndex}
        />
        <ChartContainer height={height} width="auto" css="margin: 0 5px">
          <ParentSizedTimeseriesLineChart
            timeseries={timeseries}
            skipAnimations={skipAnimations}
            preselectedValue={preselected}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
            onClick={onClick}
            chartStyle={SharedLineChartStyles}
          />
        </ChartContainer>
      </>
    )
  }
)
