import * as React from "react"
import { type ThreadWithEntities } from "@digits-graphql/frontend/graphql-bearer"
import { ColorIcon } from "@digits-shared/components/UI/Elements/ColorIcon"
import { defined, uniqueBy } from "@digits-shared/helpers/filters"
import useSession from "@digits-shared/hooks/useSession"
import colors from "@digits-shared/themes/colors"
import styled, { css } from "styled-components"
import type FrontendSession from "src/frontend/session"

/*
 STYLES
*/

const BubbleContainer = styled.div<{ hide: boolean }>`
  padding: 3px;
  background: ${colors.white};
  border-radius: 40px;
  width: fit-content;

  ${({ hide }) =>
    hide &&
    css`
      display: none;
    `}
`

/*
 INTERFACES
*/

interface BubbleProps {
  threadWithEntities?: ThreadWithEntities
  loading: boolean
  show: boolean
  onClick: (event: React.MouseEvent<HTMLElement>) => void
}

/*
 COMPONENT
*/
export const ThreadBubble: React.FC<BubbleProps> = ({
  threadWithEntities,
  loading,
  show,
  onClick,
}) => {
  const recipient = useFindInterestingUser(threadWithEntities)
  if (!recipient) return null

  return (
    <BubbleContainer hide={!show} onClick={onClick}>
      <ColorIcon size={32} imageUrl={recipient?.avatarUrl} fallbackUser={recipient} fontSize={15} />
    </BubbleContainer>
  )
}

function useFindInterestingUser(threadWithEntities?: ThreadWithEntities) {
  const {
    user: { id: currentUserId },
  } = useSession<FrontendSession>()
  return React.useMemo(() => {
    const users = threadWithEntities?.entities?.users
    if (!users) return

    const authors = threadWithEntities?.thread?.comments
      .filter(uniqueBy((c) => c.authorId))
      .map((c) => users.find((u) => u.id === c.authorId))
      .filter(defined)

    const currentUser = authors.find((u) => u.id === currentUserId)
    const otherUser = authors.find((u) => u.id !== currentUserId)
    return otherUser || currentUser
  }, [currentUserId, threadWithEntities?.entities?.users, threadWithEntities?.thread?.comments])
}
