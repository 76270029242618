import React from "react"
import {
  EmployeeStatus,
  EmployeeVisibility,
  Interval,
  type ObjectEntities,
  type ThreadDetails,
  type ThreadWithEntities,
  useListEmployeesQuery,
  useReadThreadQuery,
} from "@digits-graphql/frontend/graphql-bearer"
import { SvgCheckCircle } from "@digits-shared/components/SVGIcons/line/CheckCircle.svg"
import dateTimeHelper, { DateFormat } from "@digits-shared/helpers/dateTimeHelper"
import { uniqueBy } from "@digits-shared/helpers/filters"
import useSession from "@digits-shared/hooks/useSession"
import colors from "@digits-shared/themes/colors"
import styled, { css } from "styled-components"
import type FrontendSession from "src/frontend/session"
import { FrontendPermissionModule } from "src/frontend/session/permissionModule"
import { EmptyThread } from "src/shared/components/Comments/EmptyThread"
import { CommentsLoading } from "src/shared/components/Comments/Loading"
import { ThreadBubble } from "src/shared/components/Comments/ThreadBubble"
import { Comment } from "./Comment"
import { CommentBox } from "./CommentBox"
import { Resolve } from "./Resolve"
import useThreadContext from "./ThreadContext"

const MAX_LOADING_ROWS = 13

/*
 STYLES
*/

const ResolveHover = styled(Resolve)<{ show: boolean }>`
  opacity: ${({ show }) => (show ? 1 : 0.2)};
  transition: opacity 200ms;
`

const ThreadContainer = styled.div<{ bubble: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 16px 0;
  ${({ bubble }) =>
    bubble &&
    css`
      overflow: hidden;
      width: 0;
      height: 0;
    `}
  &:hover ${ResolveHover} {
    opacity: 1;
  }
`

export const ThreadList = styled.ul<{ smoothScroll: boolean; disableScroll: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-grow: 1;

  overflow-x: hidden;
  overflow-y: ${({ disableScroll }) => (disableScroll ? "hidden" : "scroll")};

  ${({ smoothScroll }) => smoothScroll && "scroll-behavior: smooth;"}
`

const StyledResolvedAt = styled.li<{ isLastThread: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 24px 0;
  font-size: 13px;
  color: ${colors.lightDark};
  ${({ isLastThread }) =>
    isLastThread &&
    css`
      margin-bottom: 12px;
    `}

  span {
    font-weight: bold;
    font-style: italic;
  }
`

const CheckedCircleIcon = styled(SvgCheckCircle)`
  width: 16px;
  height: 16px;
  path {
    stroke: ${colors.lightDark};
    stroke-width: 1.5px;
  }
`

/*
 INTERFACES
*/

interface ThreadProps {
  className?: string
  viewOnly?: boolean
  onSendStateChange?: (enabled: boolean) => void
  hasEmptyState?: boolean
  disableScroll?: boolean
  inlineSubmit?: boolean
}

interface CommentsProps {
  disableScroll?: boolean
  threadWithEntities?: ThreadWithEntities
  loading: boolean
  isPosting: boolean
  useEmptyState?: boolean
}

/*
 COMPONENTS
*/

export const Thread: React.FC<ThreadProps> = ({
  className,
  viewOnly,
  onSendStateChange,
  hasEmptyState,
  disableScroll,
  inlineSubmit,
}) => {
  const {
    animationState,
    activeThreadId,
    activeThreadDetails,
    targetObject,
    onClick,
    loading: associatedThreadsLoading,
  } = useThreadContext()

  const expanded = !animationState || animationState === "expanded"
  const bubble = animationState === "bubble"

  const [isPosting, setIsPosting] = React.useState(false)
  const [sendEnabled, setSendEnabled] = React.useState(false)
  const onSendButtonChange = React.useCallback(
    (enabled: boolean) => {
      onSendStateChange?.(enabled)
      setSendEnabled(enabled)
    },
    [onSendStateChange]
  )

  const { data: activeThreadResponse, loading: activeThreadLoading } = useReadThreadQuery({
    variables: {
      id: activeThreadId || "",
    },
    // activeThreadDetails are optimistically cached and passed through from readAssociatedThreads
    fetchPolicy: activeThreadDetails?.pending ? "cache-only" : undefined,
    nextFetchPolicy: activeThreadDetails?.pending ? "cache-only" : undefined,
    skip: !activeThreadId,
  })
  const { thread: threadWithEntities } = activeThreadResponse || {}

  const onListClick = React.useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation()
      event.nativeEvent.stopImmediatePropagation()

      if (activeThreadDetails) {
        onClick?.(activeThreadDetails)
      }
    },
    [onClick, activeThreadDetails]
  )

  const loading =
    associatedThreadsLoading ||
    activeThreadLoading ||
    !targetObject.id ||
    (!threadWithEntities && !!activeThreadDetails?.commentCount)

  const showCommentBox = ((!loading && !threadWithEntities) || sendEnabled || expanded) && !viewOnly

  return (
    <>
      <ThreadBubble
        show={bubble}
        threadWithEntities={threadWithEntities}
        loading={loading}
        onClick={onListClick}
      />
      <ThreadContainer bubble={bubble} className={className} onClick={onListClick}>
        <ThreadComments
          threadWithEntities={threadWithEntities}
          loading={loading}
          useEmptyState={hasEmptyState}
          disableScroll={disableScroll}
          isPosting={isPosting}
        />
        {showCommentBox && (
          <CommentBox
            sendEnabled={sendEnabled}
            onPostingStateChange={setIsPosting}
            onSendStateChange={onSendButtonChange}
            inlineSubmit={inlineSubmit}
            isLoading={associatedThreadsLoading}
          />
        )}
      </ThreadContainer>
    </>
  )
}

const ThreadComments: React.FC<CommentsProps> = ({
  threadWithEntities,
  loading,
  isPosting,
  useEmptyState = true,
  disableScroll,
}) => {
  const {
    activeThreadId,
    activeThreadDetails,
    resolvedThreads,
    resolveButtonText,
    threadEntities: allThreadEntities,
    animationState,
    preventResolve,
    alignClientOppositeEmployees,
  } = useThreadContext()
  const { user } = useSession<FrontendSession>()
  const employeeIds = useEmployeeIds()
  const isExpanded = !animationState || animationState === "expanded"

  const { listRef, smoothScroll } = useAutoScrollThread({
    resolvedThreads,
    threadWithEntities,
    activeThreadDetails,
    isExpanded,
    animationState,
    loading,
  })

  if (!loading && !threadWithEntities && !resolvedThreads?.length) {
    return useEmptyState ? <EmptyThread /> : null
  }

  const shouldShowResolveButton =
    !preventResolve && activeThreadId && (!loading || threadWithEntities?.thread.comments.length)

  return (
    <ThreadList
      disableScroll={(!!animationState && animationState !== "expanded") || Boolean(disableScroll)}
      smoothScroll={smoothScroll}
      ref={listRef}
    >
      {!loading &&
        resolvedThreads?.map((resolvedThread, index) => (
          <ResolvedThread
            key={resolvedThread.id}
            resolvedThread={resolvedThread}
            employeeIds={employeeIds}
            userId={user.id}
            alignClientOppositeEmployees={alignClientOppositeEmployees}
            entities={allThreadEntities}
            isLastThread={Boolean(
              !threadWithEntities?.thread?.comments && resolvedThreads.length - 1 === index
            )}
          />
        ))}
      <Comments
        loading={loading}
        employeeIds={employeeIds}
        comments={threadWithEntities?.thread?.comments}
        numLoadingRows={Math.min(activeThreadDetails?.commentCount || 1, MAX_LOADING_ROWS)}
        userId={user.id}
        alignClientOppositeEmployees={alignClientOppositeEmployees}
        entities={
          threadWithEntities?.entities
            ? {
                ...threadWithEntities.entities,
                users: [
                  ...(allThreadEntities?.users ?? []),
                  ...(threadWithEntities.entities.users ?? []),
                ].filter(uniqueBy((u) => u.id)),
              }
            : undefined
        }
      />
      {shouldShowResolveButton && (
        <ResolveHover
          threadId={activeThreadId}
          show={isExpanded}
          disabled={activeThreadDetails?.pending || loading || isPosting}
          buttonText={resolveButtonText}
        />
      )}
    </ThreadList>
  )
}

const ResolvedThread: React.FC<{
  resolvedThread: ThreadWithEntities["thread"]
  entities?: ObjectEntities | null
  userId?: string
  alignClientOppositeEmployees?: boolean
  employeeIds?: string[]
  isLastThread: boolean
}> = ({
  resolvedThread,
  entities,
  userId,
  alignClientOppositeEmployees,
  employeeIds,
  isLastThread,
}) => (
  <>
    <Comments
      // this is a dirty little hack that allows us to sneak open ledger comments into the resolved thread list and have them display correctly
      isResolved={Boolean(resolvedThread.details.resolvedAt)}
      loading={false}
      employeeIds={employeeIds}
      comments={resolvedThread.comments}
      userId={userId}
      alignClientOppositeEmployees={alignClientOppositeEmployees}
      entities={entities}
    />
    {resolvedThread.details.resolvedAt && (
      <ResolvedAt resolvedAt={resolvedThread.details.resolvedAt} isLastThread={isLastThread} />
    )}
  </>
)

const ResolvedAt: React.FC<{ resolvedAt: number; isLastThread: boolean }> = ({
  resolvedAt,
  isLastThread,
}) => {
  const date = dateTimeHelper.displayNameFromUnixTimestamp(
    resolvedAt,
    Interval.Day,
    DateFormat.Default
  )

  return (
    <StyledResolvedAt isLastThread={isLastThread}>
      <CheckedCircleIcon />
      &nbsp;
      <div>
        <span>Resolved</span>&nbsp;on {date}
      </div>
    </StyledResolvedAt>
  )
}

const Comments: React.FC<{
  isResolved?: boolean
  loading: boolean
  comments?: ThreadWithEntities["thread"]["comments"]
  numLoadingRows?: number
  entities?: ObjectEntities | null
  userId?: string
  alignClientOppositeEmployees?: boolean
  employeeIds?: string[]
}> = ({
  isResolved,
  loading,
  comments,
  entities,
  numLoadingRows,
  userId,
  alignClientOppositeEmployees,
  employeeIds,
}) => {
  if (loading && !comments && numLoadingRows) {
    return <CommentsLoading numLoadingRows={numLoadingRows} />
  }

  return comments?.map((c, idx) => (
    <Comment
      // don't key on comment id only, it will remount optimistic threads
      key={`${idx}_${c.text}`}
      isResolved={isResolved}
      comment={c}
      alignment={
        alignClientOppositeEmployees
          ? employeeIds?.includes(c.authorId)
            ? "right"
            : "left"
          : undefined
      }
      isCurrentUser={c.authorId === userId}
      entities={entities}
    />
  ))
}

function useEmployeeIds() {
  const { alignClientOppositeEmployees } = useThreadContext()
  const { currentOrganizationId, currentOrganization } = useSession<FrontendSession>()

  const { data: employees } = useListEmployeesQuery({
    variables: {
      filter: {
        organizationId: currentOrganizationId,
        statuses: [EmployeeStatus.Activated, EmployeeStatus.Initialized],
        visibility: EmployeeVisibility.All,
      },
      pagination: { limit: 500, offset: 0 },
    },
    skip:
      !alignClientOppositeEmployees ||
      !currentOrganization?.permissions?.hasReadPermission(FrontendPermissionModule.Employees),
  })

  return React.useMemo(() => employees?.listEmployees?.map((e) => e.user?.id || ""), [employees])
}

function useAutoScrollThread({
  resolvedThreads,
  threadWithEntities,
  activeThreadDetails,
  isExpanded,
  animationState,
  loading,
}: {
  resolvedThreads?: ThreadWithEntities["thread"][]
  threadWithEntities: ThreadWithEntities | undefined
  activeThreadDetails: ThreadDetails | null | undefined
  isExpanded: boolean
  animationState: string | undefined
  loading: boolean
}) {
  const [smoothScroll, setSmoothScroll] = React.useState(false)

  React.useEffect(() => {
    setSmoothScroll(false)
  }, [animationState])
  const listRef = React.useRef<HTMLUListElement>(null)
  const activeCommentCount =
    threadWithEntities?.thread?.comments?.length ?? activeThreadDetails?.commentCount
  const hasResolvedThreads = Boolean(resolvedThreads?.length)
  React.useEffect(() => {
    if (activeCommentCount || hasResolvedThreads) {
      // scroll to the bottom
      const list = listRef.current
      if (list) {
        list.scrollTop = list.scrollHeight
      }
      setSmoothScroll(isExpanded)
    }
  }, [
    activeCommentCount,
    hasResolvedThreads,
    isExpanded,
    loading,
    // listRef and setSmoothScroll should be stable
    listRef,
    setSmoothScroll,
  ])
  return { listRef, smoothScroll }
}
