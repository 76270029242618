import * as React from "react"

export interface PageScrollContextProps {
  scrollElement: React.RefObject<HTMLDivElement | null>
}

export const PageScrollContext = React.createContext<PageScrollContextProps>({
  scrollElement: React.createRef(),
})

export function useBuildPageScrollContext() {
  // Provide a scroll context so infinite scrolling works.
  const scrollElement = React.useRef<HTMLDivElement>(null)
  const pageScrollContext = React.useMemo<PageScrollContextProps>(() => ({ scrollElement }), [])

  return { scrollElement, pageScrollContext }
}
