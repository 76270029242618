import React from "react"
import { type NewComment } from "@digits-graphql/frontend/graphql-bearer"
import { responsiveStyles } from "@digits-shared/components/Responsive/responsiveStyles"
import { svgIconStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgSend03Solid } from "@digits-shared/components/SVGIcons/solid/Send03Solid.svg"
import { SHAKE_KEYFRAMES } from "@digits-shared/components/UI/Elements/Form/InputStyles"
import { Keys } from "@digits-shared/components/UI/Elements/Keys"
import { DigitsButton } from "@digits-shared/DesignSystem/Button"
import colorHelper from "@digits-shared/helpers/colorHelper"
import { themedStyles } from "@digits-shared/themes"
import borders from "@digits-shared/themes/borders"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled, { css, keyframes } from "styled-components"
import { COMMENT_CONTAINER_MAX_HEIGHT } from "src/frontend/components/OS/Details/Shared/Styles"
import { INPUT_BOX_MARGIN } from "src/shared/components/Comments/CommentBox/commentBoxConstants"
import { type AssigneeDelegate } from "src/shared/components/Comments/CommentBox/ThreadAssignee"
import useThreadContext from "src/shared/components/Comments/ThreadContext"
import { ContentDecorator } from "src/shared/components/EditableContent/ContentDecorator"
import { EditableContent } from "src/shared/components/EditableContent/EditableContent"
import { Decorator } from "src/shared/components/EditableContent/editableContentConstants"
import useCancelablePromise from "src/shared/hooks/useCancelablePromise"

const MIN_BOX_HEIGHT = 38
const BORDER_WIDTH = 1
const COMMENT_BOX_VERTICAL_PADDING = 7

// For light theme on a light background.
export const LIGHT_BACKGROUND_CLASS_NAME = "light-background"

/*
 STYLES
*/

const darkInputBackgroundColor = colorHelper.hexToRgba("#081518", 0.8)

const inputBackgroundStyles = themedStyles({
  light: css`
    color: ${colors.secondary};
    background: ${colors.white};
    outline: 1px solid ${colors.primary20};

    .${LIGHT_BACKGROUND_CLASS_NAME} & {
      color: ${colors.secondary};
    }

    &:hover:not(:disabled):not(:focus-within) {
      outline-color: ${colors.accentBlue};
    }
    &:focus-within {
      outline-color: ${colors.primary};
    }

    &::placeholder {
      text-transform: uppercase;
      color: ${colors.translucentSecondary80};
    }
  `,
  dark: css`
    color: ${colors.white};
    border-top: 1px solid ${colors.transparent};
    background-color: ${darkInputBackgroundColor};
    border: 1px solid ${colors.theme.dark.border};

    &:hover:not(:disabled):not(:focus-within) {
      border-color: ${colors.translucentNeonGreen30};
    }

    &:focus-within {
      border-color: ${colors.neonGreen};
      box-shadow: ${borders.theme.dark.buttonBoxShadow};
    }

    &:disabled,
    &:read-only {
      color: ${colors.translucentWhite20};
    }

    &::placeholder {
      text-transform: uppercase;
      color: ${colors.translucentWhite20};
    }
  `,
})

const INPUT_AUXILIARY_SIZING_STYLES = css`
  width: 30px;
  height: 30px;
  margin: ${(MIN_BOX_HEIGHT - 30 - 2 * BORDER_WIDTH) / 2}px;
  align-self: flex-end;
`

const errorButtonKeyframes = keyframes`
  0%, 100% {
    background: ${colors.orange};
  }
`

interface InputBoxProps {
  hasTopBorderRadius: boolean
}

export const CommentBox = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`

const failedButtonStyles = ({ $failed }: { $failed?: boolean }) =>
  $failed &&
  css`
    animation:
      ${SHAKE_KEYFRAMES} 400ms,
      ${errorButtonKeyframes} 400ms;
  `

const SendButton = styled.button<{ $failed: boolean }>`
  border: none;
  border-radius: 100px;
  cursor: pointer;
  transition: opacity 300ms;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  ${INPUT_AUXILIARY_SIZING_STYLES};
  overflow: hidden;
  background: ${colors.translucentSecondary90};

  &:hover {
    opacity: 1;
  }

  ${({ disabled }) =>
    disabled
      ? css`
          opacity: 0;
          pointer-events: none;
        `
      : css`
          opacity: 0.5;
        `}

  ${failedButtonStyles}
`

const textResponsiveStyles = responsiveStyles({
  desktop: css`
    font-weight: ${fonts.weight.normal};
  `,
  mobile: css`
    font-size: 18px;
    font-weight: ${fonts.weight.medium};
  `,
})

const editableCommentStyles = themedStyles({
  light: css`
    &[contenteditable="false"] {
      pointer-events: none;
    }
    &:empty {
      &::after {
        color: ${colors.secondary50};

        .${LIGHT_BACKGROUND_CLASS_NAME} & {
          color: ${colors.secondary};
        }
      }
    }
  `,
  dark: css`
    color: ${colors.white};

    &:not([contenteditable="true"]) {
      & + ${SendButton} {
        background: ${colors.altoGray};
        border-color: ${colors.altoGray};
      }
    }

    &[contenteditable="true"]:empty {
      &::after {
        color: ${colors.translucentWhite20};
      }
    }
  `,
})

const InlineSendButton = styled(DigitsButton)<{ $failed: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  width: 28px;
  border-radius: 6px;
  padding: 0;
  ${failedButtonStyles}

  &[disabled] {
    background: ${colors.transparent};
    svg {
      ${svgIconStyles(colors.secondary40)};
    }
  }

  svg {
    margin-left: 0;
    ${svgIconStyles(colors.secondary)};
    path {
      stroke: transparent !important;
    }
  }
`

const EditableComment = styled(EditableContent)<{ placeholder?: string }>`
  ${editableCommentStyles};
  ${textResponsiveStyles};
  ${inputBackgroundStyles};
  border-radius: 8px;
  padding: ${COMMENT_BOX_VERTICAL_PADDING}px ${COMMENT_BOX_VERTICAL_PADDING * 2}px;
  line-height: ${MIN_BOX_HEIGHT - 2 * COMMENT_BOX_VERTICAL_PADDING - 2 * BORDER_WIDTH}px;
  min-height: 25px;
  max-height: ${COMMENT_CONTAINER_MAX_HEIGHT}px;
  overflow-y: auto;

  &[contenteditable]:empty,
  &[contenteditable="false"][disabled] {
    &::after {
      cursor: text;
      ${({ placeholder }) => `content: "${placeholder || "Comment and tag others with @…"}";`}
    }
  }

  * {
    // pasted HTML into comments will break out of the comment bubble.
    // force them to wrap
    white-space: unset !important;
  }
`

export const InputBox = styled.div<InputBoxProps>`
  flex: 1;
  display: flex;
  transition:
    border 250ms ease-out,
    box-shadow 250ms ease-out;
  margin: 0 ${INPUT_BOX_MARGIN}px 0 ${INPUT_BOX_MARGIN}px;
`

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 12px;
`

const InlineSubmitCommentContainer = styled.div`
  ${editableCommentStyles};
  ${textResponsiveStyles};
  ${inputBackgroundStyles};
  display: flex;
  border-radius: 8px;
  align-items: center;
  width: 100%;
  padding: 3px ${COMMENT_BOX_VERTICAL_PADDING}px 3px 0;
  ${EditableComment} {
    outline: none;
    border-radius: 0;
    background: ${colors.transparent};
  }
`

/*
 INTERFACES
*/

export interface SendProps {
  failed: boolean
  disabled?: boolean
  isPosting: boolean
  onSend: () => void
}

interface BaseInputProps {
  className?: string
  onSubmit: (comment: NewComment, text: string) => Promise<unknown>
  sendEnabled: boolean
  onSendStateChange?: (enabled: boolean) => void
  onPostingStateChange?: (isPosting: boolean) => void
  isLoading?: boolean
  placeholder?: string
  inlineSubmit?: boolean
}

interface InputProps extends BaseInputProps {
  SendComponent?: React.ComponentType<SendProps>
  assigneeDelegate: React.RefObject<AssigneeDelegate | undefined>
}

interface EditorProps extends BaseInputProps {
  disableToolbar?: boolean
  isDisabled?: boolean
  submitOnEnter?: boolean
  autocomplete?: Decorator[]
  SendComponent: React.ComponentType<SendProps>
  assigneeDelegate?: React.RefObject<AssigneeDelegate | undefined>
}

/*
 COMPONENTS
*/

const DEFAULT_DECORATORS = [Decorator.User]

const CommentInput = React.forwardRef<HTMLDivElement, InputProps>((props, ref) => {
  const {
    className,
    placeholder,
    sendEnabled,
    inlineSubmit,
    isLoading,
    onPostingStateChange,
    onSendStateChange,
    onSubmit,
    assigneeDelegate,
    SendComponent = Send,
  } = props
  const { activeThreadId } = useThreadContext()

  return (
    <InputBox ref={ref} className={className} hasTopBorderRadius={!activeThreadId}>
      <CommentBox>
        <CommentEditor
          inlineSubmit={inlineSubmit}
          onSubmit={onSubmit}
          SendComponent={SendComponent}
          sendEnabled={sendEnabled}
          onPostingStateChange={onPostingStateChange}
          onSendStateChange={onSendStateChange}
          placeholder={placeholder}
          isLoading={isLoading}
          assigneeDelegate={assigneeDelegate}
        />
      </CommentBox>
    </InputBox>
  )
})
export default CommentInput

export const CommentEditor: React.FC<EditorProps> = ({
  className,
  SendComponent,
  onSubmit,
  sendEnabled,
  onSendStateChange,
  onPostingStateChange,
  disableToolbar,
  isDisabled,
  isLoading,
  submitOnEnter,
  autocomplete = DEFAULT_DECORATORS,
  placeholder,
  inlineSubmit,
  assigneeDelegate,
}) => {
  const {
    activeThreadDetails,
    targetObject,
    autoFocus,
    onFocus,
    context,
    autoCompleteMenuAlignment,
  } = useThreadContext()
  const editableContentRef = React.useRef<HTMLDivElement>(null)
  const [isPosting, setIsPosting] = React.useState(false)
  const [hadPostError, setHadPostError] = React.useState(false)
  const disableSend = Boolean(
    activeThreadDetails?.pending || isPosting || isDisabled || !sendEnabled || isLoading
  )
  const cancelablePromise = useCancelablePromise()
  const submit = React.useCallback(
    (comment: NewComment, element: HTMLElement) => {
      setIsPosting(true)
      onPostingStateChange?.(true)
      setHadPostError(false)

      const text = element.innerText
      const html = element.innerHTML
      element.innerHTML = ""

      cancelablePromise(onSubmit(comment, text))
        .then(() => {
          setHadPostError(false)
        })
        .catch((reason: { isCanceled: boolean }) => {
          if (reason?.isCanceled) {
            return
          }
          element.innerHTML = html
          setHadPostError(true)
        })
        .finally(() => {
          setIsPosting(false)
          onPostingStateChange?.(false)
        })
    },
    [cancelablePromise, onSubmit, onPostingStateChange]
  )

  const updateSendState = React.useCallback(() => {
    const content = editableContentRef.current
    onSendStateChange?.(!!content?.innerText.trim())
    if (content) {
      assigneeDelegate?.current?.onInput(content)
    }
  }, [assigneeDelegate, onSendStateChange])

  React.useEffect(() => {
    updateSendState()
  }, [disableSend, isPosting, updateSendState])

  const onBoxFocus = React.useCallback(() => {
    onFocus?.(activeThreadDetails || context)
    updateSendState()
  }, [context, onFocus, activeThreadDetails, updateSendState])

  const onSend = React.useCallback(() => {
    const content = editableContentRef.current
    if (!content) return

    const text = ContentDecorator.encode(content)
    if (!text.length) {
      return
    }

    const comment: NewComment = {
      text,
    }

    submit(comment, content)
  }, [submit])

  const onKeyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLElement>) => {
      event.stopPropagation()
      event.nativeEvent.stopImmediatePropagation()

      const { key, metaKey, shiftKey } = event
      const content = editableContentRef.current
      if (!content) return

      if (key === Keys.Tab) {
        // add tab
        document.execCommand("insertHTML", false, "\u00a0\u00a0\u00a0\u00a0")
        event.preventDefault()
        return
      }

      if ((shiftKey || metaKey || submitOnEnter) && key === Keys.Enter) {
        event.preventDefault()
        if (!disableSend) {
          onSend()
        }
      }
    },
    [submitOnEnter, disableSend, onSend]
  )

  return (
    <CommentContainer inlineSubmit={inlineSubmit}>
      <EditableComment
        ref={editableContentRef}
        className={className}
        identifier={targetObject}
        // do not disable the input while loading,
        // just disable the send button
        // this allows typing (but not sending) while a message is in flight
        disabled={isDisabled}
        autoFocus={autoFocus}
        onKeyDown={onKeyDown}
        onKeyUp={updateSendState}
        onChange={updateSendState}
        onFocus={onBoxFocus}
        autocomplete={autocomplete}
        disableToolbar={disableToolbar}
        placeholder={placeholder}
        autoCompleteMenuAlignment={autoCompleteMenuAlignment}
        showSelectColors={false}
      />
      {inlineSubmit ? (
        <InlineSend disabled={disableSend} onSend={onSend} failed={hadPostError} />
      ) : (
        <SendComponent
          disabled={disableSend}
          failed={hadPostError}
          isPosting={isPosting}
          onSend={onSend}
        />
      )}
    </CommentContainer>
  )
}

const CommentContainer: React.FC<
  React.PropsWithChildren<{ inlineSubmit: boolean | undefined }>
> = ({ inlineSubmit, children }) =>
  inlineSubmit ? <InlineSubmitCommentContainer>{children}</InlineSubmitCommentContainer> : children

const Send: React.FC<SendProps> = ({ disabled, isPosting, onSend }) => {
  const { activeThreadDetails, onClose, hideCancel } = useThreadContext()

  const onClick = React.useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      onSend()
    },
    [onSend]
  )

  const onCancel = React.useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      onClose?.(activeThreadDetails)
    },
    [onClose, activeThreadDetails]
  )

  return (
    <Buttons>
      {!hideCancel && (
        <DigitsButton disabled={isPosting} $variant="secondary-dark" onClick={onCancel}>
          Cancel
        </DigitsButton>
      )}
      <DigitsButton disabled={disabled || isPosting} onClick={onClick}>
        {activeThreadDetails ? "Reply" : "Comment"}
      </DigitsButton>
    </Buttons>
  )
}

const InlineSend: React.FC<Pick<SendProps, "disabled" | "onSend" | "failed">> = ({
  disabled,
  onSend,
  failed,
}) => {
  const onClick = React.useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      onSend()
    },
    [onSend]
  )

  return (
    <InlineSendButton
      $variant="primary"
      disabled={Boolean(disabled)}
      onClick={onClick}
      $failed={failed}
    >
      <SvgSend03Solid />
    </InlineSendButton>
  )
}
