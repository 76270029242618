import * as React from "react"
import { Link } from "react-router-dom"
import { svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgPlus } from "@digits-shared/components/SVGIcons/line/Plus.svg"
import SearchBox from "@digits-shared/components/UI/Elements/SearchBox"
import { DigitsLinkButton } from "@digits-shared/DesignSystem/LinkButton"
import { defined } from "@digits-shared/helpers/filters"
import objectHelper from "@digits-shared/helpers/objectHelper"
import stringHelper from "@digits-shared/helpers/stringHelper"
import { useCurrentRoute } from "@digits-shared/hooks/useCurrentRoute"
import { useEscapeKeyCapture } from "@digits-shared/hooks/useEscapeKeyCapture"
import { useMeasure } from "@digits-shared/hooks/useMeasure"
import useRouter from "@digits-shared/hooks/useRouter"
import useSession from "@digits-shared/hooks/useSession"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled, { css } from "styled-components"
import { Application } from "src/frontend/components/OS/Applications/Application"
import {
  type AffiliationEntity,
  useAffiliationEntities,
} from "src/frontend/components/OS/Applications/hooks/useAffiliationEntities"
import { AccountantAddClient } from "src/frontend/components/OS/Header/AddClient/AccountantAddClient"
import { useBadgeContext } from "src/frontend/components/Shared/Contexts/BadgeContext"
import { MENU_ANIMATION_DURATION_MS } from "src/frontend/components/Shared/NavSidebar/constants"
import { SidebarContainer } from "src/frontend/components/Shared/NavSidebar/SidebarExpando"
import { useFrontendPathGenerator } from "src/frontend/hooks/useFrontendPathGenerator"
import routes from "src/frontend/routes"
import type FrontendSession from "src/frontend/session"
import type SessionBusinessOrganization from "src/frontend/session/BusinessOrganization"
import type SessionLegalEntity from "src/frontend/session/LegalEntity"
import { type SessionOrganization } from "src/frontend/session/Organization"
import { Experience } from "src/frontend/session/personas"
import { EntityIcon } from "src/shared/components/ObjectEntities/EntityIcon"

/*
  STYLES
*/

const BusinessIcon = styled(EntityIcon)`
  margin: 0;
  padding: 0;
`

const BlurLayer = styled.div<{
  isPopOverOpen: boolean
}>`
  ${({ isPopOverOpen }) =>
    isPopOverOpen &&
    css`
      &::after {
        content: "";
        position: absolute;
        top: 64px;
        left: 0;
        height: calc(100vh - 72px);
        width: 100%;
        z-index: 1;
        backdrop-filter: blur(13px);
      }
    `}
`

const MenuContainer = styled.div<{
  height: number
}>`
  display: flex;
  align-items: center;
  flex-direction: column;

  position: absolute;
  left: 0;
  right: 0;
  height: 0;
  top: 64px;
  z-index: 2;
  overflow-y: scroll;
  overflow-x: hidden;
  backdrop-filter: blur(20px);
  max-height: calc(100vh - 60px);

  transition:
    height ${MENU_ANIMATION_DURATION_MS}ms,
    padding 0ms ${MENU_ANIMATION_DURATION_MS}ms;

  ${SidebarContainer} &,
  ${SidebarContainer}:hover & {
    &.business-expand {
      transition:
        height ${MENU_ANIMATION_DURATION_MS}ms,
        padding 0ms;

      height: ${({ height }) => height}px;
      padding: 16px 0 24px;
    }
  }
`

const EntitiesSearch = styled(SearchBox)`
  margin: 0 22px 16px;
  height: 32px;
  border-color: ${colors.secondary40};

  &:focus-within,
  &:hover {
    border-color: ${colors.secondary60};
  }

  svg {
    ${svgPathStyles(colors.secondary, 1.5)};
  }

  input {
    color: ${colors.secondary};

    &::placeholder {
      color: ${colors.secondary70};
    }
  }
`

const EntityLink = styled(Link)<{
  disabled?: boolean
  $selected: boolean
}>`
  width: calc(100% - 24px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  color: ${colors.secondary};
  margin: 0 12px;
  border-radius: 8px;
  padding: 8px 12px;

  &[disabled] {
    pointer-events: none;
  }

  ${({ $selected }) =>
    $selected
      ? css`
          color: ${colors.secondary};
          position: relative;
          background: ${colors.white};
        `
      : css`
          &:hover {
            background: ${colors.translucentSecondary05};
          }
        `}
`

const Header = styled.div`
  width: 100%;
  margin: 0 0 8px 0;
  padding: 0 22px;

  color: ${colors.secondary};
  font-family: ${fonts.family.avenir};
  font-size: 12px;
  font-style: normal;
  font-weight: ${fonts.weight.medium};
`

const List = styled.div`
  overflow-y: auto;
  width: calc(100% + 44px);
  margin: 0 -22px 36px -22px;
  display: flex;
  flex-direction: column;
  padding: 0 22px;
  gap: 4px;
`

const EntityList = styled(List)`
  flex: 1;
`

const BusinessContainer = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;
  gap: 10px;
  font-size: 12px;
  font-weight: ${fonts.weight.medium};
`

const Badge = styled.div`
  width: 20px;
  min-width: 20px;
  height: 16px;
  background: ${colors.primary};
  border-radius: 20px;
  padding: 0 3px;
  display: flex;
  align-items: center;
  justify-content: center;

  color: ${colors.white};
  font-size: 10px;
`

const Pending = styled.div`
  font-size: 9px;
  line-height: 12px;
  color: ${colors.translucentPrimary80};
`

const AddBusinessButton = styled(DigitsLinkButton)`
  margin: 24px auto 0;
`

const SvgAddBusiness = styled(SvgPlus)`
  height: 16px;
  width: 16px;
  ${svgPathStyles(colors.secondary, 2)};
`

/*
  INTERFACES
 */

interface MenuItemsProps {
  onItemClick: () => void
}

/*
  COMPONENT
*/

export const SidebarMenu: React.FC<{
  isOpen: boolean
  hideMenu: () => void
  className?: string
}> = ({ isOpen, className, hideMenu }) => {
  const { currentPrimaryExperience: experience, affiliations } = useSession<FrontendSession>()
  const [ref, { height }] = useMeasure<HTMLDivElement>()
  useEscapeKeyCapture(
    hideMenu,
    // needs to be nested within document to capture the escape event before DetailsView
    { target: document?.body }
  )

  let list
  switch (experience) {
    case Experience.AccountantPortal:
      list = <AccountantItems onItemClick={hideMenu} />
      break
    case Experience.OperatorPortal:
    case Experience.ClientPortal:
      list = <OperatorItems onItemClick={hideMenu} />
      break
  }
  // Even if you're currently looking at an operator dashboard (probably your own),
  // if you're an accountant, you should see the accountant menu
  // so you can switch to your clients
  if (affiliations?.length) {
    list = <AccountantItems onItemClick={hideMenu} />
  }
  if (!list) return null

  const classNames = [className, isOpen ? "business-expand" : ""].join(" ")
  return (
    <BlurLayer isPopOverOpen={isOpen}>
      <MenuContainer height={height + 40} className={classNames} inert={!isOpen}>
        <div ref={ref} css="width: 100%">
          {isOpen && list}
        </div>
      </MenuContainer>
    </BlurLayer>
  )
}

const OperatorItems: React.FC<MenuItemsProps> = ({ onItemClick }) => {
  const { organizations, currentLegalEntity, doppelganger } = useSession<FrontendSession>()

  const affiliatedEntities = React.useMemo(
    () =>
      organizations.flatMap((o) =>
        o.affiliations?.map((a) => ({
          legalEntity: a.entity,
          organization: a.organization,
        }))
      ),
    [organizations]
  )

  const legalEntities = React.useMemo(
    () =>
      organizations.flatMap((organization) =>
        organization.legalEntities.map((legalEntity) => ({ legalEntity, organization }))
      ),
    [organizations]
  )

  const filteredEntities = [...affiliatedEntities, ...legalEntities]
    .filter(defined)
    .filter(
      ({ legalEntity }) =>
        doppelganger?.hasDashboardAccess || legalEntity.isActive || legalEntity.isApproved
    )
    .sort(sortEntities)

  return (
    <EntityList>
      {filteredEntities.map((client) => (
        <EntityListItem
          key={client.legalEntity.id}
          isSelected={currentLegalEntity?.id === client.legalEntity.id}
          organization={client.organization}
          legalEntity={client.legalEntity}
          onItemClick={onItemClick}
        />
      ))}

      <AddBusinessButton to={routes.welcome.generate()} $variant="secondary-dark">
        <SvgAddBusiness />
        Add Business
      </AddBusinessButton>
    </EntityList>
  )
}

const AccountantItems: React.FC<MenuItemsProps> = ({ onItemClick }) => {
  const { currentLegalEntity, currentOrganization } = useSession<FrontendSession>()
  const { affiliationEntities } = useAffiliationEntities(currentOrganization?.id)

  const [searchTerm, setSearchTerm] = React.useState("")

  const filteredClients = React.useMemo(() => {
    const searchTermRegex = new RegExp(stringHelper.escapeRegExp(searchTerm), "i")
    const filtered = searchTerm
      ? affiliationEntities.filter((af) => matchesClientAffiliation(af, searchTermRegex))
      : affiliationEntities

    return sortClients(filtered)
  }, [affiliationEntities, searchTerm])

  return (
    <>
      <AccountingOrgsList />

      <Header>{currentOrganization?.name} Clients</Header>
      <EntitiesSearch
        onChange={setSearchTerm}
        onSubmit={setSearchTerm}
        onClear={setSearchTerm}
        initialValue={searchTerm}
        shouldDebounceOnChange
        placeholder="Search clients"
        stopClickProp
      />

      <EntityList>
        {filteredClients.map((client) => (
          <EntityListItem
            key={client.legalEntity.id}
            isSelected={currentLegalEntity?.id === client.legalEntity.id}
            organization={client.organization}
            legalEntity={client.legalEntity}
            onItemClick={onItemClick}
          />
        ))}
      </EntityList>
      <AccountantAddClient />
    </>
  )
}

const AccountingOrgsList: React.FC = () => {
  const { organizations, currentOrganization } = useSession<FrontendSession>()

  const accountantOrgs = organizations.filter(
    (org) => org.affiliatedLegalEntities.length > 0 || org.legalEntities.length > 0
  )

  if (!accountantOrgs.length) {
    return null
  }

  return (
    <>
      {/* My Firms */}
      <Header>My {stringHelper.pluralize(accountantOrgs.length, "Firm", "Firms")}</Header>
      <List>
        {accountantOrgs.map((org) => (
          <OrgListItem
            key={org.id}
            isSelected={currentOrganization?.id === org.id}
            organization={org}
          />
        ))}
      </List>
    </>
  )
}

const OrgListItem: React.FC<{
  isSelected: boolean
  organization: SessionBusinessOrganization
}> = ({ isSelected, organization }) => {
  const { currentLegalEntity } = useSession<FrontendSession>()
  const pathGenerator = useFrontendPathGenerator()
  const { location } = useRouter()
  const route = routes[location.name] ?? routes.legalEntityHome

  const { ownLegalEntity, legalEntity } = React.useMemo(() => {
    const { name, slug, legalEntities, affiliations } = organization

    // Prefer LEs with dashboard access and view versions.
    // If none, show the first LE but make this list item unclickable.
    const ownLegalEntities = legalEntities.filter(
      (le) => le.accountingViewVersions.size && le.hasDashboardAccess()
    )
    const affiliateLegalEntities = (
      affiliations?.filter(
        (aff) => aff.entity.accountingViewVersions.size && aff.entity.hasDashboardAccess()
      ) ?? []
    ).map((a) => a.entity)

    // Prefer the "active" Entities with same name or slug, otherwise just the first one from the list of active LEs
    const matchNameOrSlug = (le: SessionLegalEntity) =>
      !!le.name.match(new RegExp(name, "i")) || !!le.slug.match(new RegExp(slug, "i"))

    const ownLegalEntity = ownLegalEntities.find(matchNameOrSlug) ?? ownLegalEntities[0]
    const affiliateLegalEntity =
      affiliateLegalEntities.find(matchNameOrSlug) ?? affiliateLegalEntities[0]

    const legalEntity = ownLegalEntity ?? affiliateLegalEntity

    return { legalEntity, ownLegalEntity }
  }, [organization])

  const { badgeCount } = useBadgeContext()
  const actionItemsCount = badgeCount(ownLegalEntity?.id ?? "", Application.ActionItems.name) || 0

  if (!legalEntity) {
    return null
  }

  const { iconUrl } = organization

  const isLEPending = !legalEntity.hasDashboardAccess() || !legalEntity.accountingViewVersions.size
  const showBadge = !!ownLegalEntity && !isLEPending && actionItemsCount > 0

  return (
    <EntityLink
      to={pathGenerator(route, { leSlug: legalEntity.slug })}
      $selected={isSelected}
      disabled={currentLegalEntity === legalEntity || isLEPending}
      replace
    >
      <BusinessContainer>
        <BusinessIcon fontSize={12} size={32} imageUrl={iconUrl}>
          {organization.name}
        </BusinessIcon>
        <div>
          {organization.name}
          {isLEPending && <Pending>PENDING</Pending>}
        </div>
      </BusinessContainer>
      {showBadge && <Badge>{Math.min(actionItemsCount, 99)}</Badge>}
    </EntityLink>
  )
}

const EntityListItem: React.FC<{
  isSelected: boolean
  organization: SessionOrganization
  legalEntity: SessionLegalEntity
  forceHome?: boolean
  onItemClick?: () => void
}> = ({ isSelected, legalEntity, organization, forceHome, onItemClick }) => {
  const { badgeCount } = useBadgeContext()
  const curRoute = useCurrentRoute(routes)
  const pathGenerator = useFrontendPathGenerator()
  const { doppelganger } = useSession<FrontendSession>()
  const actionItemsCount = badgeCount(legalEntity.id, Application.ActionItems.name) || 0

  const { iconUrl } = organization
  const pathRoute = forceHome
    ? routes.legalEntityHome
    : curRoute.nearestUnparameterizedRoute() || routes.legalEntityHome

  const isLEPending = !(legalEntity.hasDashboardAccess() || legalEntity.accountingViewVersions.size)

  const showBadge = !isLEPending && actionItemsCount > 0

  return (
    <EntityLink
      to={pathGenerator(pathRoute, { leSlug: legalEntity.slug })}
      onClick={onItemClick}
      $selected={isSelected}
      disabled={isLEPending && !doppelganger?.hasFullAccess}
      replace
    >
      <BusinessContainer>
        <BusinessIcon fontSize={12} size={32} imageUrl={iconUrl}>
          {legalEntity.name}
        </BusinessIcon>
        <div>
          {legalEntity.name}
          {isLEPending && <Pending>PENDING</Pending>}
        </div>
      </BusinessContainer>
      {showBadge && <Badge>{Math.min(actionItemsCount, 99)}</Badge>}
    </EntityLink>
  )
}

function sortEntities(
  entityA: {
    legalEntity: SessionLegalEntity
  },
  entityB: {
    legalEntity: SessionLegalEntity
  }
) {
  const nameA = entityA.legalEntity.name
  const nameB = entityB.legalEntity.name
  if (nameA < nameB) return -1
  if (nameA > nameB) return 1
  return 0
}

function sortClients(clients: AffiliationEntity[]): AffiliationEntity[] {
  return clients.toSorted((c1, c2) =>
    objectHelper.compareObjectNames(c1.legalEntity, c2.legalEntity)
  )
}

function matchesClientAffiliation({ legalEntity }: AffiliationEntity, searchTermRegex: RegExp) {
  return legalEntity.name.match(searchTermRegex) || legalEntity.slug.match(searchTermRegex)
}
