import * as React from "react"
import { SvgLogOut01 } from "@digits-shared/components/SVGIcons/line/LogOut01.svg"
import colors from "@digits-shared/themes/colors"
import { themedSVGPathStyles } from "@digits-shared/themes/svgIconStyles"
import styled from "styled-components"

export const InputLinkContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

const linkIconStyles = themedSVGPathStyles(
  {
    light: "#182732",
    dark: colors.white,
  },
  1.5
)

const linkHoverIconStyles = themedSVGPathStyles(
  {
    light: "#4c85b8",
    dark: colors.neonGreen,
  },
  1.5
)

const HandleLink = styled.a`
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
  ${linkIconStyles}

  &:hover {
    ${linkHoverIconStyles}
  }
`

interface InputLinkProps {
  href: string
}

export const InputLink: React.FC<InputLinkProps> = (props) => (
  <HandleLink href={props.href} target="_blank" rel="noopener noreferrer">
    <SvgLogOut01 width={20} height={20} />
  </HandleLink>
)
