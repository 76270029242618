import * as React from "react"
import ReactDOM from "react-dom"
import { useEvent } from "react-use"
import { type UseMeasureRect } from "react-use/lib/useMeasure"
import { useModalRoot } from "@digits-shared/hooks/useModalRoot"
import styled from "styled-components"
import { STATEMENT_ROWS_CLASSNAME } from "src/frontend/components/Shared/Reports/Report/Components/Statements/shared"
import zIndexes from "src/shared/config/zIndexes"

const positionElement = (props: ModalPosition) => {
  const top = `${props.top}px`
  const left = `${props.left}px`
  const minHeight = `${props.minHeight}px`

  return {
    style: {
      top,
      left,
      minHeight,
    },
  }
}

const FixedPosition = styled.div.attrs<ModalPosition>(positionElement)`
  position: fixed;
  z-index: ${zIndexes.modalOverlay};
`

interface ModalPosition {
  top: number
  left: number
  minHeight: number
}

export const DetailsPopUpPortal = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<{ position?: ModalPosition }>
>(({ position, children }, ref) => {
  const modalRoot = useModalRoot()
  if (!position) return null

  const { top, left, minHeight } = position
  return (
    <>
      {ReactDOM.createPortal(
        <FixedPosition ref={ref} top={top} left={left} minHeight={minHeight}>
          {children}
        </FixedPosition>,
        modalRoot
      )}
    </>
  )
})

export function useDetailsPortalState(
  positionRef: React.RefObject<HTMLElement | null>,
  measureRect: UseMeasureRect,
  hasButtons: boolean
) {
  const [position, setPosition] = React.useState<ModalPosition | undefined>(undefined)

  const positionPortal = React.useCallback(() => {
    const parent = positionRef.current?.closest(`.${STATEMENT_ROWS_CLASSNAME}`)
    if (positionRef.current && parent) {
      const { height, width } = measureRect
      let { top, height: containerHeight } = positionRef.current.getBoundingClientRect()
      let { right } = parent.getBoundingClientRect()

      right -= 20 // right padding

      const pushRight = right + width > window.innerWidth
      if (pushRight) {
        top += 30 // push popover down to clear the current row as it is pushed to the right over contents
        right = window.innerWidth - width - 20
      }

      if (!hasButtons) {
        right -= 50
      }

      if (top + height > window.innerHeight) {
        top = top + containerHeight - height

        if (pushRight) {
          top -= 60 // 30 from above, plus another 30 to clear the row now from above
        }
      }

      setPosition((prevState) => ({
        top: top,
        left: right,
        minHeight: Math.max(prevState?.minHeight ?? 0, height),
      }))
    }
  }, [hasButtons, measureRect, positionRef])

  useEvent("resize", positionPortal)
  useEvent("wheel", positionPortal)
  useEvent("mousewheel", positionPortal)

  React.useEffect(() => {
    positionPortal()
  }, [positionPortal])

  return React.useMemo(() => ({ position }), [position])
}
