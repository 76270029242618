import React from "react"
import { ActionItemsContext } from "src/frontend/components/Shared/NavSidebar/ActionItems/ActionItemsSidebarContext"

/**
 * Initialize GroupView state and return group view setters for ActionItemSidebar.
 */
export function useGroupView() {
  return React.useContext(ActionItemsContext).groupView
}

export function useHasActionButtons() {
  return useGroupView() !== "Resolved"
}
