import * as React from "react"
import {
  PageScrollContext,
  useBuildPageScrollContext,
} from "@digits-shared/components/UI/Elements/PageScrollContext"
import ScrollMemory from "src/shared/components/ScrollMemory/ScrollMemory"

export interface ChromeScrollMemoryContextProps {
  setContentReady: () => void
}

export const ChromeScrollMemoryContext = React.createContext<ChromeScrollMemoryContextProps>({
  setContentReady: () => {},
})

export default function useChromeScrollMemoryContext() {
  const context = React.useContext(ChromeScrollMemoryContext)

  // Memoize so that a new object is only returned if something changes
  return React.useMemo(() => context, [context])
}

export const ChromeScrollMemory: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { scrollElement, pageScrollContext } = useBuildPageScrollContext()

  const { ready, setContentReady } = useReadyPromise()
  const memoryContext: ChromeScrollMemoryContextProps = React.useMemo(
    () => ({
      setContentReady,
    }),
    [setContentReady]
  )

  return (
    <PageScrollContext.Provider value={pageScrollContext}>
      <ChromeScrollMemoryContext.Provider value={memoryContext}>
        {children}
      </ChromeScrollMemoryContext.Provider>
      <ScrollMemory scrollElement={scrollElement} ready={ready} behavior="auto" />
    </PageScrollContext.Provider>
  )
}

function useReadyPromise() {
  const [promise] = React.useState(() => {
    let resolve = () => {}
    const promise = new Promise<void>((r) => {
      resolve = r
    })
    return { promise, resolve: resolve }
  })

  return {
    ready: promise.promise,
    setContentReady: promise.resolve,
  }
}
