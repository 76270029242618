import * as React from "react"
import { createPortal } from "react-dom"
import { SvgMail01 } from "@digits-shared/components/SVGIcons/line/Mail01.svg"
import { SvgChevronRightSolid } from "@digits-shared/components/SVGIcons/solid/ChevronRightSolid.svg"
import { usePortalElement } from "@digits-shared/hooks/usePortalElement"
import colors from "@digits-shared/themes/colors"
import { AnimatePresence, m, type Variants } from "framer-motion"
import styled, { css } from "styled-components"
import { useCurrentLegalEntityActionItemsBadgeCount } from "src/frontend/components/OS/Applications/hooks/useActionItemsBadgeCountsQuery"
import { useNavSidebarContext } from "src/frontend/components/Shared/Contexts/useNavSidebarContext"
import { DebuggingContextProvider } from "src/frontend/components/Shared/NavSidebar/ActionItems/ActionItemsDebugging"
import { ActionItemsSidebarContent } from "src/frontend/components/Shared/NavSidebar/ActionItems/ActionItemsSidebarContent"
import {
  SIDEBAR_PATH_SVG_STYLES,
  SIDEBAR_WIDTH,
} from "src/frontend/components/Shared/NavSidebar/sidebarConstants"
import { SidebarButtonRow } from "src/frontend/components/Shared/NavSidebar/SidebarExpando"
import zIndexes from "src/shared/config/zIndexes"

/*
  STYLES
*/

const InboxIcon = styled(SvgMail01)`
  ${SIDEBAR_PATH_SVG_STYLES};
`

const ActionItemsRowTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const MenuChevron = styled(SvgChevronRightSolid)<{ $isOpen: boolean }>`
  ${({ $isOpen }) =>
    !$isOpen &&
    css`
      opacity: 0;
    `}
  width: 16px;
  height: 16px;
  path {
    // remove active class additional stroke
    stroke: none !important;
  }
  transform: rotate(${({ $isOpen }) => ($isOpen ? 180 : 0)}deg);
  transition: all 275ms ease-in-out;
`

const AnimatedSidebarContainer = styled(m.div)`
  position: fixed;
  top: 0;
  bottom: 0;
  left: ${SIDEBAR_WIDTH}px;
  border-radius: 0 16px 16px 0;
  z-index: ${zIndexes.leftSidebarContent};
  background-color: ${colors.secondary05};
  box-shadow: 10px 0px 17px -3px ${colors.translucentBlack10};
`

const StyledSidebarButtonRow = styled(SidebarButtonRow)`
  &:hover,
  &:focus {
    ${MenuChevron} {
      opacity: 1;
    }
  }
`

const ActionItemsOverlay = styled(m.div)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: ${SIDEBAR_WIDTH}px;
  z-index: ${zIndexes.leftSidebarContent - 1};
  background-color: ${colors.translucentSecondary05};
  backdrop-filter: blur(8px);
`

const sidebarVariants = {
  closed: { opacity: 0, x: -100 },
  showing: { opacity: 1, transition: { delay: 0.1, duration: 0.3, ease: "easeOut" } },
  hiding: { opacity: 0, transition: { duration: 0.3, ease: "easeIn" } },
  opening: { x: 0, transition: { duration: 0.3, ease: "easeOut" } },
  closing: { x: -50, transition: { delay: 0.1, duration: 0.3, ease: "easeIn" } },
} satisfies Variants

const overlayVariants: Variants = {
  entering: { opacity: 1, transition: { duration: 0.3 } },
  exiting: { opacity: 0, transition: { delay: 0.1, duration: 0.3 } },
} satisfies Variants

/*
  COMPONENT
*/

export const ActionItemsSidebar: React.FC = () => {
  const {
    toggleActionItemsSidebar: toggleSidebar,
    isActionItemsSidebarOpen: isSidebarOpen,
    hideActionItemsSidebar: hideSidebar,
  } = useNavSidebarContext()
  const actionItemCount = useCurrentLegalEntityActionItemsBadgeCount()

  return (
    <>
      <StyledSidebarButtonRow
        Icon={InboxIcon}
        badge={actionItemCount}
        active={isSidebarOpen}
        onClick={toggleSidebar}
      >
        <ActionItemsRowTitle>
          Inbox
          <MenuChevron $isOpen={isSidebarOpen} />
        </ActionItemsRowTitle>
      </StyledSidebarButtonRow>
      <AnimatedActionItemsSidebar isSidebarOpen={isSidebarOpen} hideSidebar={hideSidebar} />
    </>
  )
}

const AnimatedActionItemsSidebar: React.FC<{
  isSidebarOpen: boolean
  hideSidebar: () => void
}> = ({ isSidebarOpen, hideSidebar }) => {
  const actionItemsPortal = usePortalElement("action-items-sidebar")

  return createPortal(
    <>
      <AnimatedSidebar show={isSidebarOpen} hideSidebar={hideSidebar} />
      <AnimatedOverlay show={isSidebarOpen} onClick={hideSidebar} />
    </>,
    actionItemsPortal
  )
}

const AnimatedSidebar: React.FC<{
  show: boolean
  hideSidebar: () => void
}> = ({ show, hideSidebar }) => (
  <AnimatePresence>
    {show && (
      <AnimatedSidebarContainer
        variants={sidebarVariants}
        initial="closed"
        animate={["opening", "showing"]}
        exit={["closing", "hiding"]}
      >
        <DebuggingContextProvider>
          <ActionItemsSidebarContent hideSidebar={hideSidebar} />
        </DebuggingContextProvider>
      </AnimatedSidebarContainer>
    )}
  </AnimatePresence>
)

/**
 * Overlay to blur the background when the action items sidebar is open.
 * Clicking on the overlay will close the sidebar.
 */
const AnimatedOverlay: React.FC<{ show: boolean; onClick: () => void }> = ({ show, onClick }) => (
  <AnimatePresence mode="wait">
    {show && (
      <ActionItemsOverlay
        onClick={onClick}
        variants={overlayVariants}
        initial="exiting"
        animate="entering"
        exit="exiting"
      />
    )}
  </AnimatePresence>
)
