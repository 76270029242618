import * as React from "react"
import { useRouteMatch } from "react-router-dom"
import { useApolloClient } from "@apollo/client"
import { useTokenRefreshMutation } from "@digits-graphql/frontend/graphql-public"
import envHelper from "@digits-shared/helpers/envHelper"
import useSession from "@digits-shared/hooks/useSession"
import routes from "src/frontend/routes"
import type FrontendSession from "src/frontend/session"
import useSessionTokenRefresh from "src/shared/components/Session/useSessionTokenRefresh"

export const BearerRefresh: React.FC = () => {
  const client = useApolloClient()
  const session = useSession<FrontendSession>()
  useMutateSessionActiveLegalEntity(session)
  // get the activeLegalEntityId after the mutation hook in case it needs to be changed
  const activeLegalEntityId = session.lastKnownLegalEntityId

  const [tokenRefreshMutation] = useTokenRefreshMutation()
  useSessionTokenRefresh(tokenRefreshMutation, {
    activeLegalEntityId,
  })

  React.useEffect(() => {
    session.blockingBearerRefresh()

    // ignore playwright
    if (envHelper.isTest() || envHelper.isMocks()) return
    // reset cache only when switching LEs, not every time token is refreshed
    session.bearer().then(async () => {
      client.stop()
      // Remove all data from the store. clearStore will not refetch any active queries.
      await client.clearStore()
      client.cache.gc() // Garbage collection to remove unreferenced data
    })
  }, [activeLegalEntityId, client, session])

  return null
}

/**
 * Updates the active legal entity in the session based on the `leSlug` parameter from the URL.
 * This allows the session to adjust its active legal entity when navigating to different legal entity.
 *
 */
function useMutateSessionActiveLegalEntity(session: FrontendSession) {
  const params = useRouteMatch<{ leSlug?: string }>(routes.legalEntity.parameterizedPath)?.params
  const urlLESlug = params?.leSlug
  const currentLESlug = session.currentLegalEntity?.slug

  // If the `leSlug` from the URL is different from the current legal entity's slug in the session,
  // attempt to find and set the new legal entity in the session
  if (urlLESlug && urlLESlug !== currentLESlug) {
    const entity = session.findLegalEntityBySlug(urlLESlug)
    // Update the session's active legal entity if a matching entity is found
    if (entity) {
      session.currentLegalEntity = entity
    }
  }
}
