import * as React from "react"
import colors from "@digits-shared/themes/colors"
import { DetailText } from "@digits-shared/themes/typography"
import styled from "styled-components"

/* 
  STYLES
*/

export const DateContainer = styled(DetailText)<{ isOverdue?: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: baseline;
  color: ${({ isOverdue }) => (isOverdue ? colors.error : colors.secondary70)};
  min-width: 0;
`

/* 
  COMPONENTS
*/

export const NameDateDetail: React.FC<
  React.PropsWithChildren<{
    date?: React.ReactElement | string | null
    isOverdue?: boolean
    secondary?: string
  }>
> = ({ date, isOverdue, secondary, children }) => (
  <>
    {secondary ? (
      <DetailText color={colors.secondary} weight="heavy">
        {secondary}
      </DetailText>
    ) : (
      children
    )}
    <DateContainer isOverdue={isOverdue}>{date}</DateContainer>
  </>
)
