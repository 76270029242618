import React from "react"
import { useEvent } from "react-use"
import { isNetworkRequestInFlight } from "@apollo/client/core/networkStatus"
import {
  type Category,
  type EntityCategory,
  type EntityParty,
  type ObjectIdentifier,
  ObjectKind,
  type PartyRole,
  useListCategoriesQuery,
  type User,
  useSearchTermsQuery,
} from "@digits-graphql/frontend/graphql-bearer"
import { svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { ColorIcon } from "@digits-shared/components/UI/Elements/ColorIcon"
import { Keys } from "@digits-shared/components/UI/Elements/Keys"
import caretHelper from "@digits-shared/helpers/caretHelper"
import { isMobile } from "@digits-shared/helpers/devicesHelper"
import { defined } from "@digits-shared/helpers/filters"
import userHelper from "@digits-shared/helpers/userHelper"
import useSession from "@digits-shared/hooks/useSession"
import { themedStyles, themedValue } from "@digits-shared/themes"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled, { css } from "styled-components"
import { COMMENT_CONTAINER_MAX_HEIGHT } from "src/frontend/components/OS/Details/Shared/Styles"
import { useViewVersion } from "src/frontend/components/Shared/Contexts/useViewVersion"
import { useListSuggestedUsers } from "src/frontend/hooks/useListSuggestedUsers"
import type FrontendSession from "src/frontend/session"
import { FrontendPartyRole, SUPPORTED_PARTY_ROLES } from "src/frontend/types/FrontendPartyRole"
import {
  DATA_DECORATOR,
  DATA_ENTITY_KIND,
  DATA_ID,
  type DecoratedEntity,
  decoratedEntityToEntityKind,
  Decorator,
  EntityTagToken,
  NodeValues,
  TagNames,
} from "src/shared/components/EditableContent/editableContentConstants"
import { SVGIconComponent } from "src/shared/components/Icons/SVGIcon"
import zIndexes from "src/shared/config/zIndexes"
import {
  convertHierarchicalDimensionTreeToFlatList,
  convertToHierarchicalDimensionTree,
  getHierarchicalDimensionAncestors,
} from "src/shared/helpers/hierarchicalDimensionHelper"

const MENU_MAX_RESULTS = 6
const MENU_BELOW_EXTRA_OFFSET = 25
const MENU_ABOVE_EXTRA_OFFSET = 4

/*
 STYLES
*/

const menuStyles = themedStyles({
  light: css`
    background: ${colors.offWhite};
    border: 1px solid ${colors.white};
    box-shadow: 0 20px 40px ${colors.translucentBlack10};
    backdrop-filter: blur(25px);
  `,
  dark: css`
    background: radial-gradient(53.24% 100% at 50% 0%, #3f3f3f 0%, #212121 100%);
    box-shadow: 0 13px 27px ${colors.translucentBlack10};
  `,
})

const Menu = styled.div<{ yPosition: number; leftPosition: number; alignment: MenuAlignment }>`
  ${menuStyles};
  position: absolute;
  min-width: 250px;
  max-width: 350px;
  max-height: 350px;
  ${({ alignment, yPosition }) =>
    alignment === MenuAlignment.Below
      ? css`
          top: ${yPosition}px;
        `
      : css`
          bottom: ${yPosition}px;
        `}

  left: ${(props) => props.leftPosition}px;
  padding: 10px 0 15px;
  z-index: ${zIndexes.modalOverlay + 1};
  transform: translate3d(0px, 0px, 0px);
  border-radius: 15px;
  overflow-y: auto;
`

const Info = styled.div`
  flex: 1;
  overflow: hidden;
  text-align: left;
`

const SectionTitle = styled.div`
  font-weight: ${fonts.weight.medium};
  font-size: 12px;
  line-height: 16px;
  color: ${themedValue({ light: colors.translucentSecondary80, dark: colors.translucentWhite80 })};
  padding: 10px 20px 10px;
`

const iconStyles = themedStyles({
  light: css`
    background: none;
    border: 1px solid transparent;
    ${svgPathStyles(colors.secondary, 1.5)};
  `,
  dark: css`
    background: rgba(18, 53, 63, 0.7);
    border: 1px solid rgba(22, 232, 236, 0.2);
    ${svgPathStyles(colors.translucentWhite80, 1.5)};
  `,
})

const CategoryIcon = styled(SVGIconComponent)`
  ${iconStyles};
  height: 35px;
  width: 35px;
  margin-right: 10px;
  padding: 7px;
  border-radius: 50%;
`

const Title = styled.div`
  color: ${themedValue({ light: colors.secondary, dark: colors.white })};
  font-weight: ${fonts.weight.medium};
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Subtitle = styled.div`
  color: ${themedValue({ light: colors.translucentSecondary80, dark: colors.translucentWhite30 })};
  font-weight: ${fonts.weight.medium};
  font-size: 11px;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
`

const itemStyles = themedStyles({
  light: css`
    &.selected {
      border-left-color: ${colors.primary};
      background: ${colors.white};

      ${CategoryIcon} {
        ${svgPathStyles(colors.primary, 1.5)};
      }
    }

    &:hover:not(.selected) {
      background: ${colors.white};

      ${CategoryIcon} {
        ${svgPathStyles(colors.translucentPrimary70, 1.5)};
      }
    }
  `,
  dark: css`
    &.selected {
      border-left-color: ${colors.neonGreen};

      ${Title}, ${Subtitle} {
        color: ${colors.neonGreen};
      }
    }

    &:hover:not(.selected) {
      ${Title}, ${Subtitle} {
        color: ${colors.translucentNeonGreen70};
      }
    }
  `,
})

const Item = styled.div`
  ${itemStyles};
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  padding: 7px 30px 7px 14px;
  border-left: 6px solid ${colors.transparent};
`

/*
 INTERFACES
*/

export enum MenuAlignment {
  Above,
  Below,
}

type OnAutoCompletedCallback = (data: ACItem) => void

interface BaseProps {
  identifier: ObjectIdentifier
  types: Decorator[]
  editableContentRef: React.RefObject<HTMLDivElement | null>
  onAutoCompleted?: OnAutoCompletedCallback
  className?: string
  menuAlignment?: MenuAlignment
}

interface EntityACProps extends BaseProps {
  currentElement: HTMLElement
  currentWord: string
}

export interface ACItem<T = EntityParty | EntityCategory | User> {
  id: string
  displayKey?: string | null
  iconUrl?: string | null
  title: string
  subtitle?: string | null
  decoratedValue: string
  value: string
  searchValues: string[]
  decorator: Decorator
  type: DecoratedEntity
  entity: T
}

interface ACProps {
  className?: string
  content: HTMLElement
  currentElement: HTMLElement
  searchTerm: string
  dataSet: ACItem[]
  menuAlignment: MenuAlignment
  onAutoCompleted?: OnAutoCompletedCallback
}

interface ACSectionProps {
  type: DecoratedEntity
  dataSet: ACItem[]
  sectionIndex: number
  selectedIndex: number
  onItemClick: (data: ACItem, event: React.MouseEvent) => void
}

interface ACItemProps {
  data: ACItem
  selected: boolean
  onClick: (data: ACItem, event: React.MouseEvent) => void
}

type ResultsGroups = Map<DecoratedEntity, ACItem[]>

/*
 COMPONENTS
*/

const AutoComplete: React.FC<BaseProps> = ({
  className,
  types,
  identifier,
  editableContentRef,
  menuAlignment = MenuAlignment.Below,
  onAutoCompleted,
}) => {
  const [currentWord, setCurrentWord] = React.useState("")

  const handleKeyUp = React.useCallback(
    (e: KeyboardEvent) => {
      // check if user is typing a composed character, like é or ñ, in this case just ignore
      // the first Alt + e, etc. event because the `getWordAtCaretPosition` will break the composition
      if (e.isComposing) return
      const content = editableContentRef.current
      if (!content) return

      const currentElement = caretHelper.getChildElementAtCaretPosition(content)
      const word = caretHelper.getTextAtCaretPosition()

      const currentWords =
        currentElement?.hasAttribute(DATA_DECORATOR) &&
        typeof currentElement?.innerText === "string"
          ? currentElement.innerText
          : word

      setCurrentWord(currentWords || "")
    },
    [editableContentRef]
  )

  const onSelected = React.useCallback(
    (data: ACItem) => {
      setCurrentWord("")
      onAutoCompleted?.(data)
      setTimeout(() => editableContentRef.current?.focus(), 200)
    },
    [editableContentRef, onAutoCompleted]
  )

  React.useEffect(() => {
    const content = editableContentRef.current
    if (!content) return

    content.addEventListener("keyup", handleKeyUp)
    return () => content.removeEventListener("keyup", handleKeyUp)
  }, [editableContentRef, handleKeyUp])

  if (isMobile || !currentWord) return null

  const currentElement =
    editableContentRef.current &&
    caretHelper.getChildElementAtCaretPosition(editableContentRef.current)

  const decorator = currentElement?.getAttribute(DATA_DECORATOR)

  if (!currentElement || !decorator) return null

  switch (decorator) {
    case Decorator.User:
      return (
        <UserAutoComplete
          className={className}
          identifier={identifier}
          types={types}
          editableContentRef={editableContentRef}
          currentElement={currentElement}
          currentWord={currentWord}
          onAutoCompleted={onSelected}
          menuAlignment={menuAlignment}
        />
      )
    case Decorator.Party:
    case Decorator.Category:
      return (
        <PartyCategoryAutoComplete
          className={className}
          identifier={identifier}
          types={types}
          editableContentRef={editableContentRef}
          currentElement={currentElement}
          currentWord={currentWord}
          onAutoCompleted={onSelected}
          menuAlignment={menuAlignment}
        />
      )
    default:
      console.error("unsupported decorator: ", decorator)
      return null
  }
}

const UserAutoComplete: React.FC<EntityACProps> = ({
  identifier,
  className,
  editableContentRef,
  currentElement,
  currentWord,
  menuAlignment = MenuAlignment.Below,
  onAutoCompleted,
}) => {
  const { users } = usePrefetchACData(identifier)
  const searchTerm = currentWord.replace(EntityTagToken.Mention, "").toLowerCase()
  const dataSet = React.useMemo(
    () =>
      (users || [])
        .map<ACItem<User>>((u) => ({
          id: u.id,
          iconUrl: u.avatarUrl,
          title: userHelper.displayName(u),
          subtitle: u.primaryEmailAddress,
          decoratedValue: `${EntityTagToken.Mention}${u.givenName || u.familyName || u.id}`,
          value: u.givenName || u.familyName || u.id,
          searchValues: [u.givenName, u.familyName, u.primaryEmailAddress].filter(defined),
          decorator: Decorator.User,
          type: Decorator.User,
          entity: u,
        }))
        .filter((data) => data.searchValues.some((v) => v.toLowerCase().startsWith(searchTerm))),
    [searchTerm, users]
  )
  if (!editableContentRef.current) return null

  return (
    <AutoCompleteMenu
      className={className}
      content={editableContentRef.current}
      currentElement={currentElement}
      searchTerm={searchTerm}
      dataSet={dataSet}
      onAutoCompleted={onAutoCompleted}
      menuAlignment={menuAlignment}
    />
  )
}

export const PartyCategoryAutoComplete: React.FC<EntityACProps> = ({
  className,
  types,
  editableContentRef,
  currentElement,
  currentWord,
  menuAlignment = MenuAlignment.Below,
  onAutoCompleted,
}) => {
  const searchTerm = currentWord.replace(EntityTagToken.Mention, "").toLowerCase()
  const { parties, categories } = useSearchQueries(searchTerm, types)
  const categoryIdToAncestors = useCategoryAncestorsLookup()

  const partySet: ACItem<EntityParty>[] = React.useMemo(() => {
    if (!parties) return []

    return parties
      .flatMap((party) =>
        party.roles?.map((partyRole) => {
          if (!SUPPORTED_PARTY_ROLES.includes(partyRole)) return undefined

          return {
            id: party.id,
            iconUrl: party.iconUrl,
            title: party.name,
            subtitle: party.shortDescription,
            decoratedValue: `${EntityTagToken.Mention}${party.name || party.id}`,
            value: party.name || party.id,
            searchValues: [party.name, party.shortDescription].filter(defined),
            decorator: Decorator.Party,
            type: partyRole,
            entity: party,
          }
        })
      )
      .filter(defined)
  }, [parties])

  const categorySet: ACItem<EntityCategory>[] = React.useMemo(() => {
    if (!categories) return []

    const categoryIdToHierarchyText = new Map<string, string>()
    Array.from(categoryIdToAncestors).forEach(([id, ancestors]) => {
      const ancestorString = ancestors.map((anc) => anc.name).join(" ▸ ")
      categoryIdToHierarchyText.set(id, ancestorString)
    })

    return categories.map((category) => ({
      id: category.id,
      displayKey: category.displayKey,
      title: category.name,
      subtitle: categoryIdToHierarchyText.get(category.id) ?? "",
      decoratedValue: `${EntityTagToken.Mention}${category.name || category.id}`,
      value: category.name || category.id,
      searchValues: [category.name].filter(defined),
      decorator: Decorator.Category,
      type: Decorator.Category,
      entity: category,
    }))
  }, [categories, categoryIdToAncestors])

  const dataSet = React.useMemo(() => [...categorySet, ...partySet], [categorySet, partySet])

  if (!editableContentRef.current) return null

  return (
    <AutoCompleteMenu
      className={className}
      content={editableContentRef.current}
      currentElement={currentElement}
      searchTerm={searchTerm}
      dataSet={dataSet}
      menuAlignment={menuAlignment}
      onAutoCompleted={onAutoCompleted}
    />
  )
}

export default AutoComplete

const AutoCompleteMenu: React.FC<ACProps> = ({
  className,
  content,
  currentElement,
  searchTerm,
  dataSet,
  menuAlignment,
  onAutoCompleted,
}) => {
  const menuRef = React.useRef<HTMLDivElement>(null)
  const [selectedIndex, setSelectedIndex] = React.useState(0)

  const { resultsByType, totalResults } = useGroupedResults(dataSet)
  const { yPosition, alignment } = useMenuPosition(menuRef, currentElement, menuAlignment)
  const leftPosition = currentElement.offsetLeft

  // Commented out to support user's deleting and reconstructing the auto-complete text.
  // Backspacing on the autocompleted object will cause the reference to the object ID to be removed resulting
  // In a tag like <Vendor|null|Uber>
  // React.useEffect(() => {
  //   currentElement.removeAttribute(DATA_ID)
  // })

  const onItemSelected = React.useCallback(
    (data: ACItem) => {
      const entityKind = decoratedEntityToEntityKind(data.type)
      if (!entityKind) return

      currentElement.setAttribute(DATA_ENTITY_KIND, entityKind)
      currentElement.setAttribute(DATA_DECORATOR, data.decorator)
      currentElement.setAttribute(DATA_ID, data.id)
      currentElement.innerHTML = data.decoratedValue

      const whiteSpace = document.createElement(TagNames.Span)
      whiteSpace.innerHTML = NodeValues.WhiteSpace
      currentElement.insertAdjacentElement("afterend", whiteSpace)

      onAutoCompleted?.(data)
      caretHelper.placeAtEnd(whiteSpace)
    },
    [currentElement, onAutoCompleted]
  )

  const onItemClick = React.useCallback(
    (data: ACItem, event: React.MouseEvent) => {
      event.stopPropagation()
      onItemSelected(data)
    },
    [onItemSelected]
  )

  const onKeyDown = React.useCallback(
    (event: KeyboardEvent) => {
      const { code } = event
      const fullMatch = dataSet[selectedIndex]?.searchValues.some(
        (v) => v.toLowerCase() === searchTerm
      )

      switch (code) {
        case Keys.Space:
          if (fullMatch) {
            event.preventDefault()
            event.stopPropagation()
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            onItemSelected(dataSet[selectedIndex]!)
          }
          break

        case Keys.Enter:
        case Keys.Tab:
          if (dataSet[selectedIndex]) {
            event.preventDefault()
            event.stopPropagation()
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            onItemSelected(dataSet[selectedIndex]!)
          }
          break

        case Keys.ArrowUp:
          if (selectedIndex > 0) {
            event.preventDefault()
            event.stopPropagation()
            setSelectedIndex(selectedIndex - 1)
            caretHelper.placeAtEnd(currentElement)
          }
          break

        case Keys.ArrowDown:
          if (selectedIndex < totalResults - 1) {
            event.preventDefault()
            event.stopPropagation()
            setSelectedIndex(selectedIndex + 1)
            caretHelper.placeAtEnd(currentElement)
          }
          break

        default:
          if (totalResults === 1) {
            return setSelectedIndex(0)
          }
          if (totalResults > 1 && selectedIndex >= totalResults) {
            setSelectedIndex(totalResults - 1)
          }
          break
      }
    },
    [dataSet, selectedIndex, searchTerm, totalResults, onItemSelected, currentElement]
  )
  useEvent("keydown", onKeyDown, content)

  if (!totalResults) return null

  let items = 0
  return (
    <Menu
      ref={menuRef}
      className={className}
      yPosition={yPosition}
      leftPosition={leftPosition}
      alignment={alignment}
    >
      {Array.from(resultsByType.keys()).map((type) => {
        const sectionResults = resultsByType.get(type)
        if (!sectionResults?.length) return null

        const sectionIndex = items
        items += sectionResults.length
        return (
          <MenuSection
            key={type}
            type={type}
            dataSet={sectionResults}
            sectionIndex={sectionIndex}
            selectedIndex={selectedIndex}
            onItemClick={onItemClick}
          />
        )
      })}
    </Menu>
  )
}

const MenuSection: React.FC<ACSectionProps> = ({
  type,
  dataSet,
  sectionIndex,
  selectedIndex,
  onItemClick,
}) => (
  <>
    <SectionTitle>{typeToTitle(type)}</SectionTitle>
    {dataSet.map((r, idx) => (
      <AutoCompleteItem
        key={r.id}
        data={r}
        onClick={onItemClick}
        selected={sectionIndex + idx === selectedIndex}
      />
    ))}
  </>
)

function typeToTitle(type: DecoratedEntity) {
  switch (type) {
    case Decorator.User:
      return "Users"
    case Decorator.Party:
      return "Vendors"
    case Decorator.Category:
      return "Categories"
    default: {
      const feRole = FrontendPartyRole.findByRole(type as PartyRole)
      return feRole?.pluralDisplayName ?? type
    }
  }
}

const AutoCompleteItem: React.FC<ACItemProps> = ({ data, onClick, selected }) => (
  <Item className={selected ? "selected" : ""} onMouseDown={onClick.bind(undefined, data)}>
    <AutoCompleteItemIcon data={data} />
    <Info>
      <Title>{data.title}</Title>
      <Subtitle>{data.subtitle}</Subtitle>
    </Info>
  </Item>
)

const AutoCompleteItemIcon: React.FC<{ data: ACItem }> = ({ data }) => {
  if (data.type === Decorator.Category) {
    return <CategoryIcon subjectDisplayKey={data.displayKey || ""} />
  }

  return <ColorIcon css="margin-right: 10px;" imageUrl={data.iconUrl} fallbackText={data.title} />
}

function usePrefetchACData(identifier: ObjectIdentifier) {
  const { currentOrganizationId } = useSession<FrontendSession>()
  return useListSuggestedUsers(identifier, currentOrganizationId)
}

// Returns a map from category ID to category ancestors with the most-distant ancestor first.
function useCategoryAncestorsLookup(): Map<string, Category[]> {
  const viewKey = useViewVersion()
  const { data } = useListCategoriesQuery({
    variables: { viewKey },
  })

  return React.useMemo(() => {
    const categoriesWithHierarchyData = convertHierarchicalDimensionTreeToFlatList<Category>(
      convertToHierarchicalDimensionTree(data?.listCategories ?? [], 0)
    )

    return categoriesWithHierarchyData.reduce((ancestorLookup, node) => {
      const ancestorCategories = getHierarchicalDimensionAncestors(node).map((a) => a.dimension)
      const categoryId = node.dimension.id
      ancestorLookup.set(categoryId, ancestorCategories)
      return ancestorLookup
    }, new Map<string, Category[]>())
  }, [data?.listCategories])
}

function useSearchQueries(text: string, types: Decorator[]) {
  const withParties = React.useMemo(() => types.includes(Decorator.Party), [types])
  const withCategories = React.useMemo(() => types.includes(Decorator.Category), [types])

  const { result: partyQueryResult, networkStatus: partyNetworkStatus } = useSearchQuery(
    text,
    ObjectKind.Party,
    !withParties
  )
  const { result: categoryQueryResult, networkStatus: categoryNetworkStatus } = useSearchQuery(
    text,
    ObjectKind.Category,
    !withCategories
  )

  return React.useMemo(() => {
    const loading =
      isNetworkRequestInFlight(partyNetworkStatus) ||
      isNetworkRequestInFlight(categoryNetworkStatus)

    const total = [partyQueryResult, categoryQueryResult].reduce(
      (sum, result) => sum + (result?.searchTerms?.total ?? 0),
      0
    )

    const partyResults = partyQueryResult?.searchTerms
    const parties = partyResults?.results
      .map((result) => {
        const partyId = result.objectId.id
        return partyResults?.entities.parties?.find((party) => party.id === partyId)
      })
      .filter(defined)

    const categoryResults = categoryQueryResult?.searchTerms
    const categories = categoryResults?.results
      .map((result) => {
        const categoryId = result.objectId.id
        return categoryResults?.entities.categories?.find((category) => category.id === categoryId)
      })
      .filter(defined)

    return {
      loading,
      total,

      parties,
      categories,
    }
  }, [categoryNetworkStatus, categoryQueryResult, partyNetworkStatus, partyQueryResult])
}

function useSearchQuery(query: string, kind: ObjectKind, skip = false) {
  const viewKey = useViewVersion()
  const text = query.trim()

  const response = useSearchTermsQuery({
    variables: {
      ...viewKey,
      text,
      kinds: [kind],
    },
    notifyOnNetworkStatusChange: true,
    skip: skip || !text,
  })

  const result = response.loading ? response.previousData : response.data
  // use the "current" network status otherwise the listener will always get "ready" from the previous response
  const { networkStatus } = response

  return {
    result,
    networkStatus,
  }
}

function useGroupedResults(dataSet: ACItem[]) {
  return React.useMemo(() => {
    const map: ResultsGroups = new Map()
    dataSet.forEach((item) => {
      const group: ACItem[] = map.get(item.type) || []
      group.push(item)
      map.set(item.type, group.slice(0, MENU_MAX_RESULTS))
    })

    const count = Array.from(map.values()).reduce((total, section) => total + section.length, 0)
    return { resultsByType: map, totalResults: count }
  }, [dataSet])
}

function useMenuPosition(
  menuRef: React.RefObject<HTMLDivElement | null>,
  element: HTMLElement,
  alignment: MenuAlignment
) {
  const [menuElement, setMenu] = React.useState(menuRef.current)
  // makes sure it is re-calculated when ref is set
  React.useLayoutEffect(() => {
    setMenu(menuRef.current)
  }, [menuRef])

  return React.useMemo(
    () =>
      alignment === MenuAlignment.Below
        ? getYPositionMenuBelow(menuElement, element)
        : getYPositionMenuAbove(menuElement, element),
    [menuElement, element, alignment]
  )
}

function getYPositionMenuBelow(menuElement: HTMLDivElement | null, element: HTMLElement) {
  const alignment = MenuAlignment.Below
  const { offsetParent } = element
  const offsetScrollTop = getOffsetScrollTop(offsetParent)
  const yPosition = element.offsetTop - offsetScrollTop + MENU_BELOW_EXTRA_OFFSET

  const parentYPosition = offsetParent?.getBoundingClientRect().y ?? 0
  if (parentYPosition + yPosition + (menuElement?.offsetHeight ?? 0) > window.innerHeight) {
    return getYPositionMenuAbove(menuElement, element)
  }

  return { yPosition, alignment }
}

function getYPositionMenuAbove(menuElement: HTMLDivElement | null, element: HTMLElement) {
  const alignment = MenuAlignment.Above
  const { offsetParent } = element
  const offsetScrollTop = getOffsetScrollTop(offsetParent)
  if (isElementNode(offsetParent)) {
    const offsetParentHeight = offsetParent.clientHeight
    const yPosition =
      offsetParentHeight - element.offsetTop + offsetScrollTop + MENU_ABOVE_EXTRA_OFFSET
    return { yPosition, alignment }
  }

  // we shouldn't reach this point, but if we do, this will pin the menu to the top of the comment area
  return {
    yPosition: Math.min(element.offsetTop + MENU_BELOW_EXTRA_OFFSET, COMMENT_CONTAINER_MAX_HEIGHT),
    alignment,
  }
}

function getOffsetScrollTop(offsetParent: Element | null) {
  const scrollContainer = offsetParent?.firstChild
  if (isElementNode(scrollContainer)) {
    return scrollContainer.scrollTop
  }
  return 0
}

function isElementNode(element: ChildNode | null | undefined): element is Element {
  return element?.nodeType === Node.ELEMENT_NODE
}
