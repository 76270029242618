import * as React from "react"
import {
  type Transaction,
  TransactionClassificationType,
} from "@digits-graphql/frontend/graphql-bearer"
import { svgIconStyles, svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgComputersEquipment } from "@digits-shared/components/SVGIcons/category/ComputersEquipment.svg"
import { SvgTransfers } from "@digits-shared/components/SVGIcons/custom/Transfers.svg"
import { SvgBank } from "@digits-shared/components/SVGIcons/line/Bank.svg"
import { SvgBankDollar } from "@digits-shared/components/SVGIcons/line/BankDollar.svg"
import { SvgBankNote03 } from "@digits-shared/components/SVGIcons/line/BankNote03.svg"
import { SvgCoinsHand02 } from "@digits-shared/components/SVGIcons/line/CoinsHand02.svg"
import { SvgCreditCard02 } from "@digits-shared/components/SVGIcons/line/CreditCard02.svg"
import { SvgInvoicing } from "@digits-shared/components/SVGIcons/line/Invoicing.svg"
import { SvgShieldTick } from "@digits-shared/components/SVGIcons/line/ShieldTick.svg"
import { SvgSwitchHorizontal01 } from "@digits-shared/components/SVGIcons/line/SwitchHorizontal01.svg"
import { Tooltip } from "@digits-shared/components/UI/Elements/Tooltip"
import { EXPANDED_CLASS_NAME, HOVERABLE_CLASS_NAME } from "@digits-shared/components/UI/Table"
import colors from "@digits-shared/themes/colors"
import styled, { css } from "styled-components"
import useTransactionFeed from "src/frontend/components/Shared/Contexts/TransactionFeedContext"
import { TransactionDescription } from "src/shared/components/Transactions/TransactionRow/TransactionDescription"
import { TransactionRecurrence } from "src/shared/components/Transactions/TransactionRow/TransactionRecurrence"
import { TransferTransaction } from "src/shared/components/Transactions/TransactionRow/TransferTransaction"
import {
  isTransactionGroup,
  type TransactionBodyProps,
} from "src/shared/components/Transactions/TransactionRow/types"
import transactionHelper from "src/shared/helpers/transactionHelper"

/*
  STYLES
*/

const TransactionBodyStyled = styled.div`
  position: relative;
  align-items: center;
  display: flex;
  justify-content: space-between;
`
const svgStyles = css`
  display: flex;
  height: 18px;
  width: 18px;
  ${svgIconStyles(colors.white)};
  opacity: 1;

  ${"." + HOVERABLE_CLASS_NAME}:not(.${EXPANDED_CLASS_NAME}):hover > td & {
    ${svgIconStyles(colors.black)};
  }
`

const svgPathIconStyles = css`
  display: flex;
  height: 18px;
  width: 18px;
  ${svgPathStyles(colors.white, 1.5)};
  opacity: 1;

  ${"." + HOVERABLE_CLASS_NAME}:not(.${EXPANDED_CLASS_NAME}):hover > td & {
    ${svgPathStyles(colors.black, 1.5)};
  }
`

const IconContainer = styled.div`
  right: 120px;
  position: absolute;
`

const TransferIcon = styled(SvgTransfers)`
  ${svgStyles}
`

const CardIcon = styled(SvgCreditCard02)`
  ${svgPathIconStyles}
`

const CheckIcon = styled(SvgBankNote03)`
  ${svgPathIconStyles}
`

const WireIcon = styled(SvgSwitchHorizontal01)`
  ${svgPathIconStyles}
`

const AchIcon = styled(SvgSwitchHorizontal01)`
  ${svgPathIconStyles}
`

const AccountsPayableIcon = styled(SvgCoinsHand02)`
  ${svgPathIconStyles}
`

const DebitCardIcon = styled(SvgBank)`
  ${svgPathIconStyles}
`

const ATMIcon = styled(SvgBankDollar)`
  ${svgPathIconStyles}
`

const OnlineTransferIcon = styled(SvgComputersEquipment)`
  ${svgPathIconStyles}
`

const DirectDepositIcon = styled(SvgShieldTick)`
  ${svgPathIconStyles}
`

const ExpenseReportIcon = styled(SvgInvoicing)`
  ${svgPathIconStyles}
`

const StyledTooltip = styled(Tooltip)`
  display: none;

  ${IconContainer}:hover & {
    display: flex;
  }
`

/*
  COMPONENT
*/

export const TransactionBody: React.FC<TransactionBodyProps> = ({
  data,
  loading,
  list,
  className,
  rowSubtitle,
}) => {
  const transactionFeed = useTransactionFeed()
  const transaction = isTransactionGroup(data) ? data.transactions[0] : data
  if (transaction && transactionHelper.isTransfer(transaction)) {
    return <TransferTransaction transaction={transaction} />
  }

  return (
    <TransactionBodyStyled className={className}>
      <TransactionDescription
        data={transaction}
        rowSubtitle={rowSubtitle}
        loading={loading}
        list={list}
      />
      {transactionFeed && (
        <IconContainer>
          <StyledTooltip left="30px" top="-3px">
            {transaction?.transactionClassificationType}
          </StyledTooltip>
          <ClassificationTypeIcon transaction={transaction} />
        </IconContainer>
      )}
      {!loading && transaction && <TransactionRecurrence transaction={transaction} />}
    </TransactionBodyStyled>
  )
}

export const ClassificationTypeIcon: React.FC<{ transaction?: Transaction }> = ({
  transaction,
}) => {
  switch (transaction?.transactionClassificationType) {
    case TransactionClassificationType.AccountsPayable:
      return <AccountsPayableIcon />
    case TransactionClassificationType.ACH:
      return <AchIcon />
    case TransactionClassificationType.ATM:
      return <ATMIcon />
    case TransactionClassificationType.Charge:
      return <CardIcon>Charge</CardIcon>
    case TransactionClassificationType.Check:
      return <CheckIcon />
    case TransactionClassificationType.DebitCard:
      return <DebitCardIcon />
    case TransactionClassificationType.DirectDeposit:
      return <DirectDepositIcon />
    case TransactionClassificationType.ExpenseReport:
      return <ExpenseReportIcon />
    case TransactionClassificationType.OnlineTransfer:
      return <OnlineTransferIcon />
    case TransactionClassificationType.Transfer:
      return <TransferIcon />
    case TransactionClassificationType.Wire:
      return <WireIcon />
    default:
      return null
  }
}
