import * as React from "react"
import { type ApolloError, type BaseMutationOptions } from "@apollo/client"
import {
  type Comment,
  type ObjectEntities,
  type ObjectIdentifier,
  ObjectKind,
  type Thread,
  type ThreadDetails,
} from "@digits-graphql/frontend/graphql-bearer"
import deepmerge from "deepmerge"
import { type AssigneeWithName } from "src/shared/components/Comments/useInferAssignee"
import { type MenuAlignment } from "src/shared/components/EditableContent/AutoComplete"

export type AnimationState = "none" | "expanded" | "collapsed" | "bubble"

interface ThreadContextI extends React.Context<ThreadContextProps> {
  Extend: React.FC<{ value: object }>
}

export type OnAddComment = (
  thread: Thread,
  comment: Comment,
  entities: ObjectEntities | undefined
) => void

export type OnError = (
  type: "create" | "reply" | "resolve",
  error: ApolloError,
  clientOptions?: BaseMutationOptions
) => void

export type ChooseAssignee = (choices: {
  lastAuthor: AssigneeWithName | undefined
  affiliateOrganization: AssigneeWithName | undefined
  legalEntityOrganization: AssigneeWithName | undefined
  firstUser: AssigneeWithName | undefined
}) => AssigneeWithName | undefined

export interface ThreadContextProps {
  alignClientOppositeEmployees?: boolean
  autoCompleteMenuAlignment?: MenuAlignment
  activeThreadId?: string
  activeThreadDetails?: ThreadDetails | null
  allowResolvedThreads: boolean
  resolvedThreads?: Thread[]
  resolveButtonText?: string
  threadEntities?: ObjectEntities | null
  loading?: boolean
  context?: string
  targetObject: ObjectIdentifier
  chooseAssignee?: ChooseAssignee
  autoFocus: boolean
  animationState?: AnimationState
  canComment: boolean
  hideCancel?: boolean
  placeholder?: string
  preventResolve?: boolean
  skipRefetch?: boolean
  onCreate?: OnAddComment
  onUpdate?: OnAddComment
  onError?: OnError
  onResolveUpdate?: (threadId: string, resolveComplete: boolean) => void
  onBeforeResolve?: (threadId: string) => void | Promise<void> | { skipResolve: boolean }
  onClick?: (threadDetails: ThreadDetails) => void
  onFocus?: (threadDetails?: ThreadDetails | string) => void
  onClose?: (threadDetails: ThreadDetails | null | undefined, e?: Event) => void
}

export const BaseThreadContext = React.createContext<ThreadContextProps>({
  targetObject: { kind: ObjectKind.Transaction, id: "", legalEntityId: "" },
  autoFocus: true,
  canComment: false,
  allowResolvedThreads: false,
})

const Extend: React.FC<{ value: object; children?: React.ReactNode }> = ({ children, value }) => (
  <BaseThreadContext.Consumer>
    {(theme) => (
      <BaseThreadContext.Provider value={deepmerge(theme, value)}>
        {children}
      </BaseThreadContext.Provider>
    )}
  </BaseThreadContext.Consumer>
)

export const ThreadContext = BaseThreadContext as ThreadContextI
ThreadContext.Extend = Extend

export default function useThreadContext() {
  const context = React.useContext(ThreadContext)

  // Memoize so that a new object is only returned if something changes
  return React.useMemo(() => context, [context])
}
