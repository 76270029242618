import * as React from "react"
import { Link } from "react-router-dom"
import { type ActionItemFieldsFragment } from "@digits-graphql/frontend/graphql-bearer"
import colors from "@digits-shared/themes/colors"
import styled, { css } from "styled-components"
import dayjs from "@digits-shared/initializers/dayjs/dayjs"
import { BillRow } from "src/frontend/components/Shared/NavSidebar/ActionItems/Row/BillRow"
import { DateContainer } from "src/frontend/components/Shared/NavSidebar/ActionItems/Row/NameAndDate"
import { ReportRow } from "src/frontend/components/Shared/NavSidebar/ActionItems/Row/ReportRow"
import { ThreadRow } from "src/frontend/components/Shared/NavSidebar/ActionItems/Row/ThreadRow"
import { useGenerateActionItemPath } from "src/frontend/components/Shared/NavSidebar/ActionItems/Row/useGenerateActionItemPath"
import { useHasActionButtons } from "src/frontend/components/Shared/NavSidebar/ActionItems/useGroupView"

/*
  STYLES
*/

const RowListItem = styled.li`
  display: contents;
`

export const ActionRowLink = styled(Link)<{ disabled: boolean; $hasButtons: boolean }>`
  margin: 0 16px 0 16px;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
  border-radius: 8px;
  user-select: none;
  cursor: pointer;
  transition: all 100ms ease-in;
  padding: 12px;

  background-color: ${colors.translucentWhite60};

  &:hover {
    background-color: ${colors.white};
    box-shadow: 0 4px 20px ${colors.translucentBlack06};

    ${({ $hasButtons }) =>
      $hasButtons &&
      css`
        ${DateContainer} {
          display: none;
        }
      `};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      filter: grayscale(1);
      pointer-events: none;
      background: ${colors.translucentSecondary05};
    `}
`

/*
  COMPONENTS
*/

export const Row: React.FC<{ item: ActionItemFieldsFragment; hideSidebar: () => void }> = ({
  item,
  hideSidebar,
}) => {
  const to = useGenerateActionItemPath(item)
  const isDisabled = !to
  return (
    <RowListItem>
      <ActionRowLink
        to={to}
        disabled={isDisabled}
        $hasButtons={useHasActionButtons()}
        onClick={isWorkItemThread(item) ? hideSidebar : undefined}
      >
        <RowContents item={item} hideSidebar={hideSidebar} />
      </ActionRowLink>
    </RowListItem>
  )
}

const RowContents: React.FC<{ item: ActionItemFieldsFragment; hideSidebar: () => void }> = ({
  item,
}) => {
  const { id, value, title, author, updatedAt } = item
  const date = dayjs.unix(updatedAt).utc().fromNow()

  switch (value.__typename) {
    case "ActionItemBillApproval":
      return (
        <BillRow
          actionItemId={id}
          author={author}
          title={title}
          amount={value.bill.totalAmount}
          dueDate={value.bill.dueDate}
          date={date}
        />
      )
    case "ActionItemThread": {
      return (
        <ThreadRow
          actionItemId={id}
          author={author}
          date={date}
          threadId={value.threadId}
          targetEntity={value.targetEntity}
        />
      )
    }
    case "ActionItemPublishedReport":
      return <ReportRow actionItemId={id} title={title} author={author} date={date} />

    default:
      return null
  }
}

function isWorkItemThread(actionItem: ActionItemFieldsFragment) {
  return (
    actionItem.value.__typename === "ActionItemThread" &&
    actionItem.value.targetEntity.__typename === "EntityWorkItem"
  )
}
