import * as React from "react"
import colors from "@digits-shared/themes/colors"
import styled, { css } from "styled-components"
import { INPUT_INVALID_CLASS_NAME, INPUT_STYLES } from "./InputStyles"
import { InputLabel, InputSaveStatus, Label } from "./Label"
export type InputVariant = "primary" | "bordered"

export const InputStyled = styled.input<{ $variant?: InputVariant }>`
  ${INPUT_STYLES};
`

export const InputBlock = styled.div<InputBlockProps>`
  width: ${({ inputWidth }) => (inputWidth ? `${inputWidth}` : "100%")};
  margin-bottom: ${({ inputMarginBottom }) =>
    inputMarginBottom ? `${inputMarginBottom}` : "20px"};

  &.${INPUT_INVALID_CLASS_NAME} {
    input,
    input:hover:enabled,
    input:focus-within {
      border-image-source: unset;
      ${({ $variant }) => {
        switch ($variant) {
          case "bordered":
            return css`
              border-color: ${colors.orange};
              border: 1px solid ${colors.orange};
            `
          case "primary":
          default:
            return css`
              border-bottom-color: ${colors.orange};
            `
        }
      }};
    }

    ${Label} {
      color: ${colors.orange};
    }
  }

  ${({ hidden }) =>
    hidden &&
    css`
      margin: 0;
    `}
`

/*
  INPUT
*/

export interface InputProps {
  forwardRef?: React.RefObject<HTMLInputElement | null>
  label?: string
  inputWidth?: string
  inputMarginBottom?: string
  isInvalid?: boolean
  saveStatus?: InputSaveStatus
  onAnimationEnd?: (event: React.AnimationEvent) => void
  $variant?: InputVariant
}

interface InputBlockProps {
  inputWidth?: string
  inputMarginBottom?: string
  hidden?: boolean
  $variant?: InputVariant
}

export type InputElementProps = InputProps &
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

export type SelectElementProps = InputProps &
  React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>

/*
  COMPONENTS
*/

export const Input: React.FC<InputElementProps> = (props) => {
  const {
    ref,
    forwardRef,
    isInvalid,
    disabled,
    saveStatus,
    onAnimationEnd,
    label,
    inputWidth,
    inputMarginBottom,
    className,
    autoFocus,
    hidden,
    $variant,
    ...rest
  } = props
  const pending = saveStatus === InputSaveStatus.Pending

  return (
    <InputBlock
      className={isInvalid ? INPUT_INVALID_CLASS_NAME : ""}
      inputWidth={inputWidth}
      inputMarginBottom={inputMarginBottom}
      hidden={hidden}
      $variant={$variant}
    >
      {label && (
        <InputLabel
          htmlFor={props.id}
          label={label}
          saveStatus={saveStatus}
          onAnimationEnd={onAnimationEnd}
          $variant={$variant}
        />
      )}
      <InputStyled
        className={className}
        disabled={disabled || pending}
        ref={forwardRef}
        autoComplete="off"
        autoFocus={autoFocus}
        hidden={hidden}
        $variant={$variant}
        {...rest}
      />
    </InputBlock>
  )
}
