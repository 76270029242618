import {
  type ObjectEntities,
  type ObjectEntitiesFieldsFragment,
} from "@digits-graphql/frontend/graphql-bearer"
import objectHelper from "@digits-shared/helpers/objectHelper"

// This will cause a TypeScript error if allKeys is out of sync
// Add your new keys to EMPTY_OBJECT_ENTITIES
const EMPTY_OBJECT_ENTITIES = Object.freeze({
  __typename: "ObjectEntities",
  users: null,
  transactions: null,
  comments: null,
  reports: null,
  reportPackages: null,
  parties: null,
  datasources: null,
  categories: null,
  threads: null,
  invoices: null,
  legalEntities: null,
  workItems: null,
  bills: null,
  identityVerifications: null,
  departments: null,
  locations: null,
}) satisfies Required<ObjectEntitiesFieldsFragment>

export default {
  merge(obj1: ObjectEntities = {}, obj2: ObjectEntities = {}) {
    const merged: ObjectEntitiesFieldsFragment = { ...EMPTY_OBJECT_ENTITIES }

    objectHelper.keysOf(merged).forEach((key) => {
      if (key === "__typename" || (!obj1[key] && !obj2[key])) return
      const array1 = obj1[key] ?? []
      const array2 = obj2[key] ?? []
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      merged[key] = [...array1, ...array2]
    })

    return merged
  },
}
