import * as React from "react"
import {
  ActionItemKind,
  type Date,
  type EntityUser,
  type MonetaryValue,
} from "@digits-graphql/frontend/graphql-bearer"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import numberHelper from "@digits-shared/helpers/numberHelper"
import userHelper from "@digits-shared/helpers/userHelper"
import { BillActions } from "src/frontend/components/Shared/NavSidebar/ActionItems/Row/ActionItemActions"
import { ActionItemRow } from "src/frontend/components/Shared/NavSidebar/ActionItems/Row/ActionItemRow"
import { NameDateDetail } from "src/frontend/components/Shared/NavSidebar/ActionItems/Row/NameAndDate"

/*
  COMPONENTS
*/

export const BillRow: React.FC<{
  actionItemId: string
  author?: EntityUser | null
  title?: string | null
  amount?: MonetaryValue | null
  dueDate?: Date | null
  date: string
}> = ({ actionItemId, author, dueDate, amount, title, date }) => {
  const fullTitle = React.useMemo(() => {
    const formattedAmount = amount && numberHelper.currency(amount)
    const billDueMoment = dueDate && dateTimeHelper.dayjsFromGQLDate(dueDate)
    if (formattedAmount && billDueMoment) {
      return `Requested approval of a ${formattedAmount} bill due ${billDueMoment.format("MMMM D")}`
    }

    if (!billDueMoment && formattedAmount) {
      return `Requested approval of a ${formattedAmount} bill`
    }

    if (billDueMoment && !formattedAmount) {
      return `Requested approval of a bill due ${billDueMoment.format("MMMM D")}`
    }

    return "Requested approval of a bill"
  }, [amount, dueDate])

  return (
    <>
      <ActionItemRow title={fullTitle} actionItemKind={ActionItemKind.Bill}>
        {author && (
          <NameDateDetail
            date={date}
            secondary={userHelper.displayName(author, "abbreviateLast")}
          />
        )}
      </ActionItemRow>
      <BillActions actionItemId={actionItemId} />
    </>
  )
}
