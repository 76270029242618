import * as React from "react"
import { responsiveStyles } from "@digits-shared/components/Responsive/responsiveStyles"
import styled, { css } from "styled-components"
import { DetailsViewTransitions } from "src/frontend/components/Shared/Chrome/DetailsViewComponent"

/*
 STYLES
*/

const contentViewResponsiveStyles = responsiveStyles({
  desktop: css`
    height: 100%;
    width: 100%;
  `,
  mobile: css`
    min-height: var(--viewport-height);
    width: var(--viewport-width);
  `,
})

interface StyledProps {
  $hasActiveDetailsView?: boolean
  $returningFromDetailsView?: boolean
}

const ContentViewRendererStyled = styled.article<StyledProps>`
  ${contentViewResponsiveStyles};
  display: flex;
  flex-direction: column;

  ${({ $hasActiveDetailsView }) =>
    $hasActiveDetailsView &&
    css`
      z-index: -1;
      pointer-events: none;
    `}

  ${({ $hasActiveDetailsView }) =>
    $hasActiveDetailsView &&
    css`
      transform: translate3d(0, 0, -110px);
      opacity: 1;
    `}
  
  ${({ $returningFromDetailsView }) =>
    $returningFromDetailsView &&
    css`
      transition: transform ${DetailsViewTransitions.zoomOut.duration}
        ${DetailsViewTransitions.zoomOut.delay} ease-out;
    `}
`

/*
 INTERFACES
*/

interface Props {
  hasActiveDetailsView?: boolean
  returningFromDetailsView?: boolean
  children?: React.ReactNode
}

export const ContentViewRenderer: React.FC<Props> = (props) => {
  const { children, hasActiveDetailsView, returningFromDetailsView } = props

  return (
    <ContentViewRendererStyled
      id="content-view-renderer"
      $returningFromDetailsView={returningFromDetailsView}
      $hasActiveDetailsView={hasActiveDetailsView}
      inert={hasActiveDetailsView ?? undefined}
    >
      {children}
    </ContentViewRendererStyled>
  )
}
