import * as React from "react"

/**
 * This hook allows for setting an entire state object at once or changing the state of single
 * fields in that object. This effectively emulates a `React.Component.setState` in a hook. This is
 * a non-conventional usage of useReducer. It is abstracted to a separate custom hook to avoid
 * conflating this implementation with the typical usage of useReducer.
 *
 * @param initialState starting value for the state object.
 */
export default function useStateObject<T>(initialState: T) {
  return React.useReducer(
    (prevState: T, stateOverlay: Partial<T>) => ({ ...prevState, ...stateOverlay }),
    initialState
  )
}
