import * as React from "react"
import {
  Column,
  type ColumnDefinition,
  type ColumnRenderer,
  type Columns,
  type ColumnUsingComponent,
  type ColumnUsingRender,
  defaultLoadingRenderer,
  ExpandedRowCell,
  type LoadingColumn,
  type LoadingColumnRenders,
  PaddingCellDefaultWidth,
  renderDefaultPaddingCell,
  renderLeftSideExpandedCell,
  renderRightSideExpandedCell,
} from "@digits-shared/components/UI/Table/Column"
import {
  defaultHeaderRenderer,
  Header,
  type HeaderRenderer,
  type SortingColumns,
  sortingHeaderColumn,
} from "@digits-shared/components/UI/Table/Header"
import Row, { TableRow } from "@digits-shared/components/UI/Table/Row"
import { SortOrder, TableBody } from "@digits-shared/components/UI/Table/TableBody"
import {
  type DataIdentifier,
  getRowIdentifier,
  type Identifier,
  type RowBooleanValue,
} from "@digits-shared/components/UI/Table/tableConstants"
import numberHelper from "@digits-shared/helpers/numberHelper"
import objectHelper from "@digits-shared/helpers/objectHelper"
import { themedValue } from "@digits-shared/themes"
import colors from "@digits-shared/themes/colors"
import styled, { css } from "styled-components"
import { defaultFooterRenderer, Footer, type FooterRenderer } from "./Footer"

/*
  STYLES
*/

const messageColors = themedValue({
  light: colors.translucentSecondary80,
  dark: colors.regentGray,
})
const TableDataMessage = styled.td<{ noHeader: boolean }>`
  padding: 10px;
  text-align: center;
  font-size: 13px;
  color: ${messageColors};
  ${(props) =>
    !props.noHeader &&
    css`
      border-top: none;
    `}
`

export const TableStyled = styled.table`
  width: 100%;

  &.table-fixed {
    table-layout: fixed;
  }
`

/*
  INTERFACES
*/

interface NoDataComponent<W> {
  component: React.FC
  props?: W
}

type ReactDataElement = React.ReactElement | string | number | boolean | null | undefined

type NoData<W> = ReactDataElement | NoDataComponent<W>

/**
 * T: Data type
 * U: Column props interface (when using @type{ColumnComponent})
 * V: Header/Footer props interface (when using @type{HeaderComponent})
 * W: Empty-table props interface (when using @type{React.FC})
 */
export interface TableProps<T, U = {}, V = {}, W = {}> {
  columns: Columns<T, U>

  // Data
  data?: T[] | null
  error?: Error
  noDataMessage?: NoData<W>

  // Loading states
  loadingRows?: number // default 3
  isLoading?: boolean

  // Header
  noHeader?: boolean
  header?: HeaderRenderer<V>
  sortingColumns?: SortingColumns
  sortBy?: (data: T[], sortingColumn: string, order: SortOrder) => T[]
  serverSideTriggerForSort?: (sortingColumn: string, order: SortOrder) => void
  onSortingColumnClick?: (sortingColumn: string, order: SortOrder) => void

  // Footer
  footer?: FooterRenderer<V>

  // Columns
  addPaddingCells?: boolean | number

  // Search
  filterBy?: (data: T[]) => T[]
}

export interface TableRowProps<T> {
  // Rows
  getDataIdentifier?: DataIdentifier<T>
  getRowClassName?: (data: T, index: number, list: T[]) => string
  className?: string

  // If `onRowClick` returns a boolean, will determine if the row should be selected. This is done
  // in this manner to allow for only the selected row to rerender to make selected, rather than
  // the entire table.
  onRowClick?: (data: T, index: number, event: React.MouseEvent) => boolean | void
  shouldRowBeSelected?: RowBooleanValue<T>

  isRowHoverable?: RowBooleanValue<T>
  // These 2 functions should be used scarcely as they will likely trigger a full table re-render.
  // For most cases you want to use CSS `:hover` to quickly update the UI.
  // The intention of these methods is notify a parent component about the hover states,
  // for example to render another sibling component of this table.
  onRowMouseOver?: (data: T, index: number, event: React.MouseEvent) => void
  onRowMouseOut?: (data: T, index: number, event: React.MouseEvent) => void

  isRowExpandable?: RowBooleanValue<T>
  renderExpandedRowElements?: (data: T, index: number, list: T[]) => React.ReactNode

  isLeftSideExpandable?: boolean
  renderLeftSideExpanded?: (data: T, index: number) => React.ReactNode
  isRightSideExpandable?: boolean
  renderRightSideExpanded?: (data: T, index: number) => React.ReactNode

  // Rows that will be expanded from the beginning.
  // The indices will be applied to the `data[]`
  expandedRows?: Identifier[]
  collapsedRows?: Identifier[]
  neverHoverParty?: boolean
}

interface TableState {
  sortedBy?: SortingColumns
  defaultSortedBy?: SortingColumns // given by props

  expandedRows: Identifier[]
}

export type Props<T, U, V, W> = TableProps<T, U, V, W> & TableRowProps<T>

/**
 * Render a table using custom column renderer. Can also handle loading blocks (animated)
 * by specifying the sizes of these blocks
 *
 * @param {Columns<T, U>} columns The name of the columns and the renderer per each column. You can
 * use `defaultColumnRenderer` if you just want text inside a cell.
 * @param {T[]?} data Array of generic values to be rendered as content. Each item in the array is
 * a row in the table
 * @param {Error?} error If there was an error retrieving the data, it just shows an error message
 * @param {boolean?} isLoading If the loading message or loading blocks should be rendered instead
 * of data
 * @param {number?} loadingRows Number of loading rows to be rendered when `isLoading = true`.
 * `loadingColumnsSizes` must be passed in to render these rows.
 * @param {LoadingColumns?} loadingColumnsSizes A dictionary for each column and their respective
 * loading block size (width & height). When passed in, this Table will automatically render
 * a default loading block with the specified dimensions for each column. `isLoading` must be `true`
 * @param {Columns<LoadingColumns>?} customLoadingColumns If a particular column needs a different
 * loading block content you may pass in a new loading column renderer. This is optional.
 * @param {boolean?} noHeader Table body without header
 * @param {HeaderRenderer<T>?} header Custom function to render each header column
 * @param {boolean? | number?} addPaddingCells Empty columns for extra padding that we can highlight, number to specify customt width to render the padding cells at.
 * @param {SortingColumns?} sortingColumns A dictionary with all the columns that we can sort the
 * table by. Currently, only one sorting column can be applied at a time. The column names must
 * match a subset of the column names in `columns`.
 * @param {Function?} sortBy This function will be called when the user clicks on a sorting column.
 * It will receive the existing array of `data` and the new column to sort by. The function should
 * return the `data` sorted by this column.
 * @param {Function?} serverSideTriggerForSort When data needs to be sorted server-side, this function
 * is called when the user clicks on sorting column but the table will not try to sort the data.
 * Rather the caller should call the server an update the table with new props when it receives the
 * new sorted data from the server.
 * @param {Function?} onSortingColumnClick Fired when sorting is clicked.
 * @param {React.ReactNode | React.FC} noDataMessage to be rendered in the event of no data
 * @param {Function?} filterBy This function will be called to filter out values during rending and
 * after sorting. It will receive the existing array of `data`. The function should return
 * the filtered `data`.
 */
export class Table<
  T,
  U = {},
  V = {},
  W extends React.JSX.IntrinsicAttributes & { children?: React.ReactNode } = {},
> extends React.Component<Props<T, U, V, W>, TableState> {
  state = {
    sortedBy: Table.reduceSortingColumns(this.props.sortingColumns),
    expandedRows: this.props.expandedRows || Array<Identifier>(),
  }

  element = React.createRef<HTMLTableElement>()

  static getDerivedStateFromProps<T, U, V, W>(props: Props<T, U, V, W>, state: TableState) {
    let expandedRows = props.isLoading
      ? props.expandedRows || Array<Identifier>()
      : (props.expandedRows || []).concat(state.expandedRows)

    if (props.collapsedRows && props.collapsedRows.length) {
      expandedRows = expandedRows.filter(
        (idx) => props.collapsedRows && !props.collapsedRows.includes(idx)
      )
    }

    // Default sortedBy to state or the default table value
    const defaultSortedBy = Table.reduceSortingColumns(props.sortingColumns)
    const defaultStateSortedBy = Table.reduceSortingColumns(state.defaultSortedBy)
    const sortedBy =
      state.sortedBy && JSON.stringify(defaultSortedBy) === JSON.stringify(defaultStateSortedBy)
        ? state.sortedBy
        : defaultSortedBy

    return {
      // If we are loading, we should reset the expanded rows to
      // avoid having rows expanded after loading new data
      expandedRows,
      defaultSortedBy,
      sortedBy,
    }
  }

  static reduceSortingColumns(sortingColumns?: SortingColumns) {
    // for all sorting columns passed in props, set the state to reflect the correct initial state
    // A new `SortingColumns` object is created with all the sorting columns where
    // `isSortedByColumn` is true
    return (
      sortingColumns &&
      Object.keys(sortingColumns).reduce<SortingColumns>((map, key) => {
        const column = sortingColumns[key]
        if (column && column.isSortedByColumn) {
          map[key] = column
        }
        return map
      }, {})
    )
  }

  render() {
    const { className } = this.props
    const colSizes = this.renderColSizes()

    const classNames = [className || "", colSizes ? "table-fixed" : ""].join(" ")

    return (
      <TableStyled ref={this.element} cellPadding={0} cellSpacing={0} className={classNames}>
        {colSizes}
        {this.renderTableHeader()}
        {this.renderTableBody()}
        {this.renderTableFooter()}
      </TableStyled>
    )
  }

  renderTableHeader() {
    const {
      noHeader,
      columns,
      isLoading,
      addPaddingCells,
      isLeftSideExpandable,
      isRightSideExpandable,
    } = this.props
    if (noHeader) {
      return null
    }

    const columnNames = Object.keys(columns)

    return (
      <Header
        isLoading={isLoading}
        addPaddingCells={addPaddingCells}
        isLeftSideExpandable={isLeftSideExpandable}
        isRightSideExpandable={isRightSideExpandable}
      >
        {columnNames.map(this.renderHeaderColumn)}
      </Header>
    )
  }

  renderTableFooter() {
    const {
      footer,
      columns,
      isLoading,
      addPaddingCells,
      isLeftSideExpandable,
      isRightSideExpandable,
    } = this.props

    if (!footer) return null

    const columnNames = Object.keys(columns)

    const renderedColumns = columnNames.map((columnName, key) => {
      const colSize = columns[columnName]?.size
      return defaultFooterRenderer(columnName, key, footer, colSize)
    })

    return (
      <Footer
        isLoading={isLoading}
        addPaddingCells={addPaddingCells}
        isLeftSideExpandable={isLeftSideExpandable}
        isRightSideExpandable={isRightSideExpandable}
      >
        {renderedColumns}
      </Footer>
    )
  }

  renderHeaderColumn = (columnName: string, index: number) => {
    const { sortingColumns, columns, sortBy, serverSideTriggerForSort, header } = this.props
    const { sortedBy } = this.state
    const sortingProps = sortingColumns && sortingColumns[columnName]
    const colSize = columns[columnName]?.size

    if (sortingProps) {
      const currentSortingProps =
        sortedBy && sortedBy[columnName] ? { ...sortedBy[columnName] } : undefined

      const headerColumnProps = {
        colSize,
        disableSorting: !(sortBy || serverSideTriggerForSort),
        onHeaderClick: this.onSortingHeaderClick,
        ...(currentSortingProps || sortingProps),
      }

      return sortingHeaderColumn(headerColumnProps, columnName, index, header)
    }

    return defaultHeaderRenderer(columnName, index, header, colSize)
  }

  renderTableMessage(message: ReactDataElement) {
    if (!message) return
    const { noHeader, columns, addPaddingCells } = this.props
    const colSpan = Object.keys(columns).length

    return (
      <TableRow>
        {/* Empty for extra padding that we can highlight */}
        {addPaddingCells && renderDefaultPaddingCell("padding-1")}
        <TableDataMessage noHeader={noHeader || false} colSpan={colSpan}>
          {message}
        </TableDataMessage>
        {/* Empty for extra padding that we can highlight */}
        {addPaddingCells && renderDefaultPaddingCell("padding-2")}
      </TableRow>
    )
  }

  renderColSizes() {
    const { columns, addPaddingCells, isLeftSideExpandable, isRightSideExpandable } = this.props

    const colSizes = Object.keys(columns)
      .map((colName) => {
        const { size } = columns[colName] || {}
        if (!size) {
          return null
        }
        return <col key={colName} width={size.width} />
      })
      .filter((size) => size !== null)

    if (colSizes.length === 0) {
      return
    }

    const paddingCellWidth = numberHelper.isNumber(addPaddingCells)
      ? addPaddingCells
      : PaddingCellDefaultWidth

    return (
      <colgroup>
        {isLeftSideExpandable && <col key="left-side-expand" />}
        {addPaddingCells && <col key="padding-1" width={paddingCellWidth} />}
        {colSizes}
        {addPaddingCells && <col key="padding-2" width={paddingCellWidth} />}
        {isRightSideExpandable && <col key="right-side-expand" />}
      </colgroup>
    )
  }

  renderTableBody() {
    const { isLoading, columns, error } = this.props
    const { sortedBy } = this.state
    let body
    if (isLoading) {
      const hasLoadingSizes = Object.values(columns).find(
        (column) => !!(column.loadingSize || column.size)
      )

      body = hasLoadingSizes ? this.renderLoadingRows() : this.renderTableMessage("Loading…")
    }

    if (!body && error) {
      body = this.renderTableMessage(error.toString())
    }

    const data = this.getClientSortedData(sortedBy)
    if (!body && data?.length) {
      body = data.map(this.renderRow.bind(this, columns))
    }

    body = body || this.renderNoDataMessage()

    return <TableBody>{body}</TableBody>
  }

  renderNoDataMessage() {
    const { noDataMessage } = this.props
    if (!noDataMessage) {
      return this.renderTableMessage("No data found.")
    }

    let content = noDataMessage as ReactDataElement

    const usingComponent = noDataMessage as NoDataComponent<W>
    if (usingComponent.component) {
      const props = usingComponent.props || ({} as W)
      const EmptyComponent = usingComponent.component
      content = (
        <EmptyComponent
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      )
    }

    return this.renderTableMessage(content)
  }

  renderLoadingRows() {
    const { loadingRows, columns } = this.props
    const loadingColumnRenderers: LoadingColumnRenders<T, U> = {}

    Object.entries(columns).forEach(([key, column]) => {
      const defaultSize = {
        height: (column.size && column.size.height) || "100%",
        width: "100%", // the <col> size will take care of it and will account for paddings.
        textAlign: column.size && column.size.textAlign,
      }

      const component = isComponentColumn(column) ? column.component : undefined
      const render = isRenderedColumn(column)
        ? column.loadingRender
        : (component as ColumnRenderer<T>) || defaultLoadingRenderer

      loadingColumnRenderers[key] = {
        component,
        render,
        size: column.loadingSize || defaultSize,
        loadingColumn: true,
      }
    })

    const rowRenderers = Array(loadingRows || 3).fill(loadingColumnRenderers)
    return rowRenderers.map(this.renderRow.bind(this, loadingColumnRenderers))
  }

  renderRow = (
    columns: Columns<T, U> | LoadingColumnRenders<T, U>,
    data: T,
    index: number,
    list: T[]
  ) => {
    const {
      addPaddingCells,
      isLoading,
      isRowHoverable: propsIsRowHoverable,
      isRightSideExpandable,
      isLeftSideExpandable,
      renderLeftSideExpanded,
      renderRightSideExpanded,
      getRowClassName,
    } = this.props

    const isExpandable = this.isRowExpandable(data, index)
    const isExpanded = this.isRowExpanded(data, index)
    const toggledRow =
      isExpanded && !isLoading ? this.renderExpandedRow(data, index, list) : undefined

    const isRowHoverable = propsIsRowHoverable || isRightSideExpandable || isLeftSideExpandable

    const rowProps = {
      index,
      data,
      list,
      isExpandable,
      isExpanded,
      isRowHoverable,
      isLoading,
      getRowClassName,
    }

    if (!isLoading) {
      const { shouldRowBeSelected, getDataIdentifier, onRowMouseOver, onRowMouseOut } = this.props

      Object.assign(rowProps, {
        onRowClick: this.onRowClick,
        shouldRowBeSelected,
        isLeftSideExpandable,
        renderLeftSideExpanded,
        isRightSideExpandable,
        renderRightSideExpanded,
        getDataIdentifier,
        onRowMouseOver,
        onRowMouseOut,
      })
    }

    const leftSideExpandedCell =
      isLeftSideExpandable &&
      renderLeftSideExpanded &&
      renderLeftSideExpandedCell<T>(renderLeftSideExpanded, data, index)

    const rightSideExpandedCell =
      isRightSideExpandable &&
      renderRightSideExpanded &&
      renderRightSideExpandedCell<T>(renderRightSideExpanded, data, index)

    const identifier = getRowIdentifier<Props<T, U, V, W>, T>(this.props, data, index)
    return (
      <React.Fragment key={identifier}>
        <Row {...rowProps}>
          {leftSideExpandedCell}
          {/* Empty for extra padding that we can highlight */}
          {addPaddingCells && renderDefaultPaddingCell("padding-1")}
          {this.renderColumns(columns, data, index, list)}
          {/* Empty for extra padding that we can highlight */}
          {addPaddingCells && renderDefaultPaddingCell("padding-2")}
          {rightSideExpandedCell}
        </Row>
        {toggledRow}
      </React.Fragment>
    )
  }

  renderExpandedRow(data: T, index: number, list: T[]) {
    const { renderExpandedRowElements } = this.props
    const expanded = renderExpandedRowElements && renderExpandedRowElements(data, index, list)

    return (
      <Row index={index} data={data} list={list} isExpanded>
        <ExpandedRowCell colSpan={this.numberOfColumns()}>{expanded}</ExpandedRowCell>
      </Row>
    )
  }

  renderColumns(
    columns: Columns<T, U> | LoadingColumnRenders<T, U>,
    data: T,
    rowIndex: number,
    list: T[]
  ) {
    return Object.values(columns).map((column, colIndex) => {
      let content
      if (isComponentColumn(column)) {
        const props = column.props || ({} as U)
        const CellComponent = column.component
        content = (
          <CellComponent
            data={data}
            loading={isLoadingColumn(column)}
            rowIndex={rowIndex}
            colIndex={colIndex}
            list={list}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
          />
        )
      } else if (isRenderedColumn(column)) {
        content = column.render(data, rowIndex, colIndex)
      }

      return (
        <Column size={column.size} key={colIndex}>
          {content}
        </Column>
      )
    })
  }

  isRowExpandable(data: T, index: number) {
    const { isLoading, isRowExpandable, renderExpandedRowElements } = this.props
    const rowExpandable = !isLoading && !!renderExpandedRowElements

    if (typeof isRowExpandable === "function") {
      return rowExpandable && isRowExpandable(data, index)
    }

    if (typeof isRowExpandable === "boolean") {
      return rowExpandable && isRowExpandable
    }

    return rowExpandable
  }

  numberOfColumns = () => {
    const { columns, addPaddingCells } = this.props
    const numberOfColumns = Object.keys(columns).length
    return numberOfColumns + (addPaddingCells ? 2 : 0)
  }

  // If `onRowClick` returns a boolean, will determine if the row should be selected. This is done
  // in this manner to allow for only the selected row to rerender to make selected, rather than
  // the entire table.
  onRowClick = (data: T, index: number, event: React.MouseEvent<HTMLTableRowElement>) => {
    const { onRowClick, renderExpandedRowElements } = this.props
    let isSelected
    if (onRowClick) {
      isSelected = onRowClick(data, index, event)
      if (event.isPropagationStopped()) return isSelected
    }

    if (!renderExpandedRowElements || !this.isRowExpandable(data, index)) {
      return isSelected
    }

    this.setState((prevState) => {
      // collapse current expanded row if clicked again
      const expandedRows = prevState.expandedRows.slice()
      const identifier = getRowIdentifier<Props<T, U, V, W>, T>(this.props, data, index)

      const indexOfRowIndex = expandedRows.indexOf(identifier)
      if (indexOfRowIndex !== -1) {
        expandedRows.splice(indexOfRowIndex, 1)
      } else {
        expandedRows.push(identifier)
      }
      return { expandedRows }
    })

    return isSelected
  }

  onSortingHeaderClick = (columnName: string) => {
    const { sortedBy } = this.state
    const { sortingColumns } = this.props

    const newSortingColumns: SortingColumns = {}
    const currentSorting = sortedBy && sortedBy[columnName]

    // reset current sorting cols
    if (sortedBy) {
      objectHelper.keysOf(sortedBy).forEach((colName) => {
        if (!sortedBy || colName === columnName) return

        const sortingColumn = sortedBy[colName]
        if (sortingColumn) {
          sortingColumn.isSortedByColumn = false
          newSortingColumns[colName] = sortingColumn
        }
      })
    }

    // same sorting column, change the order
    if (currentSorting) {
      const currentSortingOrder = currentSorting.sortOrder
      currentSorting.sortOrder =
        currentSortingOrder === SortOrder.Ascending ? SortOrder.Descending : SortOrder.Ascending
      currentSorting.isSortedByColumn = true

      newSortingColumns[columnName] = currentSorting

      // new sorting column
    } else {
      const sortingColumn = sortingColumns && sortingColumns[columnName]
      if (!sortingColumn) {
        return
      }

      const newSortingColumn = { ...sortingColumn }
      newSortingColumn.isSortedByColumn = true
      newSortingColumns[columnName] = newSortingColumn
    }

    if (this.isClientSideSort(newSortingColumns)) {
      this.setState({ sortedBy: newSortingColumns })
    }
  }

  isClientSideSort(sortingColumns: SortingColumns) {
    const { data, serverSideTriggerForSort, onSortingColumnClick } = this.props
    if (!data || !data.length) return false

    const { sortingColumnName, sortingColumn } = this.getSortingColumnMetadata(sortingColumns)

    if (!sortingColumnName || !sortingColumn) return false

    if (onSortingColumnClick) onSortingColumnClick(sortingColumnName, sortingColumn.sortOrder)

    if (serverSideTriggerForSort) {
      serverSideTriggerForSort(sortingColumnName, sortingColumn.sortOrder)
      return false
    }

    return true
  }

  getSortingColumnMetadata(sortingColumns: SortingColumns) {
    const { data } = this.props
    if (!data || !data.length) return {}

    const sortingColumnName = Object.keys(sortingColumns).find((columnName) => {
      const sortingColumn = sortingColumns[columnName]
      return (sortingColumn && sortingColumn.isSortedByColumn) || false
    })

    if (!sortingColumnName) return {}

    const sortingColumn = sortingColumns[sortingColumnName]
    if (!sortingColumn) return {}

    return { sortingColumnName, sortingColumn }
  }

  getClientSortedData(sortingColumns?: SortingColumns) {
    const { data, sortBy } = this.props

    const filteredData = this.getClientFilteredData(data)
    if (!filteredData || !filteredData.length || !sortingColumns || !sortBy) return filteredData

    const { sortingColumnName, sortingColumn } = this.getSortingColumnMetadata(sortingColumns)
    if (!sortingColumnName || !sortingColumn) return filteredData

    return sortBy(filteredData.slice(), sortingColumnName, sortingColumn.sortOrder)
  }

  getClientFilteredData(data?: T[] | null) {
    const { filterBy } = this.props
    if (!data || !data.length || !filterBy) return data
    return filterBy(data)
  }

  isRowExpanded(data: T, rowIndex: number) {
    const { isLoading } = this.props
    const { expandedRows } = this.state
    const identifier = getRowIdentifier<Props<T, U, V, W>, T>(this.props, data, rowIndex)
    return !isLoading && expandedRows.includes(identifier)
  }
}

function isRenderedColumn<T, U>(column: ColumnDefinition<T, U>): column is ColumnUsingRender<T> {
  return !!(column as ColumnUsingRender<T>).render
}

function isComponentColumn<T, U>(
  column: ColumnDefinition<T, U>
): column is ColumnUsingComponent<T, U> {
  return !!(column as ColumnUsingComponent<T, U>).component
}

function isLoadingColumn<T, U>(
  column: ColumnDefinition<T, U> | LoadingColumn<T, U>
): column is LoadingColumn<T, U> {
  return (column as LoadingColumn<T, U>).loadingColumn !== undefined
}
