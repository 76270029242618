import * as React from "react"
import ReactDOM from "react-dom"
import { useGenerateExecutiveSummaryQuery } from "@digits-graphql/frontend/graphql-bearer"
import { svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgEdit05 } from "@digits-shared/components/SVGIcons/line/Edit05.svg"
import { ModalCenterPositioning } from "@digits-shared/components/UI/Elements/Modals/Modal"
import PendingIcon from "@digits-shared/components/UI/Elements/PendingIcon"
import { DigitsButton } from "@digits-shared/DesignSystem/Button"
import caretHelper from "@digits-shared/helpers/caretHelper"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import { useModalRoot } from "@digits-shared/hooks/useModalRoot"
import useSession from "@digits-shared/hooks/useSession"
import useStateBoolean from "@digits-shared/hooks/useStateBoolean"
import colors from "@digits-shared/themes/colors"
import fonts, { BodyText, H2Text } from "@digits-shared/themes/typography"
import styled, { css, keyframes } from "styled-components"
import { useReportIntervalOrigin } from "src/frontend/components/Shared/Reports/Report/hooks/useReportIntervalOrigin"
import type FrontendSession from "src/frontend/session"
import {
  NodeValues,
  TagNames,
} from "src/shared/components/EditableContent/editableContentConstants"
import DOMSanitize from "src/shared/components/ObjectEntities/DOMSanitize"
import { convertToDigitsEntityTags } from "src/shared/components/ObjectEntities/entitiesParserShared"
import { useStopClickPropagation } from "src/shared/hooks/useStopClickPropagation"

const PROMPT_TEMPLATE =
  "Hi, The {DATE} report is ready for your review! This draft us based on the information available as of today." +
  "Let us know if you have any questions!." +
  "- Digits Accounting Team"

/*
 STYLES
*/

const ModalMask = styled.div`
  position: fixed;
  background-image: radial-gradient(
    172% 378% at -9.5% -24%,
    rgba(0, 92, 140, 0.1) 0%,
    rgba(0, 10, 15, 0) 100%
  );
  backdrop-filter: blur(2px);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  overflow: auto;
`

const ModalContent = styled.div`
  width: 480px;
  min-height: 150px;
  background: ${colors.white};
  border-radius: 16px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`

const ICON_STYLES = css`
  height: 22px;
  width: 22px;
  ${svgPathStyles(colors.secondary, 1.5)};
`

const Header = styled(H2Text)`
  font-weight: ${fonts.weight.roman};
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 24px;
`

const Content = styled(BodyText)`
  padding: 0 24px 24px;
  flex: 1;
`

const Footer = styled.div`
  border-top: 1px solid ${colors.secondary10};
  padding: 16px 16px 8px;
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const AIResponseContainer = styled.div`
  border-radius: 8px;
  white-space: pre-wrap;
  line-height: 1.5;
`

const BLINKER = keyframes`
  0%{
    opacity: 0.8;
  }
  50% {
    opacity: 0.3;
  }
  100%{
    opacity: 0.8;
  }
`

const AICursor = styled.span`
  display: inline-block;
  width: 0.5em;
  animation: ${BLINKER} 700ms ease-in-out infinite alternate;
  vertical-align: text-bottom;
  &::before {
    content: "▄";
  }
`

/*
 INTERFACES
*/

interface AIGenerator {
  editableContentRef: React.RefObject<HTMLElement | null>
}

interface Modal {
  closeModal: () => void
  editableContentRef: React.RefObject<HTMLElement | null>
}

/*
 COMPONENTS
*/

export const ExecutiveSummaryAIGenerator: React.FC<React.PropsWithChildren<AIGenerator>> = ({
  editableContentRef,
  children,
}) => {
  const { isDigitsEmployee } = useSession<FrontendSession>()
  const { value: showModal, setTrue: openModal, setFalse: closeModal } = useStateBoolean()
  const stopPropagation = useStopClickPropagation({ preventDefault: true })

  if (!isDigitsEmployee) return null

  return (
    <>
      <div css="display: contents" onMouseDown={stopPropagation} onClick={openModal}>
        {children}
      </div>
      {showModal && (
        <NewSummaryModal closeModal={closeModal} editableContentRef={editableContentRef} />
      )}
    </>
  )
}

const NewSummaryModal: React.FC<Modal> = ({ closeModal, editableContentRef }) => {
  const {
    currentLegalEntity: { id: legalEntityId, name, slug },
  } = useSession<FrontendSession>()
  const origin = useReportIntervalOrigin()

  const promptText = React.useMemo(() => window.getSelection()?.toString() ?? "", [])

  const textReplacement = React.useMemo(() => !window.getSelection()?.isCollapsed, [])

  const { data, loading } = useGenerateExecutiveSummaryQuery({
    variables: {
      legalEntityId,
      prompt: `ENTITY_NAME:${name} SLUG:${slug} DATE:${dateTimeHelper.displayNameFromIntervalOrigin(origin)} CONTEXT:${promptText} TEMPLATE:${PROMPT_TEMPLATE}`,
    },
    fetchPolicy: "network-only",
  })

  const onReplaceText = React.useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault()
      event.stopPropagation()

      const newSummary = data?.generateExecutiveSummary
      const editableContent = editableContentRef.current
      if (!editableContent || !newSummary) return

      const newContent = sanitizeContent(newSummary)
      const whiteSpace = document.createElement(TagNames.Span)
      whiteSpace.innerHTML = NodeValues.WhiteSpace
      newContent.appendChild(whiteSpace)

      const selection = window.getSelection()
      const range = selection?.getRangeAt(0)
      if (range) {
        if (!selection?.isCollapsed) {
          range.deleteContents()
        }
        range.insertNode(newContent)
      }

      caretHelper.placeAtEnd(whiteSpace)
      closeModal()
    },
    [closeModal, data?.generateExecutiveSummary, editableContentRef]
  )

  const disabled = !data?.generateExecutiveSummary
  const modalRoot = useModalRoot()
  const stopPropagation = useStopClickPropagation({ preventDefault: true })
  return ReactDOM.createPortal(
    <ModalMask>
      <ModalCenterPositioning>
        <ModalContent onClick={stopPropagation}>
          <Header>
            <SvgEdit05 css={ICON_STYLES} />
            Generated Executive Summary
          </Header>
          <Content>
            {loading && (
              <>
                Generating
                <PendingIcon color="inherit" />
              </>
            )}
            <AIResponse content={data?.generateExecutiveSummary || ""} />
          </Content>
          <Footer>
            <DigitsButton onMouseDown={closeModal} $variant="secondary-dark" disabled={loading}>
              Cancel
            </DigitsButton>

            <DigitsButton onMouseDown={onReplaceText} $variant="primary" disabled={disabled}>
              {textReplacement ? "Replace" : "Insert"}
            </DigitsButton>
          </Footer>
        </ModalContent>
      </ModalCenterPositioning>
    </ModalMask>,
    modalRoot
  )
}

const AIResponse: React.FC<{ content: string }> = ({ content }) => {
  const displayRef = React.useRef<HTMLSpanElement>(null)
  const [currentIndex, setCurrentIndex] = React.useState(0)
  const startTime = React.useRef(Date.now())

  React.useEffect(() => {
    if (!content) {
      if (displayRef.current) {
        displayRef.current.innerHTML = ""
      }
      setCurrentIndex(0)
      startTime.current = Date.now()
      return
    }

    const elapsedTime = Date.now() - startTime.current
    const isSlowAnimation = elapsedTime > 4000
    const intervalTime = isSlowAnimation ? 40 : 20

    const timer = setInterval(() => {
      if (currentIndex >= content.length || !displayRef.current) {
        clearInterval(timer)
        return
      }

      let slicedContent: string
      if (isSlowAnimation) {
        // Find next word boundary
        let nextSpace = content.indexOf(" ", currentIndex)
        if (nextSpace === -1) nextSpace = content.length
        setCurrentIndex(nextSpace + 1)
        slicedContent = content.slice(0, nextSpace)
      } else {
        // Add 1 character at a time
        const nextIndex = Math.min(currentIndex + 1, content.length)
        setCurrentIndex(nextIndex)
        slicedContent = content.slice(0, nextIndex)
      }

      const fragment = sanitizeContent(slicedContent)
      displayRef.current.innerHTML = ""
      displayRef.current.appendChild(fragment)
    }, intervalTime)

    return () => clearInterval(timer)
  }, [content, currentIndex])

  if (!content) return null

  return (
    <AIResponseContainer>
      <span ref={displayRef} />
      {currentIndex < content.length && <AICursor />}
    </AIResponseContainer>
  )
}

function sanitizeContent(content: string) {
  return DOMSanitize.sanitize(
    convertToDigitsEntityTags(
      content.replaceAll(". ", ".\n\n").replaceAll("- Digits", "\n\n- Digits")
    )
  )
}
