import * as React from "react"
import {
  type ApolloCache,
  type ApolloError,
  type BaseMutationOptions,
  type FetchResult,
  type NormalizedCacheObject,
} from "@apollo/client"
import {
  type CommentFieldsFragment,
  type CreateThreadMutation,
  type EntityUser,
  ListActionItemsAndBadgeCountDocument,
  ListThreadsDocument,
  type NewComment,
  type ObjectEntitiesFieldsFragment,
  type ObjectIdentifier,
  ReadAssociatedThreadsDocument,
  type ReadAssociatedThreadsQuery,
  ReadThreadDocument,
  type ReadThreadQuery,
  type ReplyThreadMutation,
  type ResolveThreadMutation,
  type Thread,
  type ThreadDetails,
  ThreadDetailsFieldsFragmentDoc,
  type ThreadFieldsFragment,
  ThreadFieldsFragmentDoc,
  type ThreadWithEntitiesFieldsFragment,
  useCreateThreadMutation,
  useReplyThreadMutation,
  useResolveThreadMutation,
} from "@digits-graphql/frontend/graphql-bearer"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import envHelper from "@digits-shared/helpers/envHelper"
import objectEntitiesHelper from "@digits-shared/helpers/objectEntitiesHelper"
import objectHelper from "@digits-shared/helpers/objectHelper"
import promiseHelper from "@digits-shared/helpers/promises/promiseHelper"
import useSession from "@digits-shared/hooks/useSession"
import { type ExecutionResult } from "graphql"
import { TrackJS } from "trackjs"
import { v4 as generateUUID } from "uuid"
import dayjs from "@digits-shared/initializers/dayjs/dayjs"
import { PENDING_ID_PREFIX } from "src/shared/initializers/typePolicies"
import { useViewVersion } from "src/frontend/components/Shared/Contexts/useViewVersion"
import { type AssigneeDelegate } from "src/shared/components/Comments/CommentBox/ThreadAssignee"
import useThreadContext from "src/shared/components/Comments/ThreadContext"

// Do not refetch ReadThreadQuery because it will fetch "pending" threads
// which are created client side and will 404
const REFETCH_THREADS = [ReadAssociatedThreadsDocument, ListThreadsDocument]
export const REFETCH_ACTION_ITEMS = [ListActionItemsAndBadgeCountDocument]

const ALWAYS_REFETCH_ON_REPLY = [...REFETCH_ACTION_ITEMS]
const REFETCH_ON_REPLY = [...ALWAYS_REFETCH_ON_REPLY, ...REFETCH_THREADS]

const ALWAYS_REFETCH_ON_RESOLVE = [...REFETCH_ACTION_ITEMS]
const REFETCH_ON_RESOLVE = [...ALWAYS_REFETCH_ON_RESOLVE, ...REFETCH_THREADS]

export const useResolveThread = () => {
  const viewKey = useViewVersion()
  const { onResolveUpdate, skipRefetch, onError } = useThreadContext()
  const [resolveThread, { called }] = useResolveThreadMutation({
    refetchQueries: skipRefetch ? ALWAYS_REFETCH_ON_RESOLVE : REFETCH_ON_RESOLVE,
  })

  const onResolveError = React.useCallback(
    (error: ApolloError, clientOptions?: BaseMutationOptions) =>
      onError?.("resolve", error, clientOptions),
    [onError]
  )

  const resolveThreadOptimistically = React.useCallback(
    (threadId: string) =>
      resolveThread({
        variables: { threadId, viewKey },
        update: (
          cache: ApolloCache<NormalizedCacheObject>,
          resp: FetchResult<ResolveThreadMutation>
        ) => {
          const id = cache.identify({ id: threadId, __typename: "ThreadDetails" })
          cache.modify({
            id,
            fields: {
              resolvedAt() {
                return dateTimeHelper.unixNowSeconds()
              },
            },
            broadcast: true,
          })
          onResolveUpdate?.(threadId, resp.data?.resolveThread !== null)
        },
        optimisticResponse: () => ({
          // we use this null response to indicate it is the optimistic response
          resolveThread: null,
        }),
        onError: onError ? onResolveError : undefined,
      }).catch((error) => TrackJS?.track(error)),
    [resolveThread, viewKey, onError, onResolveError, onResolveUpdate]
  )

  return React.useMemo(
    () => ({ resolveThread: resolveThreadOptimistically, called }),
    [resolveThreadOptimistically, called]
  )
}

export function useCreateThread(assigneeDelegate: React.RefObject<AssigneeDelegate | undefined>) {
  const { targetObject, context, onCreate, onError, allowResolvedThreads, skipRefetch } =
    useThreadContext()
  const [counter, incrCounter] = React.useState(0)

  const viewKey = useViewVersion()
  const newThread = useNewThreadWithEntities()
  const onCreateError = React.useCallback(
    (error: ApolloError, clientOptions?: BaseMutationOptions) =>
      onError?.("create", error, clientOptions),
    [onError]
  )
  const updateCache = React.useCallback(
    (store: ApolloCache<NormalizedCacheObject>, resp: FetchResult<CreateThreadMutation>) => {
      const thread = resp.data?.thread.thread
      const entities = resp.data?.thread.entities
      const comment = thread?.comments?.at(-1)

      if (!resp.data || !thread || !entities || !comment) return

      createThreadCache(thread, entities, targetObject, store, allowResolvedThreads)
      incrCounter(counter + 1)
      onCreate?.(thread, comment, entities)
    },
    [counter, onCreate, targetObject, allowResolvedThreads]
  )

  const [create] = useCreateThreadMutation({
    refetchQueries: skipRefetch ? undefined : REFETCH_THREADS,
    awaitRefetchQueries: false,
    update: updateCache,
  })

  return {
    createThread: React.useCallback(
      (comment: NewComment) => {
        const assignee = assigneeDelegate.current?.getAssignee?.()
        return create({
          variables: {
            ...targetObject,
            comment,
            context,
            viewKey,
            assignee,
          },
          optimisticResponse: () => ({
            thread: newThread(targetObject, comment, context),
          }),
          onError: onError ? onCreateError : undefined,
        })
      },
      [assigneeDelegate, create, targetObject, context, viewKey, onError, onCreateError, newThread]
    ),
    counter,
  }
}

export function useReplyThread(assigneeDelegate: React.RefObject<AssigneeDelegate | undefined>) {
  const {
    onUpdate,
    onError,
    activeThreadId,
    activeThreadDetails,
    allowResolvedThreads,
    skipRefetch,
  } = useThreadContext()
  const [counter, incrCounter] = React.useState(0)
  const newComment = useNewComment()
  const newEntities = useNewEntities()
  const viewKey = useViewVersion()
  const onReplyError = React.useCallback(
    (error: ApolloError, clientOptions?: BaseMutationOptions) =>
      onError?.("reply", error, clientOptions),
    [onError]
  )

  const updateCache = React.useCallback(
    (store: ApolloCache<NormalizedCacheObject>, resp: FetchResult<ReplyThreadMutation>) => {
      const comment = resp.data?.comment.comment
      const entities = resp.data?.comment.entities?.users
        ? resp.data.comment.entities
        : newEntities()
      if (!comment || !entities) return

      const newThread = updateThreadCache(
        activeThreadId,
        comment,
        entities,
        store,
        allowResolvedThreads
      )
      if (!newThread) return

      incrCounter(counter + 1)
      if (activeThreadDetails) updateThreadDetailsCache(activeThreadDetails, store)

      onUpdate?.(newThread, comment, entities)
    },
    [newEntities, activeThreadId, counter, activeThreadDetails, onUpdate, allowResolvedThreads]
  )

  const [reply] = useReplyThreadMutation({
    refetchQueries: skipRefetch ? ALWAYS_REFETCH_ON_REPLY : REFETCH_ON_REPLY,
    awaitRefetchQueries: false,
    update: updateCache,
  })

  const replyThread = React.useCallback(
    (comment: NewComment) => {
      if (!activeThreadId) {
        return promiseHelper.makeRejected<ExecutionResult>("threadId is not defined")
      }

      const assignee = assigneeDelegate.current?.getAssignee?.()
      return reply({
        variables: {
          threadId: activeThreadId,
          comment,
          assignee,
          viewKey,
        },
        optimisticResponse: () => ({
          comment: {
            comment: newComment(comment),
            entities: newEntities(),
          },
        }),
        onError: onError ? onReplyError : undefined,
      })
    },
    [
      activeThreadId,
      assigneeDelegate,
      reply,
      viewKey,
      onError,
      onReplyError,
      newComment,
      newEntities,
    ]
  )

  return React.useMemo(() => ({ replyThread, counter }), [counter, replyThread])
}

function createThreadCache(
  thread: ThreadFieldsFragment,
  newEntities: ObjectEntitiesFieldsFragment,
  targetObject: ObjectIdentifier,
  store: ApolloCache<NormalizedCacheObject>,
  allowResolvedThreads: boolean
) {
  const id = `Thread:${thread.id}`
  store.updateFragment(
    {
      id,
      fragmentName: "ThreadFields",
      fragment: ThreadFieldsFragmentDoc,
    },
    () => thread
  )

  const { entities } = updateAssociatedThreadQueryCache(
    thread,
    newEntities,
    targetObject,
    store,
    allowResolvedThreads
  )

  updateThreadQueryCache(thread, entities, targetObject, store, allowResolvedThreads)

  return thread
}

function updateThreadQueryCache(
  thread: ThreadFieldsFragment,
  newEntities: ObjectEntitiesFieldsFragment,
  targetObject: ObjectIdentifier,
  store: ApolloCache<NormalizedCacheObject>,
  allowResolvedThreads: boolean
) {
  // ReadAssociatedThreadsQuery
  const associatedThreadsQuery = {
    query: ReadAssociatedThreadsDocument,
    variables: {
      ...targetObject,
      allowResolved: allowResolvedThreads,
    },
  }
  const associatedThreadsData = store.readQuery<ReadAssociatedThreadsQuery>(associatedThreadsQuery)
  const entities = {
    ...(associatedThreadsData?.response.entities || {}),
    ...(newEntities || {}),
    users: [
      ...(associatedThreadsData?.response.entities?.users || []),
      ...(newEntities.users || []),
    ],
  }

  // ReadThreadQuery
  const readThreadQuery = {
    query: ReadThreadDocument,
    variables: { id: thread.id },
  }

  store.writeQuery<ReadThreadQuery>({
    ...readThreadQuery,
    data: {
      thread: { __typename: "ThreadWithEntities", thread, entities },
    },
    broadcast: true,
  })

  // Check caching working properly
  const cachedThread = store.readQuery<ReadThreadQuery>({
    ...readThreadQuery,
  })
  if (!cachedThread?.thread) {
    if (!envHelper.isProduction()) {
      // If this error is thrown it means one of the graphl fragments
      // was modified and we can no longer cache the thread object.
      // This must be corrected.
      throw new Error("New Thread was not cached")
    }
    TrackJS.track(new Error("New Thread was not cached"))
  }

  return { thread, entities }
}

function updateAssociatedThreadQueryCache(
  thread: ThreadFieldsFragment,
  entities: ObjectEntitiesFieldsFragment,
  targetObject: ObjectIdentifier,
  store: ApolloCache<NormalizedCacheObject>,
  allowResolvedThreads: boolean
) {
  // ReadAssociatedThreadsQuery
  const associatedThreadsQuery = {
    query: ReadAssociatedThreadsDocument,
    variables: {
      ...targetObject,
      allowResolved: allowResolvedThreads,
    },
  }
  const associatedThreadsData = store.readQuery<ReadAssociatedThreadsQuery>(associatedThreadsQuery)

  const oldThreads = associatedThreadsData?.response.threads || []
  // newer threads appear at the end of the array
  // so ensure we pass the new thread *after* the cached threads
  const threads: ThreadFieldsFragment[] = [...oldThreads, thread]

  store.writeQuery<ReadAssociatedThreadsQuery>({
    ...associatedThreadsQuery,
    data: { response: { threads, entities } },
    broadcast: true,
  })

  return { thread, entities }
}

function updateThreadCache(
  threadId: string | undefined,
  comment: CommentFieldsFragment,
  newEntities: ObjectEntitiesFieldsFragment,
  store: ApolloCache<NormalizedCacheObject>,
  allowResolvedThreads: boolean
) {
  const id = `Thread:${threadId}`
  const thread = store.readFragment<ThreadFieldsFragment>({
    id,
    fragmentName: "ThreadFields",
    fragment: ThreadFieldsFragmentDoc,
  })

  if (!thread) return

  const newThread = objectHelper.cloneDeep(thread)
  newThread.details.commentCount += 1
  newThread.comments.push(comment)

  store.writeFragment({
    id,
    fragmentName: "ThreadFields",
    fragment: ThreadFieldsFragmentDoc,
    data: newThread,
    broadcast: true,
  })

  updateThreadQueryCache(
    newThread,
    newEntities,
    newThread.details.targetObject,
    store,
    allowResolvedThreads
  )

  // Check caching working properly
  const cachedThread = store.readFragment<Thread>({
    id,
    fragmentName: "ThreadFields",
    fragment: ThreadFieldsFragmentDoc,
  })
  if (!cachedThread || thread.details.commentCount + 1 !== cachedThread.details.commentCount) {
    if (!envHelper.isProduction()) {
      // If this error is thrown it means one of the graphl fragments
      // was modified and we can no longer cache the thread object.
      // This must be corrected.
      throw new Error(
        `Updated Thread was not cached, pre-count: ${thread.details.commentCount}, post: ${cachedThread?.details.commentCount}`
      )
    }
    TrackJS.track(new Error("Updated Thread was not cached"))
  }

  return newThread
}

function updateThreadDetailsCache(
  threadDetails: ThreadDetails,
  store: ApolloCache<NormalizedCacheObject>
) {
  const threadDetailsStore = store.readFragment<ThreadDetails>({
    id: store.identify(threadDetails),
    fragmentName: "ThreadDetailsFields",
    fragment: ThreadDetailsFieldsFragmentDoc,
  })

  if (!threadDetailsStore) return

  const newThreadDetails = objectHelper.cloneDeep(threadDetailsStore)
  newThreadDetails.commentCount += 1

  store.writeFragment({
    id: store.identify(threadDetails),
    fragmentName: "ThreadDetailsFields",
    fragment: ThreadDetailsFieldsFragmentDoc,
    data: threadDetailsStore,
  })

  return newThreadDetails
}

function useNewThreadWithEntities() {
  const newThread = useNewThread()
  const newEntities = useNewEntities()

  return React.useCallback(
    (
      targetObject: ObjectIdentifier,
      comment: NewComment,
      context: string | undefined
    ): ThreadWithEntitiesFieldsFragment => ({
      __typename: "ThreadWithEntities",
      thread: newThread(targetObject, comment, context),
      entities: newEntities(),
    }),
    [newEntities, newThread]
  )
}

function useNewEntities() {
  const {
    user: { id, familyName, givenName, emailAddress, avatarUrl },
  } = useSession()

  const author = React.useMemo<EntityUser>(
    () => ({
      id,
      familyName,
      givenName,
      emailAddress,
      avatarUrl: avatarUrl || null,
    }),
    [avatarUrl, emailAddress, familyName, givenName, id]
  )

  return React.useCallback(
    () =>
      objectEntitiesHelper.merge({
        users: [
          {
            __typename: "EntityUser",
            ...author,
          } as EntityUser, // casting to avoid adding typename to entity (breaks tests fixtures)
        ],
      }),
    [author]
  )
}

function useNewThread() {
  const { user } = useSession()
  const newComment = useNewComment()

  return React.useCallback(
    (
      targetObject: ObjectIdentifier,
      comment: NewComment,
      context: string | undefined
    ): ThreadFieldsFragment => {
      const threadId = `${PENDING_ID_PREFIX}${generateUUID()}`

      const details: ThreadDetails = {
        id: threadId,
        commentCount: 1,
        resolvedAt: null,
        targetObject,
        authorId: user.id,
        commenterIds: [],
        tags: [],
        context: context || null,
      }

      return {
        __typename: "Thread",
        id: threadId,
        details: {
          __typename: "ThreadDetails",
          ...details,
        } as ThreadDetails,
        comments: [newComment(comment)],
      }
    },
    [newComment, user.id]
  )
}

function useNewComment() {
  const { user } = useSession()

  return React.useCallback(
    ({ text }: NewComment): CommentFieldsFragment => ({
      __typename: "Comment",
      id: `${PENDING_ID_PREFIX}${generateUUID()}`,
      authorId: user.id,
      text,
      timestamp: dayjs().unix(),
      editedAt: null,
      deletedAt: null,
      layoutAttachment: null,
      reactions: null,
    }),
    [user.id]
  )
}
