import * as React from "react"
import { type ActionItemKind } from "@digits-graphql/frontend/graphql-bearer"
import { LoadingCircle } from "@digits-shared/components/Loaders"
import { LazyComponentLoad } from "@digits-shared/components/UI/Elements/LazyComponentLoad"
import colors from "@digits-shared/themes/colors"
import { BodyText, DetailText } from "@digits-shared/themes/typography"
import styled, { css } from "styled-components"
import { IconByPrecedence } from "src/frontend/components/Shared/NavSidebar/ActionItems/Row/Icon"
import EntitiesParser from "src/shared/components/ObjectEntities/EntitiesParser"
import { type PartyAttributesForIcon } from "src/shared/components/PartyHover/PartyIcon"

/*
  STYLES
*/

const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  overflow: hidden;
  width: 100%;
  margin-left: 10px;
`

const TitleText = styled(BodyText)<{ lineCount: number }>`
  color: ${colors.secondary80};
  max-width: 302px;

  display: -webkit-box;
  -webkit-box-orient: vertical;

  ${({ lineCount }) =>
    lineCount &&
    css`
      -webkit-line-clamp: ${lineCount};
      overflow: hidden;
    `};
`

const SecondLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 8px;
  width: 100%;
  justify-content: space-between;

  ${DetailText} {
    max-width: 140px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`

/*
  COMPONENTS
*/

export const ActionItemRow: React.FC<
  React.PropsWithChildren<{
    title: string
    iconUrl?: string | null
    iconColor?: string
    actionItemKind: ActionItemKind
    detailText?: string
    party?: PartyAttributesForIcon | null
    categoryName?: string | null
  }>
> = ({ actionItemKind, iconUrl, iconColor, party, categoryName, title, detailText, children }) => {
  const textRef = React.useRef<HTMLDivElement>(null)
  const [lineCount, setLineCount] = React.useState(0)
  React.useEffect(() => {
    const element = textRef.current
    if (element) {
      const height = element.offsetHeight
      const lineHeight = parseInt(window.getComputedStyle(element).lineHeight)
      setLineCount(Math.round(height / lineHeight))
    }
  }, [detailText])

  return (
    <>
      <LazyComponentLoad
        placeholder={<LoadingCircle width={32} height={32} animationPlayState="paused" />}
      >
        <IconByPrecedence
          party={party}
          iconUrl={iconUrl}
          actionItemKind={actionItemKind}
          iconColor={iconColor}
          categoryName={categoryName}
        />
      </LazyComponentLoad>
      <TextContent>
        <SecondLine>
          {children ?? <DetailText color={colors.secondary70}>{detailText}</DetailText>}
        </SecondLine>
        <TitleText ref={textRef} weight="normal" lineCount={lineCount}>
          <EntitiesParser text={title} decode />
        </TitleText>
      </TextContent>
    </>
  )
}
