import styled from "styled-components"
import { StyledLoadingBlock } from "src/frontend/components/OS/Shared/PayUI/Loader/StyledLoadingBlock"

const LoadingComment = styled.div<{ reverse?: boolean }>`
  display: flex;
  flex-direction: ${({ reverse }) => (reverse ? "column-reverse" : "column")};
  align-items: ${({ reverse }) => (reverse ? "flex-end" : "flex-start")};
  gap: 4px;
  margin: 0 15px;
`

const LoadingAuthor = styled.div<{ reverse?: boolean }>`
  display: flex;
  flex-direction: ${({ reverse }) => (reverse ? "row-reverse" : "row")};
  gap: 4px;
`

export const CommentsLoading: React.FC<{ numLoadingRows: number }> = ({ numLoadingRows }) =>
  Array.from({ length: numLoadingRows }).map((_, i) => (
    <CommentLoading key={i} reverse={Boolean(i % 2)} />
  ))

export const CommentLoading: React.FC<{ reverse?: boolean }> = ({ reverse }) => (
  <LoadingComment reverse={reverse}>
    <LoadingAuthor reverse={reverse}>
      <StyledLoadingBlock width="32px" height="32px" borderRadius="16px" dark />
      <StyledLoadingBlock width="200px" height="32px" borderRadius="8px" dark />
    </LoadingAuthor>
    <StyledLoadingBlock width="300px" height="35px" borderRadius="8px" dark />
  </LoadingComment>
)
