import * as React from "react"
import { InvertValuesContext } from "@digits-shared/components/Contexts/InvertValuesContext"
import { DeltaChevron, DeltaContainer } from "@digits-shared/components/UI/Elements/Delta"
import { Amount } from "@digits-shared/components/UI/Table/Content"
import { DigitsTooltip } from "@digits-shared/DesignSystem/Tooltip"
import stringHelper from "@digits-shared/helpers/stringHelper"
import colors from "@digits-shared/themes/colors"
import styled from "styled-components"
import { useConfigOriginOverride } from "src/frontend/components/Shared/Layout/hooks/useConfigOriginOverride"
import {
  Title,
  type TopEntitiesProps,
} from "src/frontend/components/Shared/Portals/Components/Lists/Shared"
import { useTopPartiesLiveData } from "src/frontend/components/Shared/Portals/hooks/useTopPartiesLiveData"
import {
  Categories,
  TopPartiesList,
} from "src/frontend/components/Shared/Reports/Report/Viewer/Layout/Shared/TopPartiesList"
import { useTextIsOverflowing } from "src/frontend/hooks/useTextIsOverflowing"
import { type PartyTransactionSummary } from "src/frontend/types"
import { FrontendPartyRole } from "src/frontend/types/FrontendPartyRole"

const StyledTopPartiesList = styled(TopPartiesList)`
  ${Categories}, ${Amount}, ${DeltaContainer} {
    color: ${colors.secondary50};

    ${DeltaChevron} {
      border-bottom-color: ${colors.secondary50};
      border-top-color: ${colors.secondary50};
    }
  }
`

/*
  COMPONENTS
*/

export const TopParties: React.FC<TopEntitiesProps> = ({ component, componentSize }) => {
  const config = useConfigOriginOverride(component.config.topEntities, 1)
  const { textElementRef, isTextOverflowing } = useTextIsOverflowing<HTMLDivElement>()

  const role = React.useMemo(
    () => (config.partyRole ? FrontendPartyRole.findByRole(config.partyRole) : undefined),
    [config.partyRole]
  )

  const invertValues = role?.invertValues ?? false

  const countDisplay = role
    ? stringHelper.pluralize(config.limit, role.displayName, role.pluralDisplayName)
    : ""

  const { data, loading } = useTopPartiesLiveData(config, component.config.type)

  const partySummaries: PartyTransactionSummary[] | undefined = React.useMemo(
    () =>
      data?.entities.party.map((p) => ({
        party: p.partyObject,
        summary: p.summary,
        categoryNames: p.by.category.map((c) => c.categoryObject.name),
      })),
    [data]
  )

  if (!countDisplay) return null

  return (
    <InvertValuesContext.Provider value={invertValues}>
      <DigitsTooltip
        css="width: unset"
        content={<div>Top {countDisplay}</div>}
        disabled={!isTextOverflowing}
      >
        <Title ref={textElementRef} componentSize={componentSize}>
          Top {countDisplay}
        </Title>
      </DigitsTooltip>
      <StyledTopPartiesList padding={10} partySummaries={partySummaries} loading={loading} />
    </InvertValuesContext.Provider>
  )
}
