import * as React from "react"
import { SvgSearchSm } from "@digits-shared/components/SVGIcons/line/SearchSm.svg"
import { Keys } from "@digits-shared/components/UI/Elements/Keys"
import caretHelper, { type CaretPosition } from "@digits-shared/helpers/caretHelper"
import useLazyTimeout from "@digits-shared/hooks/useLazyTimeout"
import useStateBoolean from "@digits-shared/hooks/useStateBoolean"
import { themedStyles } from "@digits-shared/themes"
import borders from "@digits-shared/themes/borders"
import colors from "@digits-shared/themes/colors"
import { themedSVGPathStyles } from "@digits-shared/themes/svgIconStyles"
import fonts from "@digits-shared/themes/typography"
import styled, { css } from "styled-components"

const INPUT_UPDATE_INTERVAL = 300

/*
  STYLES
*/

const SEARCH_BOX_HORIZONTAL_PADDING = 8
const BOX_SHADOW_BLUR_RADIUS = 10

const searchBoxStyles = themedStyles({
  light: css`
    color: ${colors.secondary};

    &:focus-within,
    &:hover {
      border-color: ${colors.translucentSecondary40};
    }
  `,
  dark: css`
    color: ${colors.white};

    &:hover {
      border: 1px solid ${colors.translucentNeonGreen30};
    }

    &:focus-within {
      border: 1px solid ${colors.neonGreen};
      box-shadow: 0 0 ${BOX_SHADOW_BLUR_RADIUS}px ${colors.translucentNeonGreen15};
    }
  `,
})

export const SearchBoxStyled = styled.div`
  ${searchBoxStyles};
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 ${SEARCH_BOX_HORIZONTAL_PADDING}px;
  border-radius: ${borders.radius.large}px;
  cursor: pointer;
  align-content: center;
  transition:
    border 250ms ease-out,
    box-shadow 250ms ease-out;
  border: 1px solid ${colors.theme.dark.border};
  background-color: transparent;
  font-weight: ${fonts.weight.medium};
  height: 28px;
`

const searchInputStyles = themedStyles({
  light: css`
    color: ${colors.secondary};

    &::placeholder {
      color: ${colors.translucentSecondary40};
    }
  `,
  dark: css`
    color: ${colors.white};

    &::placeholder {
      color: ${colors.translucentWhite30};
    }
  `,
})
export const SearchInput = styled.input`
  ${searchInputStyles};
  appearance: none;
  border: none;
  background: ${colors.transparent};
  width: 100%;
  padding-left: 5px;

  &::placeholder {
    text-transform: uppercase;
    font-size: 11px;
  }
`

const clearBoxStyles = themedStyles({
  light: css`
    color: ${colors.translucentSecondary50};

    &:hover {
      color: ${colors.secondary};
    }
  `,
  dark: css`
    color: ${colors.translucentWhite50};

    &:hover {
      color: ${colors.white};
    }
  `,
})
export const ClearBox = styled.div<{ isHidden: boolean }>`
  ${clearBoxStyles};
  padding-right: 2px;
  visibility: ${(props) => (props.isHidden ? "hidden" : "visible")};
  font-size: 15px;
  font-weight: ${fonts.weight.light};

  &::after {
    content: "×";
  }
`

const SEARCH_ICON_SIZE = 14
const COLLAPSED_SEARCH_ICON_SIZE = 16

export const SearchIcon = styled(SvgSearchSm)`
  width: ${SEARCH_ICON_SIZE}px;
  height: ${SEARCH_ICON_SIZE}px;
  padding-top: 1px;
  transition: fill 250ms ease-out;
  flex-shrink: 0;

  ${themedSVGPathStyles(
    {
      light: colors.translucentSecondary40,
      dark: colors.translucentWhite20,
    },
    1.5
  )};
`

const CollapsableSearchBoxContainer = styled(SearchBoxStyled)<{ isCollapsed: boolean }>`
  clip-path: ${({ isCollapsed }) =>
    isCollapsed
      ? `inset(3px 8px 3px calc(100% - ${
          COLLAPSED_SEARCH_ICON_SIZE + SEARCH_BOX_HORIZONTAL_PADDING
        }px))`
      : `inset(-${BOX_SHADOW_BLUR_RADIUS}px -${BOX_SHADOW_BLUR_RADIUS}px -${BOX_SHADOW_BLUR_RADIUS}px -${BOX_SHADOW_BLUR_RADIUS}px)`};

  transition:
    border 250ms ease-out,
    box-shadow 250ms ease-out,
    clip-path 250ms ease-out;

  ${SearchInput} {
    padding-left: 25px;
  }

  ${SearchIcon} {
    position: absolute;
    right: ${({ isCollapsed }) =>
      isCollapsed ? `${SEARCH_BOX_HORIZONTAL_PADDING}px` : "calc(100% - 26px)"};
    width: ${COLLAPSED_SEARCH_ICON_SIZE}px;
    height: ${COLLAPSED_SEARCH_ICON_SIZE}px;
    transition: right 250ms ease-out;
  }

  ${ClearBox} {
    visibility: visible;
    pointer-events: ${({ isCollapsed }) => (isCollapsed ? "none" : "unset")};
    opacity: ${({ isCollapsed }) => (isCollapsed ? 0 : 1)};
    transition: opacity 250ms ease-out;
  }
`

/*
  INTERFACES
*/

interface SearchBoxProps {
  disabled?: boolean
  onChange?: (input: string) => void
  onSubmit?: (input: string) => void
  onClear?: (input: string) => void
  onFocusChange?: (focus: boolean) => void
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
  initialValue?: string
  autoFocus?: boolean
  className?: string
  children?: React.ReactNode
  placeholder?: string
  shouldDebounceOnChange?: boolean
  noTrim?: boolean
  readOnly?: boolean
  noIcon?: boolean
  noClear?: boolean
  useCollapsableBox?: boolean
  stopClickProp?: boolean
}

type SearchBoxImplementationProps = Omit<
  SearchBoxProps,
  | "shouldDebounceOnChange"
  | "useCollapsableBox"
  | "onSubmit"
  | "onChange"
  | "onClear"
  | "stopClickProp"
> & {
  boxRef: React.MutableRefObject<HTMLInputElement | null>
  onKeyUp: (event: React.KeyboardEvent<HTMLInputElement>) => void
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onClearBox: () => void
}

/*
  COMPONENT
*/

const SearchBox: React.FC<SearchBoxProps> = ({
  children,
  className,
  disabled,
  initialValue,
  placeholder,
  readOnly,
  noClear,
  noIcon,
  shouldDebounceOnChange,
  noTrim,
  autoFocus,
  onSubmit,
  onChange,
  onClear,
  onFocusChange,
  useCollapsableBox,
  stopClickProp,
}) => {
  const { ref, onKeyUp, onInputChange, onClearBox, onClick } = useSearchBox({
    initialValue,
    shouldDebounceOnChange,
    noTrim,
    onSubmit,
    onChange,
    onClear,
    stopClickProp,
  })

  const BoxComponent = useCollapsableBox ? CollapsableSearchBox : StandardSearchBox

  return (
    <BoxComponent
      boxRef={ref}
      className={className}
      onKeyUp={onKeyUp}
      onInputChange={onInputChange}
      initialValue={initialValue}
      disabled={disabled}
      placeholder={placeholder}
      readOnly={readOnly}
      autoFocus={autoFocus}
      onFocusChange={onFocusChange}
      onClearBox={onClearBox}
      noIcon={noIcon}
      noClear={noClear}
      onClick={onClick}
    >
      {children}
    </BoxComponent>
  )
}

export default SearchBox

const StandardSearchBox: React.FC<SearchBoxImplementationProps> = ({
  boxRef,
  noIcon,
  noClear,
  onKeyUp,
  onInputChange,
  onClearBox,
  initialValue,
  disabled,
  placeholder,
  readOnly,
  autoFocus,
  onFocusChange,
  className,
  children,
  onClick,
}) => (
  <SearchBoxStyled className={className}>
    {!noIcon && <SearchIcon />}
    <SearchInput
      ref={boxRef}
      type="text"
      spellCheck={false}
      onKeyUp={onKeyUp}
      onChange={onInputChange}
      defaultValue={initialValue}
      disabled={disabled}
      placeholder={placeholder}
      readOnly={readOnly}
      autoFocus={autoFocus}
      onFocus={onFocusChange?.bind(null, true)}
      onBlur={onFocusChange?.bind(null, false)}
      onClick={onClick}
    />
    {!noClear && (
      <ClearBox onClick={onClearBox} isHidden={!initialValue && !boxRef.current?.value} />
    )}
    {children}
  </SearchBoxStyled>
)

const CollapsableSearchBox: React.FC<SearchBoxImplementationProps> = ({
  boxRef,
  onKeyUp,
  onInputChange,
  noIcon,
  initialValue,
  disabled,
  placeholder,
  readOnly,
  autoFocus,
  onFocusChange,
  onClearBox,
  noClear,
  className,
  children,
}) => {
  const [searchTerm, setSearchTerm] = React.useState(initialValue)
  const { value: isCollapsed, setTrue: collapse, setFalse: expand } = useStateBoolean(!initialValue)

  // Handle bug where clicking on search icon cause term
  // to collapse briefly when no search term present
  const {
    value: preventNextCollapse,
    setTrue: setPreventNextCollapse,
    setFalse: setNoPreventNextCollapse,
  } = useStateBoolean(false)

  const onFocus = React.useCallback(() => {
    expand()
    onFocusChange?.(true)
    boxRef.current?.focus()
  }, [onFocusChange, expand, boxRef])

  const onBlur = React.useCallback(() => {
    if (!searchTerm && !preventNextCollapse) collapse()
    onFocusChange?.(false)
  }, [searchTerm, preventNextCollapse, onFocusChange, collapse])

  const onSearchInputChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value)
      onInputChange(event)
    },
    [onInputChange]
  )

  const onClearClick = React.useCallback(() => {
    collapse()
    onClearBox?.()
  }, [collapse, onClearBox])

  return (
    <CollapsableSearchBoxContainer className={className} isCollapsed={isCollapsed}>
      <SearchInput
        ref={boxRef}
        type="text"
        spellCheck={false}
        onKeyUp={onKeyUp}
        onChange={onSearchInputChange}
        defaultValue={initialValue}
        disabled={disabled}
        placeholder={placeholder}
        readOnly={readOnly}
        autoFocus={autoFocus}
        onFocus={onFocus}
        onBlur={onBlur}
      >
        {children}
      </SearchInput>
      {!noIcon && (
        <SearchIcon
          onClick={onFocus}
          onMouseEnter={setPreventNextCollapse}
          onMouseLeave={setNoPreventNextCollapse}
        />
      )}
      {!noClear && <ClearBox onClick={onClearClick} isHidden={false} />}
    </CollapsableSearchBoxContainer>
  )
}

function useSearchBox({
  initialValue,
  shouldDebounceOnChange,
  noTrim,
  onSubmit,
  onChange,
  onClear,
  stopClickProp,
}: Pick<
  SearchBoxProps,
  | "initialValue"
  | "shouldDebounceOnChange"
  | "noTrim"
  | "onSubmit"
  | "onChange"
  | "onClear"
  | "stopClickProp"
>) {
  const ref = React.useRef<HTMLInputElement | null>(null)
  const previousPosition = React.useRef<CaretPosition>(undefined)
  if (ref.current) previousPosition.current = caretHelper.getCaretPosition(ref.current)

  const forwardOnChange = React.useCallback(() => {
    const input = ref.current
    if (!input) return
    const { value } = input
    onChange?.(noTrim ? value : value.trim())
  }, [onChange, noTrim])
  const [debounce, timerRef] = useLazyTimeout(forwardOnChange, INPUT_UPDATE_INTERVAL)

  React.useEffect(() => {
    const input = ref.current
    if (!input) return

    // if the `initialValue` has changed (via prop) and the input's value does not match the previous default value
    // it means that we have received a new prop (initialValue) that the input has to now take as its new value.
    // e.g. Click on a suggested term in Search, the parent container reads the new term off the URL and passes it
    // to the search box to reflect the newly selected search term.
    const isFieldDirty = input.value !== input.defaultValue
    // In this case `defaultValue` will always be the same as `initialValue`
    if (initialValue && isFieldDirty) {
      input.value = initialValue
    }
  }, [initialValue])

  React.useEffect(() => {
    const input = ref.current
    const position = previousPosition.current

    if (!input || !position || !initialValue) {
      previousPosition.current = undefined
      return
    }
    caretHelper.moveCaret(input, position)
  }, [initialValue])

  const onClearBox = React.useCallback(() => {
    const input = ref.current
    if (!input) return
    input.value = ""

    if (onClear) {
      onClear(input.value)
    } else {
      onChange?.(input.value)
    }
  }, [onClear, onChange])

  const onKeyUp = React.useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const input = event.currentTarget.value

      switch (event.key) {
        case Keys.Enter:
          if (timerRef.current) clearTimeout(timerRef.current)
          onSubmit?.(input)
          break
        case Keys.Escape:
          onClearBox()
          break
      }
    },
    [timerRef, onClearBox, onSubmit]
  )

  const onInputChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const input = event.currentTarget.value
      if (shouldDebounceOnChange) {
        debounce()
      } else {
        onChange?.(input)
      }
    },
    [debounce, shouldDebounceOnChange, onChange]
  )

  const onClick = React.useMemo(
    () =>
      stopClickProp
        ? (e: React.MouseEvent<HTMLElement>) => {
            e.stopPropagation()
          }
        : undefined,
    [stopClickProp]
  )

  return React.useMemo(
    () => ({
      ref,
      onKeyUp,
      onInputChange,
      onClearBox,
      onClick,
    }),
    [ref, onKeyUp, onInputChange, onClearBox, onClick]
  )
}
