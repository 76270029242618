import * as React from "react"

/**
 * INTERFACES
 */

export type GroupView = "Assigned" | "Watched" | "Open" | "Resolved"

interface ActionItemsContextProps {
  groupView: GroupView
}

/**
 * CONTEXT
 */
export const ActionItemsContext = React.createContext<ActionItemsContextProps>({
  groupView: "Assigned",
})
