import React from "react"
import { svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgMessageChatSquare } from "@digits-shared/components/SVGIcons/line/MessageChatSquare.svg"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled from "styled-components"

const EmptyThreadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  margin: auto 0;
  min-height: 165px;
  flex-grow: 1;

  color: ${colors.secondary};
  font-size: 18px;
  font-weight: ${fonts.weight.normal};
  white-space: nowrap;

  svg {
    ${svgPathStyles(colors.secondary50)};
    width: 48px;
    height: 48px;
    margin-bottom: 12px;
  }
`

const Secured = styled.div`
  color: ${colors.secondary60};
  font-size: 12px;
`

export const EmptyThread: React.FC = () => (
  <EmptyThreadContainer>
    <SvgMessageChatSquare />
    <div>
      Collaborate with <b>Comments</b>
    </div>
    <Secured>Secured by Digits</Secured>
  </EmptyThreadContainer>
)
