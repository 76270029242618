import * as React from "react"
import { Route, Switch } from "react-router-dom"
import { type ApolloError } from "@apollo/client"
import {
  type CustomerEvent,
  type CustomerEventPayload,
  type LogCustomerEventsMutationFn,
  useLogCustomerEventsMutation,
} from "@digits-graphql/frontend/graphql-bearer"
import errorHelper from "@digits-shared/helpers/errorHelper"
import urlHelper from "@digits-shared/helpers/urlHelper"
import useRouter from "@digits-shared/hooks/useRouter"
import useSession from "@digits-shared/hooks/useSession"
import { TrackJS } from "trackjs"
import { v4 as generateUUID } from "uuid"
import dayjs from "@digits-shared/initializers/dayjs/dayjs"
import { EMAIL_VALIDATION_TOKEN_PARAM } from "src/frontend/components/Public/hooks/useEmailValidationToken"
import { PASSWORD_RESET_TOKEN_PARAM } from "src/frontend/components/Public/hooks/usePasswordResetToken"
import routes from "src/frontend/routes"
import type FrontendSession from "src/frontend/session"
import useVisibilityChange from "src/shared/hooks/useVisibilityChange"

const LOGS_STORAGE_KEY = "event.logs"
const SENSITIVE_QUERY_PARAMS = ["ml", EMAIL_VALIDATION_TOKEN_PARAM, PASSWORD_RESET_TOKEN_PARAM]

/**
 * INTERFACES
 */

type CustomerEventOneOf = OneOf<Required<CustomerEventPayload>>

export type LogEvent = (payload: CustomerEventOneOf) => void

interface EventsLoggerContextProps {
  log: LogEvent
  flush: () => void // flush all the events currently in cache
}

interface LogsStorage {
  [key: string]: CustomerEvent
}

/**
 * CONTEXT
 */
const EventsLoggerContext = React.createContext<EventsLoggerContextProps>({
  log: () => {},
  flush: () => {},
})

export function useEventsLogger() {
  return React.useContext(EventsLoggerContext)
}

/**
 * COMPONENTS
 */
export const EventsLoggerProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const session = useSession<FrontendSession>()
  const [logCustomerEvents] = useLogCustomerEventsMutation({ context: { noBatch: true } })

  const logger = React.useMemo(
    () => new EventLogger(session, logCustomerEvents),
    [session, logCustomerEvents]
  )

  // If user switches tabs, flush them immediately
  useVisibilityChange(logger.flush)

  return (
    <EventsLoggerContext.Provider value={logger}>
      <RouteLogger />
      {children}
    </EventsLoggerContext.Provider>
  )
}

// Using a Switch with a Route that matches the @:leSlug in routes.legalEntity,
// so that `URLLogger` can call `useRouter` and received the params matches
const RouteLogger: React.FC = () => (
  <Switch>
    <Route path={routes.legalEntity.parameterizedPath} component={URLLogger} />
    <Route path="*" component={URLLogger} />
  </Switch>
)

const URLLogger: React.FC = () => {
  const { location, params } = useRouter()
  const logger = useEventsLogger() as EventLogger
  const currentUrl = React.useRef<string>(undefined)

  // Log every change in History.Location
  const { fullPathname, queryParams, state } = location
  const source = state?.source

  React.useEffect(() => {
    // Set the current route params to the logger to check for slugs and other params
    logger.routeParams = params
    logger.queryParams = queryParams

    try {
      const url = urlHelper.fullDashboardURL(fullPathname)

      if (source) {
        // We are adding the "source" to the logged url if it exists in the location history to track certain navigation events,
        // e.g. to differentiate when clicking on a suggested search term.
        url.searchParams.append("source", source)
      }

      // Redact any security-sensitive params.
      redactSensitiveParams(url)

      // do not log URLs twice (strict mode)
      const urlString = url.toString()
      if (currentUrl.current === urlString) return

      currentUrl.current = urlString
      logger.log({ pageView: { url: urlString } }).finally(() => {
        currentUrl.current = undefined
      })
    } catch (e) {
      TrackJS.console.error(e)
    }
  }, [logger, params, fullPathname, queryParams, source])

  return null
}

function redactSensitiveParams(url: URL) {
  SENSITIVE_QUERY_PARAMS.forEach((sensitiveParam) => {
    if (url.searchParams.has(sensitiveParam)) {
      url.searchParams.set(sensitiveParam, "redacted")
    }
  })
}

class EventLogger implements EventsLoggerContextProps {
  private static TIMER_DURATION = 3 * 1000 // 3sec
  private static MAX_EVENTS_PER_BATCH = 10 // 10 events every 3 secs
  private static LOGGING_ENABLED = true
  routeParams: Record<string, string>
  queryParams: Record<string, string>
  private readonly session: FrontendSession
  private readonly logCustomerEvents: LogCustomerEventsMutationFn
  private inProgress = new Set<string>()
  private timer?: NodeJS.Timeout

  // if there is no user, keep events in memory until there is a user in the session,
  // when we will flush those events (we don't send anything until there is a user)
  private readonly loggedOutEvents: Map<string, CustomerEvent>

  constructor(session: FrontendSession, logCustomerEvents: LogCustomerEventsMutationFn) {
    this.session = session
    this.routeParams = {}
    this.queryParams = {}
    this.loggedOutEvents = new Map()
    this.logCustomerEvents = logCustomerEvents
  }

  log = (payload: CustomerEventOneOf): Promise<void> => {
    // If we received a kill switch from the API, we will stop capturing any kind of logs until
    // the next session
    if (!EventLogger.LOGGING_ENABLED) return Promise.resolve()

    // Only read the legal entity id if there is a slug in the url
    const { leSlug } = this.routeParams
    const { entity } = this.queryParams
    const legalEntityId = leSlug || entity ? this.session.currentLegalEntityId : undefined

    // Prevent logging events for users who visit a URL they don't have access to
    if (leSlug && !this.session.findLegalEntityBySlug(leSlug)) return Promise.resolve()
    if (entity && !this.session.findLegalEntityBySlug(entity)) return Promise.resolve()

    const event: CustomerEvent = {
      eventId: generateUUID(),
      legalEntityId,
      occurredAt: dayjs().utc().unix(),
      payload,
    }
    this.writeNewEvent(event)
    this.setTimer()

    // skip one cycle to avoid strict mode from logging twice
    return new Promise<void>((resolve) => {
      setTimeout(resolve, 1)
    })
  }

  flush = () => {
    this.logEvents()
  }

  private logEvents = () => {
    this.clearTimer()

    const events = Object.values(this.readEvents())
      .filter((e) => !this.inProgress.has(e.eventId))
      .slice(0, EventLogger.MAX_EVENTS_PER_BATCH)
      .sort((e1, e2) => e1.occurredAt - e2.occurredAt)

    // no events to log when timer fired off
    // if there is no user, wait until there is one to log the events
    if (!events?.length || !this.session.user?.id) return

    const eventIds = events.map((e) => e.eventId)
    eventIds.forEach((id) => this.inProgress.add(id))

    this.logCustomerEvents({
      variables: {
        events,
      },
    })
      .then(() => {
        this.clearEvents(eventIds)
      })
      .catch((error: ApolloError) => {
        // Logging is unavailable, kill logging
        if (errorHelper.isUnavailable(error)) {
          EventLogger.LOGGING_ENABLED = false
          this.clearAllEvents()
        }
      })
      .finally(() => {
        eventIds.forEach((id) => this.inProgress.delete(id))
      })
  }

  private setTimer() {
    if (!this.timer) {
      this.timer = setTimeout(this.logEvents, EventLogger.TIMER_DURATION)
    }
  }

  private clearTimer() {
    if (this.timer) {
      clearTimeout(this.timer)
    }
    this.timer = undefined
  }

  private readEvents() {
    return {
      ...this.readLoggedOutEvents(),
      ...this.session.getUserPreference(LOGS_STORAGE_KEY),
    } as LogsStorage
  }

  private writeNewEvent(event: CustomerEvent) {
    // If doppelganger, do not write the logs to localstorage
    // (this will not disabled logging when DG is done)
    if (this.session.doppelganger) return

    if (!this.session.user?.id) {
      return this.writeNewLoggedOutEvent(event)
    }

    const events = this.readEvents()
    events[event.eventId] = event
    this.session.setUserPreference(LOGS_STORAGE_KEY, events)
  }

  private clearAllEvents() {
    this.loggedOutEvents.clear()
    this.session.setUserPreference(LOGS_STORAGE_KEY, undefined)
  }

  private clearEvents(eventIds: string[]) {
    eventIds.forEach((eventId) => {
      this.loggedOutEvents.delete(eventId)
    })

    const events = this.readEvents()
    eventIds.forEach((eventId) => {
      delete events[eventId]
    })
    this.session.setUserPreference(LOGS_STORAGE_KEY, events)
  }

  // Logged out Events
  private readLoggedOutEvents(): LogsStorage {
    return Object.fromEntries(this.loggedOutEvents)
  }

  private writeNewLoggedOutEvent(event: CustomerEvent) {
    this.loggedOutEvents.set(event.eventId, event)
  }
}
