import * as React from "react"
import { useEvent } from "react-use"
import { type Contact } from "@digits-graphql/frontend/graphql-public"
import { svgIconStyles, svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgPlus } from "@digits-shared/components/SVGIcons/line/Plus.svg"
import { ColorIcon } from "@digits-shared/components/UI/Elements/ColorIcon"
import { PickerPosition } from "@digits-shared/components/UI/Picker/constants"
import { Picker, type PickerMouseInteraction } from "@digits-shared/components/UI/Picker/Picker"
import { defined } from "@digits-shared/helpers/filters"
import userHelper from "@digits-shared/helpers/userHelper"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled from "styled-components"
import { type InviteClientState } from "src/frontend/components/OS/Applications/ClientPortal/InviteFlow/State/types"
import { useSuggestedUsers } from "src/frontend/components/OS/Applications/ClientPortal/InviteFlow/State/useSuggestedUsers"

const MENU_MAX_RESULTS = 10
const MENU_WIDTH = 250

/*
  STYLES
*/

const Menu = styled.div`
  width: ${MENU_WIDTH}px;
  position: absolute;
  top: 116px;
  left: 0;
  margin: 10px 0 0 56px;
  border-radius: 10px;
  color: ${colors.secondary};
  background: ${colors.translucentWhite80};
  border: 1px solid ${colors.white};
  backdrop-filter: blur(35px);
  filter: drop-shadow(0 0 15px rgba(34, 71, 125, 0.14));
  overflow: hidden;
`

const PlusIcon = styled(SvgPlus)`
  width: 20px;
  height: 20px;
  ${svgPathStyles(colors.secondary40, 1.5)};
`

const Item = styled.div<PickerMouseInteraction>`
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  padding: 10px 15px;

  color: ${({ isHovered, isSelected }) =>
    isHovered ? colors.secondary : isSelected ? colors.secondary : colors.secondary70};

  ${PlusIcon} {
    ${({ isHovered, isSelected }) =>
      isHovered
        ? svgIconStyles(colors.primary)
        : isSelected
          ? svgIconStyles(colors.secondary40)
          : svgIconStyles(colors.secondary40)};
  }
`

const Info = styled.div`
  flex: 1;
  overflow: hidden;
  text-align: left;
  margin-left: 10px;
`

const Title = styled.div`
  font-weight: ${fonts.weight.black};
  font-size: 10px;
  font-family: ${fonts.family.avenir};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 14px;
`

const Subtitle = styled.div`
  font-weight: ${fonts.weight.book};
  font-size: 9px;
  font-family: ${fonts.family.avenir};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 11px;
`

/*
  COMPONENTS
*/

type OnAutoCompletedCallback = (contact: Contact) => void

interface AutoCompleteProps {
  state: InviteClientState
  inputRef: React.RefObject<HTMLInputElement | null>
  onAutoCompleted: OnAutoCompletedCallback
}

interface AutoCompleteMenuProps {
  inputElement: HTMLInputElement
  dataSet: ACItem[]
  onAutoCompleted: OnAutoCompletedCallback
}

interface ACItem {
  title: string
  subtitle?: string
  iconUrl?: string | null
  contact: Contact
  searchValues: string[]
}

interface ACItemProps {
  element: ACItem
}

export const AutoComplete: React.FC<AutoCompleteProps> = ({ state, inputRef, onAutoCompleted }) => {
  const { showAutocomplete } = state
  const suggestedUsers = useSuggestedUsers(state)

  if (!suggestedUsers || !inputRef.current || !showAutocomplete) return null

  const dataSet = suggestedUsers.map<ACItem>((u) => ({
    title: userHelper.displayName(u),
    subtitle: u.emailAddress,
    iconUrl: u.avatarUrl,
    contact: u,
    searchValues: [u.givenName, u.familyName, u.emailAddress].filter(defined),
  }))

  return (
    <AutoCompleteMenu
      inputElement={inputRef.current}
      dataSet={dataSet}
      onAutoCompleted={onAutoCompleted}
    />
  )
}

const AutoCompleteMenu: React.FC<AutoCompleteMenuProps> = ({
  inputElement,
  dataSet,
  onAutoCompleted,
}) => {
  const [filteredResults, setFilteredResults] = React.useState<ACItem[]>([])
  const show = filteredResults.length > 0

  const onItemClick = React.useCallback(
    (data: ACItem) => {
      onAutoCompleted(data.contact)
      setFilteredResults([])
    },
    [onAutoCompleted]
  )

  const onKeyUp = React.useCallback(() => {
    const searchTerm = inputElement.value.toLowerCase()
    if (!searchTerm) return setFilteredResults([])

    const results = dataSet
      .filter((data) => data.searchValues.some((v) => v.toLowerCase().startsWith(searchTerm)))
      .slice(0, MENU_MAX_RESULTS)

    setFilteredResults(results)
  }, [dataSet, inputElement])

  useEvent("keyup", onKeyUp)

  if (!show || inputElement.value.length === 0) {
    return null
  }

  return (
    <Menu>
      <Picker
        elements={filteredResults}
        pickerPosition={PickerPosition.BelowTarget}
        onPickElement={onItemClick}
        RowContent={AutoCompleteItem}
        autoSelect
      />
    </Menu>
  )
}

const AutoCompleteItem: React.FC<ACItemProps & PickerMouseInteraction> = ({
  element,
  isSelected,
  isHovered,
}) => (
  <Item isHovered={isHovered} isSelected={isSelected}>
    <ColorIcon imageUrl={element.iconUrl} fallbackText={element.title} />
    <Info>
      <Title>{element.title}</Title>
      <Subtitle>{element.subtitle}</Subtitle>
    </Info>
    <PlusIcon />
  </Item>
)
