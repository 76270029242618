import * as React from "react"
import { LoadingBlock } from "@digits-shared/components/Loaders"
import colors from "@digits-shared/themes/colors"

export const StyledLoadingBlock: React.FC<{
  width: string | number
  height: string | number
  borderRadius?: string
  dark?: boolean
  className?: string
}> = ({ className, width, height, borderRadius = "2px", dark }) => (
  <LoadingBlock
    className={className}
    width={width}
    height={height}
    backgroundColor={dark ? colors.secondary10 : colors.translucentWhite50}
    $shineColor={dark ? colors.translucentWhite50 : colors.white}
    borderRadius={borderRadius}
    display="block"
  />
)
