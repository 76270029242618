import * as React from "react"
import { svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgDotsVerticalSolid } from "@digits-shared/components/SVGIcons/solid/DotsVerticalSolid.svg"
import {
  type ButtonSize,
  type ButtonVariant,
  DigitsButton,
} from "@digits-shared/DesignSystem/Button"
import { Checkbox } from "@digits-shared/DesignSystem/FormElements/Checkbox"
import stringHelper from "@digits-shared/helpers/stringHelper"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu"
import styled, { css } from "styled-components"

/*
 * STYLES
 */

const ICON_STYLES = css`
  width: 20px;
  min-width: 20px;
  height: 20px;
  ${svgPathStyles(colors.secondary, 1.5)};
`

export const MenuItemSeparator = styled(DropdownMenuPrimitive.Separator)`
  height: 0.5px;
  background-color: ${colors.secondary20};
`

type MenuStyleProps = {
  $active?: boolean
  $disabled?: boolean
}

const menuStyles = css<MenuStyleProps>`
  position: relative;
  border-radius: 0;
  display: flex;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;
  outline: none;

  ${({ $active }) =>
    $active &&
    css`
      &&::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 4px;
        border-radius: 0;
        height: 70%;
        margin: auto;
        background: ${colors.secondary};
      }
    `};

  &:hover,
  &[data-highlighted] {
    background: ${colors.translucentSecondary05};
  }

  ${({ $disabled }) =>
    $disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `};
`

const StyledMenuItem = styled(DropdownMenuPrimitive.Item)<MenuStyleProps>`
  ${menuStyles};
`

const StyledCheckboxMenuItem = styled.div<MenuStyleProps>`
  ${menuStyles};
  gap: 8px;
`

const StyledMenuContent = styled(DropdownMenuPrimitive.Content)<{
  $width: MenuProps["width"]
  $small: MenuProps["small"]
}>`
  background: ${colors.white};
  box-shadow: 0 0 12px 0 ${colors.translucentSecondary20};
  border-radius: 8px;
  z-index: 10;
  max-height: 300px;
  overflow-y: auto;

  ${({ $width }) => {
    if ($width === "matchTrigger") {
      return css`
        width: var(--radix-dropdown-menu-trigger-width);
      `
    }
    if (typeof $width === "number") {
      return css`
        width: ${$width}px;
      `
    }
  }};

  &:has(.active) {
    ${StyledMenuItem} {
      padding-left: ${({ $small }) => ($small ? 12 : 16)}px;
    }
  }

  ${StyledCheckboxMenuItem} {
    ${({ $small }) =>
      $small
        ? css`
            height: 32px;
            padding: 0 24px 0 8px;
          `
        : css`
            height: 40px;
            padding: 0 24px 0 12px;
          `};
  }

  ${StyledMenuItem} {
    ${({ $small, $width }) =>
      $small
        ? css`
            min-width: ${(typeof $width === "number" && $width) || 140}px;
            ${fonts.scale.detail};
            padding: 8px;
            font-weight: ${fonts.weight.medium};
            gap: 4px;

            svg {
              width: 16px;
              height: 16px;
            }
          `
        : css`
            min-width: ${(typeof $width === "number" && $width) || 200}px;
            ${fonts.scale.body};
            padding: 12px;
            font-weight: ${fonts.weight.heavy};
            gap: 8px;

            svg {
              width: 20px;
              height: 20px;
            }
          `};
  }
`

/*
 * INTERFACES
 */

export interface MenuItemProps {
  onSelect: (e?: Event) => void
  label: React.ReactNode
  Icon?: React.ComponentType<React.SVGProps<SVGSVGElement>>
  active?: boolean
  disabled?: boolean
  className?: string
}

export interface CheckboxMenuItemProps {
  checked: boolean
  onCheckedChange: (checked: boolean) => void
  label: string
  role?: React.AriaRole
  disabled?: boolean
  className?: string
}

export interface CustomMenuItemProps extends Omit<MenuItemProps, "label" | "Icon"> {}

interface MenuProps {
  align?: "start" | "center" | "end"
  className?: string
  side?: "top" | "right" | "bottom" | "left"
  sideOffset?: number
  small?: boolean
  width?: number | "matchTrigger"
  onCloseAutoFocus?: (event: Event) => void
  role?: React.AriaRole
}

/**
 * EXAMPLE USAGE WITH CUSTOM TRIGGER:
 * <Menu>
 *   <MenuTrigger asChild>
 *     -- place trigger component here--
 *   </MenuTrigger>
 *   <MenuContent>
 *     <MenuItem label="Menu Item 1" onSelect={onSelect} Icon={SvgCheck} />
 *     <MenuItem label="Menu Item 2" onSelect={onSelect} Icon={SvgCheck} />
 *     <MenuItemSeparator />
 *     <MenuItem label="Menu Item 3" onSelect={onSelect} Icon={SvgCheck} />
 *   </MenuContent>
 * </Menu>
 *
 * EXAMPLE USAGE WITH DEFAULT TRIGGER:
 * <Menu>
 *   <EllipsisMenuTrigger />
 *   <MenuContent>
 *     <MenuItem label="Menu Item 1" onSelect={onSelect} Icon={SvgCheck} />
 *     <MenuItem label="Menu Item 2" onSelect={onSelect} Icon={SvgCheck} />
 *     <MenuItemSeparator />
 *     <MenuItem label="Menu Item 3" onSelect={onSelect} Icon={SvgCheck} />
 *   </MenuContent>
 * </Menu>
 */

export const Menu = DropdownMenuPrimitive.Root
export const MenuTrigger = DropdownMenuPrimitive.Trigger
export const MenuContent = React.forwardRef<HTMLDivElement, React.PropsWithChildren<MenuProps>>(
  (
    {
      align = "end",
      children,
      className,
      side = "bottom",
      sideOffset = 8,
      small = false,
      width,
      onCloseAutoFocus,
      role,
    },
    forwardedRef
  ) => (
    <DropdownMenuPrimitive.Portal>
      <StyledMenuContent
        align={align}
        side={side}
        sideOffset={sideOffset}
        $width={width}
        $small={small}
        className={className}
        ref={forwardedRef}
        onCloseAutoFocus={onCloseAutoFocus}
        role={role}
      >
        {children}
      </StyledMenuContent>
    </DropdownMenuPrimitive.Portal>
  )
)

export const MenuItem: React.FC<MenuItemProps> = ({
  onSelect,
  label,
  Icon,
  active = false,
  disabled = false,
  className,
}) => (
  <StyledMenuItem
    className={stringHelper.classNames(className, active ? "active" : undefined)}
    onClick={(e) => e.stopPropagation()}
    onSelect={onSelect}
    $active={active}
    $disabled={disabled}
  >
    {Icon && <IconStyled Icon={Icon} css={ICON_STYLES} />}
    <div>{label}</div>
  </StyledMenuItem>
)

export const CustomMenuItem: React.FC<React.PropsWithChildren<CustomMenuItemProps>> = ({
  onSelect,
  active = false,
  disabled = false,
  children,
  className,
}) => (
  <StyledMenuItem
    className={stringHelper.classNames(className, active ? "active" : undefined)}
    onClick={(e) => e.stopPropagation()}
    onSelect={onSelect}
    $active={active}
    $disabled={disabled}
  >
    {children}
  </StyledMenuItem>
)

export const CheckboxMenuItem: React.FC<CheckboxMenuItemProps> = ({
  className,
  label,
  disabled,
  checked,
  onCheckedChange,
  role,
}) => {
  const onCheckboxChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onCheckedChange(e.target.checked)
    },
    [onCheckedChange]
  )
  return (
    <DropdownMenuPrimitive.CheckboxItem
      asChild
      checked={checked}
      onCheckedChange={onCheckedChange}
      onSelect={(e: Event) => {
        // required to prevent the menu from closing when clicked
        e.preventDefault()
      }}
    >
      <StyledCheckboxMenuItem
        className={className}
        $disabled={disabled}
        aria-label={label}
        role={role}
        aria-selected={role === "option" ? booleanish(checked) : undefined}
      >
        <Checkbox id={label} name={label} checked={checked} onChange={onCheckboxChange} />
        {label}
      </StyledCheckboxMenuItem>
    </DropdownMenuPrimitive.CheckboxItem>
  )
}

export const EllipsisMenuTrigger: React.FC<{
  disabled?: boolean
  variant?: ButtonVariant
  size?: ButtonSize
  inert?: boolean
  className?: string
}> = ({ disabled, size, variant = "ghost-dark", inert, className }) => {
  const stopPropagation = React.useCallback((e: React.MouseEvent) => {
    e.stopPropagation()
  }, [])

  return (
    <MenuTrigger asChild disabled={disabled} className={className}>
      <DigitsButton
        $circle
        $solidIcon
        $variant={variant}
        size={size}
        disabled={disabled}
        onClick={stopPropagation}
        inert={inert ?? undefined}
      >
        <SvgDotsVerticalSolid />
      </DigitsButton>
    </MenuTrigger>
  )
}

const IconStyled: React.FC<{
  Icon: React.ComponentType<React.SVGProps<SVGSVGElement>>
  className?: string
}> = ({ Icon, className }) => <Icon className={className} />

function booleanish(value: boolean) {
  return value ? "true" : "false"
}
