export enum AspectCode {
  Branding = "bnd",
  Audit = "adt",
  PayBills = "pbl",
  GetPaid = "gpd",
  AIBookkeeper = "aib",
  Connections = "cnx",
  Vault = "vlt",
  Invoicing = "inv",
  AgentHydratedParties = "ahp",
}
