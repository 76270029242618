import React from "react"
import {
  type ObjectEntities,
  type ObjectIdentifier,
  type Thread as ThreadModel,
  type ThreadDetails,
  useReadThreadQuery,
} from "@digits-graphql/frontend/graphql-bearer"
import { PointingDirection } from "@digits-shared/components/UI/Elements/Chevron"
import { PopUp, type PopUpCoordinates } from "@digits-shared/components/UI/Elements/PopUp/PopUp"
import useStateBoolean from "@digits-shared/hooks/useStateBoolean"
import {
  type DigitsTheme,
  type Theme,
  ThemeContext,
  themedStyles,
  themedValue,
  useThemeMode,
} from "@digits-shared/themes"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled, { css, keyframes } from "styled-components"
import { useCanComment } from "src/frontend/hooks/useCanComment"
import { objectKindToModule } from "src/frontend/session/permissionModule"
import { Thread } from "src/shared/components/Comments/Thread"
import {
  type AnimationState,
  type ChooseAssignee,
  ThreadContext,
  type ThreadContextProps,
} from "src/shared/components/Comments/ThreadContext"

export const POPUP_WIDTH = 320
const CHEVRON_SIZE = 7

const RESOLVE_ANIMATION = keyframes`
  0% {
    clip-path: inset(0 0 0 0);
  }
  100% {
    clip-path: inset(50% 0 50% 0);
  }
`

const PopupContents = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const AnimatedPopUp = styled(PopUp)<PopUpProps>`
  ${themedStyles({
    light: css`
      background: linear-gradient(
        rgba(255, 255, 255, 0.45) 12.73%,
        rgba(255, 255, 255, 0.33) 90.37%
      );
      border: 1px solid ${colors.translucentWhite20};
      box-shadow: 0 0 20px 0 ${colors.translucentBlack10};
      border-radius: 16px;

      opacity: 0.9;
      transition: opacity 200ms;
      &:hover,
      &:focus-within {
        opacity: 1;
      }
    `,
    dark: undefined, // inherit from Popup
  })};

  ${({ resolved }) =>
    resolved &&
    css`
      animation: ${RESOLVE_ANIMATION} 500ms forwards;
    `}
`

const StyledThread = styled(Thread)`
  max-height: 535px;
`

const CloseButton = styled.div`
  width: 100%;
  height: 20px;

  cursor: pointer;
  line-height: 26px;

  &::before {
    position: absolute;
    right: 9px;
    content: "✕";
    color: ${themedValue({
      light: colors.translucentSecondary60,
      dark: colors.translucentWhite60,
    })};
    font-size: 12px;
    transition: color 200ms;
  }

  &:hover {
    &::before {
      color: ${themedValue({ light: colors.secondary, dark: colors.translucentWhite80 })};
      font-weight: ${fonts.weight.heavy};
    }
  }
`

/*
 INTERFACES
*/

interface PopUpProps {
  resolved: boolean
}

interface Props {
  targetObject: ObjectIdentifier
  coordinates?: PopUpCoordinates
  threadDetails?: ThreadDetails | null
  context?: string
  className?: string
  autoFocus?: boolean
  animationState?: AnimationState
  chevronDirection?: PointingDirection
  customTheme?: Theme
  preventResolve?: boolean
  loading?: boolean
  allowResolvedThreads?: boolean
  threadEntities?: ObjectEntities | null
  resolvedThreads?: ThreadModel[]
  onCreate?: (thread: ThreadModel) => void
  onUpdate?: (thread: ThreadModel) => void
  onClose?: (threadDetails: ThreadDetails | null, e: Event) => void
  onResolve?: (threadId: string) => void
  onClick?: (threadDetails: ThreadDetails) => void
  onFocus?: (threadDetails?: ThreadDetails | string) => void
  onClosePopupClick?: () => void
  viewOnly?: boolean
  alignClientOppositeEmployees?: boolean
  placeholder?: string
  chooseAssignee?: ChooseAssignee
}

type PopUpHTMLProps = Omit<React.HTMLProps<HTMLDivElement>, "onFocus" | "onClick"> & Props

/*
 COMPONENTS
*/

export const ThreadPopUp = React.forwardRef<HTMLDivElement, PopUpHTMLProps>((props, ref) => {
  const {
    targetObject,
    chooseAssignee,
    context,
    coordinates,
    threadDetails,
    className,
    autoFocus,
    animationState,
    chevronDirection = PointingDirection.None,
    customTheme,
    preventResolve,
    loading,
    allowResolvedThreads,
    threadEntities,
    resolvedThreads,
    onCreate,
    onUpdate,
    onClose,
    onResolve,
    onClick,
    onMouseEnter,
    onMouseLeave,
    onFocus,
    onClosePopupClick,
    viewOnly,
    alignClientOppositeEmployees = false,
    placeholder,
  } = props

  const [commenting, setIsCommenting] = React.useState(false)
  const { value: resolved, setTrue: setResolved } = useStateBoolean(false)

  // Popup won't close if there is text in comment box or during expanding animation
  const isPopUpActive = !commenting && (!animationState || animationState === "expanded")

  const currentTheme = useThemeMode()
  const theme: DigitsTheme = React.useMemo(
    () => ({ mode: customTheme || currentTheme }),
    [currentTheme, customTheme]
  )

  // it reloads the comment count when there is a reply
  const { data } = useReadThreadQuery({
    variables: { id: threadDetails?.id || "" },
    skip: !threadDetails?.id,
    fetchPolicy: threadDetails?.pending ? "cache-only" : undefined,
    nextFetchPolicy: threadDetails?.pending ? "cache-only" : undefined,
    context: { noBatch: true },
  })

  const onResolveAnimationEnd = React.useCallback(
    (e: React.AnimationEvent) => {
      if (e.animationName !== RESOLVE_ANIMATION.getName()) return
      if (threadDetails?.id) {
        onResolve?.(threadDetails?.id)
      }
    },
    [onResolve, threadDetails?.id]
  )

  const onSendStateChange = React.useCallback((enabled: boolean) => {
    setIsCommenting(enabled)
  }, [])

  const canComment = useCanComment(objectKindToModule(targetObject.kind))

  const details = data?.thread.thread.details || threadDetails
  const threadId = details?.id
  const threadContext: ThreadContextProps = React.useMemo(
    () => ({
      allowResolvedThreads: Boolean(allowResolvedThreads),
      threadEntities,
      resolvedThreads,
      activeThreadId: threadId,
      activeThreadDetails: details,
      context,
      targetObject,
      chooseAssignee,
      onCreate,
      onUpdate,
      onClick,
      onFocus,
      onBeforeResolve: setResolved,
      onClose,
      autoFocus: autoFocus === undefined || autoFocus,
      animationState,
      canComment,
      preventResolve,
      loading,
      viewOnly,
      alignClientOppositeEmployees,
      placeholder,
    }),
    [
      allowResolvedThreads,
      threadEntities,
      resolvedThreads,
      threadId,
      details,
      context,
      targetObject,
      chooseAssignee,
      onCreate,
      onUpdate,
      onClick,
      onFocus,
      setResolved,
      onClose,
      autoFocus,
      animationState,
      canComment,
      preventResolve,
      loading,
      viewOnly,
      alignClientOppositeEmployees,
      placeholder,
    ]
  )

  return (
    <ThreadContext.Provider value={threadContext}>
      <ThemeContext.Provider value={theme}>
        <AnimatedPopUp
          ref={ref}
          key={context}
          data-context={context}
          className={className}
          maxHeight="auto"
          width={POPUP_WIDTH}
          coordinates={coordinates}
          chevron={{ size: CHEVRON_SIZE, direction: chevronDirection }}
          onClose={onClose?.bind(undefined, threadDetails)}
          noStopPropagation
          active={isPopUpActive}
          resolved={resolved}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onAnimationEnd={onResolveAnimationEnd}
        >
          <PopupContents>
            {onClosePopupClick && <CloseButton onClick={onClosePopupClick} />}
            <StyledThread
              viewOnly={viewOnly}
              onSendStateChange={onSendStateChange}
              hasEmptyState={false}
            />
          </PopupContents>
        </AnimatedPopUp>
      </ThemeContext.Provider>
    </ThreadContext.Provider>
  )
})
