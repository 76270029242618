import * as React from "react"
import {
  ListActionItemsAndBadgeCountDocument,
  ListActionItemsBadgeCountsDocument,
  type ObjectIdentifier,
  ObjectKind,
  type OrganizationAssignee,
  type UserAssignee,
  useReadThreadQuery,
  useUpdateActionItemAssignmentStatusMutation,
  useUpdateActionItemWatchStatusMutation,
} from "@digits-graphql/frontend/graphql-bearer"
import { SvgEye } from "@digits-shared/components/SVGIcons/line/Eye.svg"
import { SvgEyeOff } from "@digits-shared/components/SVGIcons/line/EyeOff.svg"
import { SvgMessageTextSquare02 } from "@digits-shared/components/SVGIcons/line/MessageTextSquare02.svg"
import { SvgSwitchHorizontal01 } from "@digits-shared/components/SVGIcons/line/SwitchHorizontal01.svg"
import { DigitsButton } from "@digits-shared/DesignSystem/Button"
import { AlertModal } from "@digits-shared/DesignSystem/Modals/AlertModal"
import { DigitsTooltip } from "@digits-shared/DesignSystem/Tooltip"
import { hasExactlyOne } from "@digits-shared/helpers/arrayHelper"
import { useMeasure } from "@digits-shared/hooks/useMeasure"
import { usePopOverState } from "@digits-shared/hooks/usePopOverState"
import useSession from "@digits-shared/hooks/useSession"
import styled from "styled-components"
import {
  useGroupView,
  useHasActionButtons,
} from "src/frontend/components/Shared/NavSidebar/ActionItems/useGroupView"
import type FrontendSession from "src/frontend/session"
import { ThreadPopUp } from "src/shared/components/Comments"
import {
  isTypeaheadOrganization,
  isTypeaheadUser,
  type UserWithOrgTypeaheadResult,
} from "src/shared/components/Typeahead/sharedTypeahead"
import { Typeahead } from "src/shared/components/Typeahead/Typeahead"
import { useTypeaheadState } from "src/shared/components/Typeahead/useTypeaheadState"
import { useStopClickPropagation } from "src/shared/hooks/useStopClickPropagation"

const FETCHES = [ListActionItemsBadgeCountsDocument, ListActionItemsAndBadgeCountDocument]

/*
  STYLES
*/

const Buttons = styled.div`
  display: flex;
  width: 0;
  align-items: center;
  gap: 4px;
  opacity: 0;
  margin-left: 2px;

  & > div {
    width: 0;
  }
  a:hover > & {
    width: unset;
    opacity: 1;

    & > div {
      width: unset;
    }
  }
`

const ActionThread = styled(ThreadPopUp)`
  &:before {
    content: "";
    position: absolute;
    left: -20px;
    height: 100px;
    width: 100px;
    z-index: -1;
  }
`

interface ActionsProps {
  actionItemId: string
}

interface ThreadActionsProps extends ActionsProps {
  threadId: string
  targetObject: ObjectIdentifier
}

/*
  COMPONENTS
*/

export const BillActions: React.FC<ActionsProps> = ({ actionItemId }) => (
  <ActionsButtons>
    <WatchButton actionItemId={actionItemId} />
    <UnwatchButton actionItemId={actionItemId} />
  </ActionsButtons>
)

export const ReportActions: React.FC<ActionsProps> = ({ actionItemId }) => (
  <ActionsButtons>
    <WatchButton actionItemId={actionItemId} />
    <UnwatchButton actionItemId={actionItemId} />
  </ActionsButtons>
)

export const ThreadActions: React.FC<ThreadActionsProps> = ({
  actionItemId,
  threadId,
  targetObject,
}) => (
  <ActionsButtons>
    <WatchButton actionItemId={actionItemId} />
    <UnwatchButton actionItemId={actionItemId} />
    <ReassignButton actionItemId={actionItemId} targetObject={targetObject} />
    <CommentButton threadId={threadId} targetObject={targetObject} />
  </ActionsButtons>
)

const ActionsButtons: React.FC<React.PropsWithChildren> = ({ children }) => {
  const stopPropagation = useStopClickPropagation({ preventDefault: true })
  if (!useHasActionButtons()) return null

  return <Buttons onClick={stopPropagation}>{children}</Buttons>
}

const CommentButton: React.FC<{ threadId: string; targetObject: ObjectIdentifier }> = ({
  threadId,
  targetObject,
}) => {
  const [buttonRef, buttonRect] = useMeasure<HTMLButtonElement>()
  const [threadRef, threadRect] = useMeasure<HTMLDivElement>()
  const [resolved, setResolved] = React.useState(false)
  const { isPopOverOpen, onMouseEnter, onMouseLeave } = usePopOverState({
    disabled: resolved,
    waitToOpen: 200,
    waitToClose: 1000,
  })

  const { data, loading } = useReadThreadQuery({
    variables: {
      id: threadId,
      allowResolved: true,
    },
    skip: !isPopOverOpen,
  })

  const coordinates = React.useMemo(() => {
    let popUpTop = buttonRect.top - 20
    if (popUpTop + threadRect.height > window.innerHeight) {
      popUpTop = window.innerHeight - threadRect.height - 20
    }
    return {
      top: popUpTop,
      left: "calc(100% - 10px)",
    }
  }, [buttonRect.top, threadRect.height])

  return (
    <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <DigitsTooltip content="Comments">
        <DigitsButton
          ref={buttonRef}
          $circle
          $variant="secondary-light"
          size="small"
          disabled={resolved}
        >
          <SvgMessageTextSquare02 />
        </DigitsButton>
      </DigitsTooltip>

      {isPopOverOpen && (
        <ActionThread
          ref={threadRef}
          viewOnly
          threadDetails={data?.thread.thread.details}
          loading={loading}
          targetObject={targetObject}
          onResolve={() => setResolved(true)}
          coordinates={coordinates}
        />
      )}
    </div>
  )
}

const ReassignButton: React.FC<{ actionItemId: string; targetObject: ObjectIdentifier }> = ({
  actionItemId,
  targetObject,
}) => {
  const isAssignedTab = useGroupView() === "Assigned"
  const positionRef = React.useRef<HTMLDivElement>(null)
  const [assigneeName, setName] = React.useState<string>()
  const [organizationAssignees, setOrg] = React.useState<OrganizationAssignee[]>()
  const [userAssignees, setUser] = React.useState<UserAssignee[]>()
  const { isTypeaheadShown, onClick, hideTypeahead, ref } = useTypeaheadState(false)

  const onResultSelected = React.useCallback(
    (data: UserWithOrgTypeaheadResult) => {
      hideTypeahead()
      const { entity, title } = data
      setOrg(() => (isTypeaheadOrganization(entity) ? [{ organizationId: entity.id }] : undefined))
      setUser(() => (isTypeaheadUser(entity) ? [{ userId: entity.id }] : undefined))
      setName(() => title)
    },
    [hideTypeahead]
  )

  const onConfirmClose = React.useCallback(() => {
    setOrg(undefined)
    setUser(undefined)
    setName(undefined)
  }, [])

  return (
    <div css="position:relative;">
      <div ref={positionRef} css="position: absolute; left: calc(100% + 20px); top: -25px; " />
      <DigitsTooltip content="Re-assign">
        <DigitsButton $circle $variant="secondary-light" size="small" onClick={onClick}>
          <SvgSwitchHorizontal01 />
        </DigitsButton>
      </DigitsTooltip>

      {isTypeaheadShown && (
        <Typeahead
          ref={ref}
          positionRef={positionRef}
          placeholder="Find assignee…"
          kind={ObjectKind.User}
          includeOrganization
          includeCurrentUser={!isAssignedTab}
          objectIdentifier={targetObject}
          onResultSelected={onResultSelected}
          close={hideTypeahead}
        />
      )}

      {assigneeName && (
        <ConfirmReassignModal
          actionItemId={actionItemId}
          onConfirmClose={onConfirmClose}
          assigneeName={assigneeName}
          organizationAssignees={organizationAssignees}
          userAssignees={userAssignees}
        />
      )}
    </div>
  )
}

const ConfirmReassignModal: React.FC<{
  actionItemId: string
  assigneeName: string
  organizationAssignees: OrganizationAssignee[] | undefined
  userAssignees: UserAssignee[] | undefined
  onConfirmClose: () => void
}> = ({
  actionItemId,
  assigneeName,
  organizationAssignees = [],
  userAssignees = [],
  onConfirmClose,
}) => {
  const isAssignedTab = useGroupView() === "Assigned"
  const { currentLegalEntityId: legalEntityId, user } = useSession<FrontendSession>()
  const [updateAssignment, { loading }] = useUpdateActionItemAssignmentStatusMutation({
    variables: {
      legalEntityId,
      actionItemId,
      userAssignees,
      organizationAssignees,
    },
    refetchQueries: FETCHES,
    awaitRefetchQueries: true,
  })

  const onConfirm = React.useCallback(() => {
    updateAssignment().finally(onConfirmClose)
  }, [onConfirmClose, updateAssignment])

  const formattedName =
    hasExactlyOne(userAssignees) && user.id === userAssignees[0].userId ? "yourself" : assigneeName

  return (
    <AlertModal
      HeaderIcon={SvgSwitchHorizontal01}
      headerLabel="Re-assigning Item"
      disableAll={loading}
      primaryButtonLabel={loading ? "Re-assigning…" : "Re-assign"}
      primaryButtonClick={onConfirm}
      secondaryButtonLabel="Cancel"
      secondaryButtonClick={onConfirmClose}
    >
      Are you sure you want to re-assign this item to <b>{formattedName}</b>?
      <br />
      <br />
      {!isAssignedTab && (
        <>
          Previous <b>assignees</b> to this item will become <b>watchers</b>.
        </>
      )}
      {isAssignedTab && (
        <>
          This will remove the item from your <b>Assigned</b> section and move it to <b>Watching</b>
          .
        </>
      )}
    </AlertModal>
  )
}

const WatchButton: React.FC<{ actionItemId: string }> = ({ actionItemId }) => {
  const isOpenTab = useGroupView() === "Open"
  const { currentLegalEntityId: legalEntityId } = useSession<FrontendSession>()
  const [watch, { loading }] = useUpdateActionItemWatchStatusMutation({
    variables: { legalEntityId, actionItemId, watch: true },
    refetchQueries: FETCHES,
    awaitRefetchQueries: true,
  })

  const onWatchClick = React.useCallback(() => {
    watch()
  }, [watch])

  if (!isOpenTab) return null

  return (
    <DigitsTooltip content="Watch Item">
      <DigitsButton
        $circle
        $active={loading}
        $variant="secondary-light"
        size="small"
        loading={loading}
        onClick={onWatchClick}
        disabled={loading}
      >
        <SvgEye />
      </DigitsButton>
    </DigitsTooltip>
  )
}

const UnwatchButton: React.FC<{ actionItemId: string }> = ({ actionItemId }) => {
  const isWatchingTab = useGroupView() === "Watched"
  const { currentLegalEntityId: legalEntityId } = useSession<FrontendSession>()
  const [unwatch, { loading }] = useUpdateActionItemWatchStatusMutation({
    variables: { legalEntityId, actionItemId, watch: false },
    refetchQueries: FETCHES,
    awaitRefetchQueries: true,
  })

  const onUnwatchClick = React.useCallback(() => {
    unwatch()
  }, [unwatch])

  if (!isWatchingTab) return null

  return (
    <DigitsTooltip content="Stop Watching">
      <DigitsButton
        $circle
        $active={loading}
        $variant="secondary-light"
        size="small"
        loading={loading}
        onClick={onUnwatchClick}
        disabled={loading}
      >
        <SvgEyeOff />
      </DigitsButton>
    </DigitsTooltip>
  )
}
