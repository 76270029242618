import React from "react"
import {
  type Comment as ThreadComment,
  type EntityUser,
  Interval,
  type ObjectEntities,
} from "@digits-graphql/frontend/graphql-bearer"
import { LoadingBlock, LoadingCircle } from "@digits-shared/components/Loaders"
import { responsiveStyles } from "@digits-shared/components/Responsive/responsiveStyles"
import { ColorIcon } from "@digits-shared/components/UI/Elements/ColorIcon"
import PendingIcon from "@digits-shared/components/UI/Elements/PendingIcon"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import userHelper from "@digits-shared/helpers/userHelper"
import useLazyInterval from "@digits-shared/hooks/useLazyInterval"
import { themedStyles, themedValue } from "@digits-shared/themes"
import colors from "@digits-shared/themes/colors"
import { useThemedConstant } from "@digits-shared/themes/themedFunctions"
import fonts from "@digits-shared/themes/typography"
import styled, { css } from "styled-components"
import dayjs from "@digits-shared/initializers/dayjs/dayjs"
import { EntityName, User as StyledUser } from "src/shared/components/ObjectEntities/Entities"
import EntitiesParser from "src/shared/components/ObjectEntities/EntitiesParser"

/*
 STYLES
*/

const authorTextColor = themedValue({
  light: colors.secondary,
  dark: colors.translucentWhite50,
})

const User = styled.div<{ alignment: CommentAlignment }>`
  flex: 1;
  display: flex;
  gap: 6px;
  align-items: center;

  ${({ alignment }) =>
    alignment === "right" &&
    css`
      flex-direction: row-reverse;
      align-self: flex-end;
    `}
`

const AuthorContainer = styled.div`
  display: flex;
  align-items: baseline;
  gap: 6px;
`

const AuthorNameText = styled.div<{ alignment: CommentAlignment }>`
  font-size: 12px;
  font-weight: ${fonts.weight.heavy};
  display: flex;
  justify-content: flex-start;
  color: ${authorTextColor};

  ${({ alignment }) =>
    alignment === "right" &&
    css`
      flex-direction: row-reverse;
    `}
`

const CommentItem = styled.li<{ alignment: CommentAlignment; isResolved?: boolean }>`
  list-style: none;
  padding: 0 15px;

  ${({ isResolved }) =>
    isResolved &&
    css`
      ${AuthorNameText} {
        color: ${colors.lightDark};
      }

      ${CommentText} {
        color: #808080;
        &.left {
          background: #f2f2f2 !important;
        }
        &.right {
          background: #e5e5e5;
        }
      }

      ${Avatar}, ${StyledUser} {
        filter: grayscale(100%) brightness(1.2);
        span {
          color: ${colors.secondary90};
        }
      }
    `}

  text-align: left;
  display: flex;
  flex-direction: row;
  gap: 6px;

  ${({ alignment }) =>
    alignment === "left" &&
    css`
      flex-direction: row-reverse;
    `}
`

const CommentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 6px;
`

const Avatar = styled(ColorIcon)`
  margin: 0;
`

const Date = styled.div`
  font-weight: ${fonts.weight.normal};
  font-size: 11px;
  color: ${colors.secondary50};
`

const MessageText = styled.div<{ alignment: CommentAlignment }>`
  display: flex;
  flex: 1;
  ${({ alignment }) =>
    alignment === "right" &&
    css`
      justify-content: flex-end;
    `}
`

const textBubbleStyles = themedStyles<{ alignment: CommentAlignment }>({
  light: ({ alignment }) => css`
    background: ${alignment === "right" ? colors.accentBlue : colors.white};
    color: ${alignment === "right" ? colors.white : colors.secondary};
  `,
  dark: ({ alignment }) => css`
    background: linear-gradient(
      ${alignment === "right" ? "to right" : "to left"},
      #3f9288,
      #146570
    );
    color: ${colors.white};
  `,
})

const textResponsiveStyles = responsiveStyles({
  desktop: css`
    font-size: 14px;
    font-weight: ${fonts.weight.medium};
  `,
  mobile: css`
    font-size: 18px;
    font-weight: ${fonts.weight.medium};
  `,
})

export const CommentText = styled.div<{ alignment: CommentAlignment }>`
  ${textBubbleStyles};
  ${textResponsiveStyles};

  word-break: break-word;
  position: relative;
  padding: 9px 15px;
  box-shadow: 0 7px 20px rgba(0, 0, 0, 0.015);

  * {
    // pasted HTML into comments will break out of the comment bubble.
    // force them to wrap
    white-space: unset !important;
  }

  a {
    text-decoration: underline;
  }

  ul,
  ol {
    padding: revert;
  }

  ${({ alignment }) => {
    if (alignment === "right") {
      return css`
        border-radius: 16px 16px 0 16px;

        ${EntityName} {
          color: ${colors.white};
        }
      `
    }

    // left
    return css`
      border-radius: 0 16px 16px 16px;

      ${StyledUser} {
        background-image: linear-gradient(
          90deg,
          ${colors.translucentSecondary05} 0%,
          ${colors.translucentSecondary10} 102.05%
        );
      }

      ${EntityName} {
        color: ${colors.secondary};
      }
    `
  }};
`

/*
 INTERFACES
*/

type CommentAlignment = "right" | "left"

interface TimestampProps {
  comment?: ThreadComment
}

interface CommentProps {
  comment?: ThreadComment
  alignment: CommentAlignment
  entities?: ObjectEntities | null
  author: EntityUser | undefined
}

interface Props {
  isResolved?: boolean
  comment?: ThreadComment
  isCurrentUser?: boolean
  alignment?: CommentAlignment
  entities?: ObjectEntities | null
  className?: string
}

/*
 COMPONENTS
*/

export const Comment: React.FC<React.PropsWithChildren<Props>> = ({
  comment,
  isResolved,
  isCurrentUser,
  alignment: alignmentOverride,
  entities,
  className,
  children,
}) => {
  const alignment = alignmentOverride ?? (!comment || isCurrentUser ? "right" : "left")
  const author = useCommentAuthor(comment, entities)

  return (
    <CommentItem isResolved={isResolved} className={className} alignment={alignment}>
      <CommentContainer>
        {children}
        <Message comment={comment} author={author} alignment={alignment} entities={entities} />
        <Author comment={comment} author={author} alignment={alignment} entities={entities} />
      </CommentContainer>
      <AuthorIcon author={author} />
    </CommentItem>
  )
}

const AuthorIcon: React.FC<{ author: EntityUser | undefined }> = ({ author }) => {
  // loading
  if (!author) {
    return (
      <LoadingCircle
        width="32px"
        height="32px"
        backgroundColor={colors.translucentWhite20}
        $shineColor={colors.translucentWhite20}
      />
    )
  }
  return <Avatar imageUrl={author?.avatarUrl} fallbackUser={author} size={32} fontSize={15} />
}

const Author: React.FC<CommentProps> = ({ comment, author, alignment, entities }) => {
  // loading
  if (!comment) {
    return (
      <User alignment={alignment}>
        <LoadingCircle
          width="32px"
          height="32px"
          backgroundColor={colors.translucentWhite20}
          $shineColor={colors.translucentWhite20}
        />
        <AuthorName comment={comment} author={author} alignment={alignment} entities={entities} />
      </User>
    )
  }

  return (
    <User alignment={alignment}>
      <AuthorName comment={comment} author={author} alignment={alignment} entities={entities} />
    </User>
  )
}

const AuthorName: React.FC<CommentProps> = ({ comment, author, alignment }) => {
  const loadingBackgroundColor = useThemedConstant({
    light: "#eeefee",
    dark: colors.translucentWhite20,
  })

  // loading
  if (!comment) {
    return (
      <div>
        <LoadingBlock
          display="block"
          width="150px"
          height="11px"
          backgroundColor={loadingBackgroundColor}
          $shineColor={loadingBackgroundColor}
        />
      </div>
    )
  }

  if (!author || !author.givenName || !author.familyName) return null

  const name = userHelper.displayName(author, "abbreviateLast")
  return (
    <AuthorContainer>
      <AuthorNameText alignment={alignment}>{name}</AuthorNameText>
      <Timestamp comment={comment} />
    </AuthorContainer>
  )
}

const Message: React.FC<CommentProps> = ({ comment, alignment, entities }) => {
  if (!comment) {
    return (
      <CommentText alignment={alignment}>
        <PendingIcon color={colors.white} />
      </CommentText>
    )
  }

  const { text } = comment

  return (
    <MessageText alignment={alignment} className="message-text">
      <CommentText alignment={alignment} className={alignment}>
        <EntitiesParser text={text} entities={entities} decode />
      </CommentText>
    </MessageText>
  )
}

const Timestamp: React.FC<Required<TimestampProps>> = ({ comment }) => {
  const { timeDelta } = useCommentTimestamp(comment)

  return <Date>{timeDelta}</Date>
}

function useCommentAuthor(comment?: ThreadComment, entities?: ObjectEntities | null) {
  return React.useMemo(() => {
    if (!comment) return comment
    const { authorId } = comment
    return entities?.users?.find((u) => u.id === authorId)
  }, [comment, entities?.users])
}

function useCommentTimestamp(comment: ThreadComment) {
  const { timestamp } = comment
  const date = dayjs.unix(timestamp)
  const sameDay = date.isSame(dayjs(), "day")

  // update timestamp every minute
  const [timeDelta, setTimeDelta] = React.useState(
    sameDay ? date.fromNow() : dateTimeHelper.displayNameFromDayjs(date, Interval.Day)
  )

  const [startInterval, timer] = useLazyInterval(() => {
    setTimeDelta(date.fromNow())
  }, 60 * 1000)

  React.useEffect(() => {
    if (!sameDay) {
      if (timer.current) {
        clearInterval(timer.current)
      }
      return
    }
    startInterval()
  }, [sameDay, startInterval, timer])

  return React.useMemo(() => ({ timeDelta, sameDay }), [timeDelta, sameDay])
}
