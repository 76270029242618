import * as React from "react"
import { useHistory, useLocation } from "react-router-dom"
import { type Action, type Location } from "history"
import { elementByIdScrollTop, elementScrollTop, getElementScrollTop } from "./Utils"

interface ScrollProps {
  scrollElement?: React.RefObject<Element | null>
  elementID?: string
  ready?: Promise<void>
  behavior?: ScrollBehavior
}

const ScrollMemory: React.FC<ScrollProps> = ({ scrollElement, elementID, ready, behavior }) => {
  const urlMap = React.useRef(new Map<string, { scrollHeight: number; scrollTop: number }>())
  const history = useHistory()
  const browserLocation = useLocation()

  const scrollWhenContentReady = React.useCallback(
    (data: { scrollHeight: number; scrollTop: number }, timestamp = Date.now()) => {
      const element = elementID ? document.getElementById(elementID) : scrollElement?.current
      if (!element) {
        window.requestAnimationFrame(() => scrollWhenContentReady(data, timestamp))
        return
      }

      ;(element as HTMLElement).style.filter = "opacity(0)"
      const { scrollHeight, scrollTop } = data

      if (element.scrollHeight < scrollHeight && timestamp + 3000 > Date.now()) {
        window.requestAnimationFrame(() => scrollWhenContentReady(data, timestamp))
        return
      }

      ;(element as HTMLElement).style.removeProperty("filter")
      elementScrollTop(scrollTop, element, behavior)
    },
    [behavior, elementID, scrollElement]
  )

  const handlePushAction = React.useCallback(
    (location: Location) => {
      if (elementID) {
        elementByIdScrollTop(0, elementID, behavior)
      } else if (scrollElement?.current) {
        elementScrollTop(0, scrollElement.current, behavior)
      }
    },
    [behavior, elementID, scrollElement]
  )

  const handlePopAction = React.useCallback(
    (location: Location) => {
      const key = location.key || "enter"
      const data = urlMap.current.get(key)

      if (data !== undefined) {
        scrollWhenContentReady(data)
        if (ready) {
          ready.then(() => {
            scrollWhenContentReady(data)
          })
        }
      }
    },
    [ready, scrollWhenContentReady]
  )

  const handleHistoryEvent = React.useCallback(
    (location: Location, action: Action) => {
      const actual = browserLocation
      const key = actual.key || "enter"

      const element = elementID ? document.getElementById(elementID) : scrollElement?.current

      const scrollTop = element ? getElementScrollTop(element) : 0
      const scrollHeight = element?.scrollHeight ?? 0

      const locationChanged =
        (location.pathname !== actual.pathname || location.search !== actual.search) &&
        location.hash === ""

      if (locationChanged) {
        urlMap.current.set(key, { scrollTop, scrollHeight })

        if (action === "PUSH") {
          handlePushAction(location)
        }
      }

      if (action === "POP") {
        handlePopAction(location)
      }
    },
    [browserLocation, elementID, handlePopAction, handlePushAction, scrollElement]
  )

  React.useEffect(() => {
    const unregister = history.listen(handleHistoryEvent)
    return () => unregister()
  }, [history, handleHistoryEvent])

  return null
}

export default ScrollMemory
