import * as React from "react"
import {
  type Assignee,
  type ObjectIdentifier,
  ObjectKind,
  type OrganizationAssignee,
  type TextComponentConfigTag,
  type User,
  type UserAssignee,
  useReadThreadQuery,
} from "@digits-graphql/frontend/graphql-bearer"
import { hasFirstElement } from "@digits-shared/helpers/arrayHelper"
import userHelper from "@digits-shared/helpers/userHelper"
import useSession from "@digits-shared/hooks/useSession"
import { PENDING_ID_PREFIX } from "src/shared/initializers/typePolicies"
import { useListSuggestedUsers } from "src/frontend/hooks/useListSuggestedUsers"
import type FrontendSession from "src/frontend/session"
import useThreadContext from "src/shared/components/Comments/ThreadContext"
import { extractEntitiesAsTags } from "src/shared/components/ObjectEntities/entitiesParserShared"
import { useCurrentAffiliation } from "src/shared/hooks/useCurrentAffiliation"

type OrganizationAssigneeWithName = OrganizationAssignee & { name: string }
type UserAssigneeWithName = UserAssignee & { name: string }
export type AssigneeWithName =
  | { organization: OrganizationAssigneeWithName }
  | { user: UserAssigneeWithName }

export function isOrganizationAssignee(
  assignee: Assignee | undefined
): assignee is { organization: OrganizationAssigneeWithName } {
  return (
    !!assignee &&
    "organization" in assignee &&
    "name" in (assignee.organization as OrganizationAssigneeWithName)
  )
}

export function isUserAssignee(
  assignee: Assignee | undefined
): assignee is { user: UserAssigneeWithName } {
  return !!assignee && "user" in assignee && "name" in (assignee.user as UserAssigneeWithName)
}

// Infer who the default assignee for this new comment should be.
export function useInferAssignee() {
  const { targetObject, chooseAssignee } = useThreadContext()
  const {
    inferFromCommentTags,
    lastAuthor,
    affiliateOrganization,
    legalEntityOrganization,
    firstUser,
    toUserAssignee,
    toOrgAssignee,
  } = usePotentialAssignees(targetObject)

  const inferredAssignee = React.useMemo<AssigneeWithName | undefined>(() => {
    // Prefer the provided context-specific choice.
    if (chooseAssignee)
      return chooseAssignee({
        lastAuthor,
        affiliateOrganization,
        legalEntityOrganization,
        firstUser,
      })
    return lastAuthor || affiliateOrganization || legalEntityOrganization || firstUser
  }, [affiliateOrganization, chooseAssignee, firstUser, lastAuthor, legalEntityOrganization])

  return { inferredAssignee, toUserAssignee, toOrgAssignee, inferFromCommentTags }
}

function usePotentialAssignees(targetObject: ObjectIdentifier) {
  const {
    user: currentUser,
    currentOrganizationId,
    currentLegalEntity,
    isAffiliatedSession,
    currentAffiliation: currentSessionAffiliation,
  } = useSession<FrontendSession>()
  const { users: usersList } = useListSuggestedUsers(targetObject, currentOrganizationId)
  const currentAffiliation = useCurrentAffiliation()
  const affiliationOrg = currentAffiliation?.organization

  const { activeThreadId, resolvedThreads } = useThreadContext()
  const { data, loading } = useReadThreadQuery({
    variables: {
      id: activeThreadId || "",
      allowResolved: true,
    },
    skip: !activeThreadId || activeThreadId.startsWith(PENDING_ID_PREFIX),
    context: { noBatch: true },
  })

  return React.useMemo(() => {
    const allComments = [
      ...(resolvedThreads?.flatMap((t) => t.comments || []) || []),
      ...(data?.thread?.thread?.comments || []),
    ]

    const users =
      // wait for comments to load to avoid returning the first user prematurely
      !loading && usersList ? usersList.toSorted(userHelper.compareUsers) : []

    const usersMaps = users.reduce((map, user) => {
      map.set(user.id, user)
      return map
    }, new Map<string, User>())

    const affiliatedUsersMap = (currentAffiliation?.affiliatedUsers || []).reduce((map, aff) => {
      map.set(aff.user.id, aff.user)
      return map
    }, new Map<string, User>())

    const lastDifferentAuthorId = allComments
      // Don't self-assign for repeat comments.
      ?.filter(({ authorId }) => authorId !== currentUser.id)
      .at(-1)?.authorId

    const toOrgAssignee = (organizationId: string): AssigneeWithName | undefined => {
      const name =
        affiliationOrg?.id === organizationId ? affiliationOrg.name : currentLegalEntity.name
      return {
        organization: {
          organizationId,
          name,
        },
      }
    }

    const toUserAssignee = (userId: string): AssigneeWithName | undefined => {
      const user = usersMaps.get(userId)
      if (!user) return undefined

      const isAffiliate = affiliatedUsersMap.has(userId)
      const employeeOrgId = user?.employments?.find(
        (e) => e.organization.id === legalEntityOrgId // _not_ currentOrganizationId which can be the affiliate org.
      )?.organization.id
      const organizationId = isAffiliate ? currentAffiliation?.organization.id : employeeOrgId

      return {
        user: {
          userId: user.id,
          organizationId,
          name: userHelper.displayName(user, "abbreviateLast"),
        },
      }
    }

    // Only assign to the affiliate organization when the commenter is not
    // an employee of that organization.
    const affiliateOrganizationAssignee =
      // wait for comments to load to avoid returning the affiliate prematurely
      !loading && affiliationOrg?.id && !isAffiliatedSession
        ? toOrgAssignee(affiliationOrg.id)
        : undefined

    const legalEntityOrgId = isAffiliatedSession
      ? currentSessionAffiliation?.organization.id // _not_ .entity.organizationId which can be the affiliate org.
      : currentOrganizationId
    const legalEntityOrganizationAssignee =
      // wait for comments to load to avoid returning the org prematurely
      !loading && isAffiliatedSession && legalEntityOrgId
        ? toOrgAssignee(legalEntityOrgId)
        : undefined

    return {
      inferFromCommentTags: (commentText: string): AssigneeWithName | undefined => {
        const tags = extractEntitiesAsTags(commentText, currentLegalEntity.id)
        const firstTag = tags
          .filter((tag) => tag.objectId.kind === ObjectKind.User)
          .map<[TextComponentConfigTag, number]>((tag) => [
            tag,
            commentText.indexOf(tag.objectId.id),
          ])
          .filter(([_, idx]) => idx !== -1)
          .toSorted((a, b) => a[1] - b[1])[0]?.[0]

        return firstTag ? toUserAssignee(firstTag?.objectId.id) : undefined
      },
      firstUser: hasFirstElement(users) ? toUserAssignee(users[0].id) : undefined,
      lastAuthor: lastDifferentAuthorId ? toUserAssignee(lastDifferentAuthorId) : undefined,
      affiliateOrganization: affiliateOrganizationAssignee,
      legalEntityOrganization: legalEntityOrganizationAssignee,
      toUserAssignee,
      toOrgAssignee,
    }
  }, [
    resolvedThreads,
    data?.thread?.thread?.comments,
    loading,
    usersList,
    currentAffiliation?.affiliatedUsers,
    currentAffiliation?.organization.id,
    affiliationOrg?.id,
    affiliationOrg?.name,
    isAffiliatedSession,
    currentSessionAffiliation?.organization.id,
    currentOrganizationId,
    currentLegalEntity.name,
    currentLegalEntity.id,
    currentUser.id,
  ])
}
