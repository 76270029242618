import React from "react"
import { isNetworkRequestInFlight } from "@apollo/client/core/networkStatus"
import {
  type EntityUser,
  type ObjectIdentifier,
  ObjectKind,
  type User,
} from "@digits-graphql/frontend/graphql-bearer"
import { defined, uniqueBy } from "@digits-shared/helpers/filters"
import userHelper from "@digits-shared/helpers/userHelper"
import useSession from "@digits-shared/hooks/useSession"
import { useListSuggestedUsers } from "src/frontend/hooks/useListSuggestedUsers"
import type FrontendSession from "src/frontend/session"
import { type UserTypeaheadResult } from "src/shared/components/Typeahead/sharedTypeahead"

export function useTypeaheadUsers(
  text: string,
  objectIdentifier: ObjectIdentifier,
  includeCurrentUser: boolean = false
) {
  const { currentOrganizationId, user } = useSession<FrontendSession>()
  const { users, networkStatus } = useListSuggestedUsers(objectIdentifier, currentOrganizationId)
  const currentUser = includeCurrentUser ? userHelper.sessionUserToEntity(user) : undefined

  return React.useMemo(
    () => ({
      loading: isNetworkRequestInFlight(networkStatus),
      results: buildUserResults(text, users, currentUser),
    }),
    [currentUser, networkStatus, text, users]
  )
}

function userToTypeaheadResult(user: EntityUser): UserTypeaheadResult {
  return {
    id: user.id,
    title: userHelper.displayName(user),
    subtitle: user.emailAddress,
    searchValues: [user.givenName, user.familyName, user.emailAddress].filter(defined),
    kind: ObjectKind.User,
    entity: user,
  }
}

function buildUserResults(
  query: string,
  users: User[] | undefined,
  currentUser: EntityUser | undefined
) {
  const searchTerm = query.trim()
  if (!users) return []

  const list = users.map((u) => userHelper.userToEntity(u))
  if (currentUser) {
    list.push(currentUser)
  }

  return list
    .map(userToTypeaheadResult)
    .filter(uniqueBy((u) => u.id))
    .filter((data) => data.searchValues.some((v) => v.toLowerCase().startsWith(searchTerm)))
    .sort((a, b) => a.title.localeCompare(b.title))
}
