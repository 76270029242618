import * as React from "react"
import { useRouteMatch } from "react-router-dom"
import { type ObjectIdentifier, ObjectKind } from "@digits-graphql/frontend/graphql-bearer"
import useRouter from "@digits-shared/hooks/useRouter"
import useSession from "@digits-shared/hooks/useSession"
import useReportPackageContext, {
  useIsEditMode,
  useIsPreview,
  useIsReportVersionedView,
} from "src/frontend/components/Shared/Reports/Packages/Viewer/ReportPackageContext"
import { useReportCommentsDispatch } from "src/frontend/components/Shared/Reports/ReportComments/ReportCommentsContext"
import {
  useActiveCommentEntry,
  useCommentsRegistryEntries,
} from "src/frontend/components/Shared/Reports/ReportComments/selectors"
import { REPORT_TOP_LEVEL_THREAD } from "src/frontend/components/Shared/Reports/ReportComments/types"
import { useFrontendPathGenerator } from "src/frontend/hooks/useFrontendPathGenerator"
import routes from "src/frontend/routes"
import type FrontendSession from "src/frontend/session"

/*
  HOOKS
*/

export function useIsCommentMode() {
  const {
    packageState: { showAssistant },
  } = useReportPackageContext()
  const isEditRoot = !!useRouteMatch(routes.reportPackageEditVersion.parameterizedPath)?.isExact
  const isViewRoot = !!useRouteMatch(routes.reportPackage.parameterizedPath)?.isExact
  const isPreviewRoot = useIsReportVersionedView(true)

  return !showAssistant && (isEditRoot || isViewRoot || isPreviewRoot)
}

export function useEnableComments() {
  const { packageDispatch } = useReportPackageContext()
  const isCommentMode = useIsCommentMode()
  const {
    packageState: { showAssistant },
  } = useReportPackageContext()
  const editMode = useIsEditMode()

  const isVersionedView = useIsReportVersionedView()
  const previewMode = useIsPreview()

  const { history } = useRouter()
  const generatePath = useFrontendPathGenerator()

  // turn everything else off
  const enable = React.useCallback(() => {
    if (showAssistant) {
      return packageDispatch({ type: "SHOW_ASSISTANT", value: false })
    }

    const route =
      isVersionedView || previewMode
        ? routes.reportPackageVersion
        : editMode
          ? routes.reportPackageEditVersion
          : routes.reportPackage

    history.replace(generatePath(route, {}, "includeAllQueryParams"))
  }, [
    editMode,
    generatePath,
    history,
    isVersionedView,
    packageDispatch,
    previewMode,
    showAssistant,
  ])

  // noop
  const noop = React.useCallback(() => {}, [])

  return isCommentMode ? noop : enable
}

export function useIsActivelyCommenting() {
  const active = useActiveCommentEntry()
  return !!active
}

export function useThreadDeeplink() {
  const { location } = useRouter()
  const { threadId, commentId, newComment } = location.queryParams
  return React.useMemo(
    () => ({
      threadId,
      commentId,
      newComment: newComment === "true" ? true : undefined,
    }),
    [commentId, newComment, threadId]
  )
}

export function useDocumentDeeplink() {
  const { location } = useRouter()
  const { deeplink } = location.queryParams
  return deeplink
}

export function useReportTargetObject(): ObjectIdentifier {
  const { currentLegalEntityId: legalEntityId } = useSession<FrontendSession>()
  const { reportPackage } = useReportPackageContext()
  const packageId = reportPackage?.id || ""

  return React.useMemo(
    () => ({
      kind: ObjectKind.ReportPackage,
      id: packageId,
      legalEntityId,
    }),
    [legalEntityId, packageId]
  )
}

export function useReportThreadsReposition() {
  const commentsDispatch = useReportCommentsDispatch()
  return React.useCallback(() => {
    commentsDispatch({ type: "REPOSITION_THREADS" })
  }, [commentsDispatch])
}

export function useDispatchResolveSidebarThread() {
  const commentsDispatch = useReportCommentsDispatch()
  const entries = useCommentsRegistryEntries()
  return React.useCallback(() => {
    const activeThread = entries.find(
      (entry) => !entry.resolved && entry.context === REPORT_TOP_LEVEL_THREAD
    )
    const activeThreadId = activeThread?.thread?.id
    if (activeThreadId) {
      commentsDispatch({ type: "RESOLVE_THREAD", threadId: activeThreadId })
    }
  }, [commentsDispatch, entries])
}
