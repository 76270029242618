import * as React from "react"
import { type FetchResult } from "@apollo/client"
import {
  useActivateCurrentUserEmploymentMutation,
  useActivateLegalEntityMutation,
} from "@digits-graphql/frontend/graphql-bearer"
import useSession from "@digits-shared/hooks/useSession"
import { JWTPermissionFlag } from "@digits-shared/session/jwt/jwtPermissions"
import { useHasPermission } from "src/frontend/components/Shared/Permissions/Requires"
import type FrontendSession from "src/frontend/session"
import {
  FrontendPermissionModule,
  FrontendPermissionSource,
} from "src/frontend/session/permissionModule"

export function useActivateLegalEntityAndEmployment(): () => Promise<void> {
  const {
    currentOrganization,
    currentOrganizationId,
    currentLegalEntity,
    currentLegalEntityId,
    doppelganger,
    isSharingContextActive,
    user,
  } = useSession<FrontendSession>()

  const loadingRef = React.useRef(false)

  const [activateEmployment] = useActivateCurrentUserEmploymentMutation({
    variables: { organizationId: currentOrganizationId || "" },
  })

  const [activateLegalEntity] = useActivateLegalEntityMutation({
    variables: { id: currentLegalEntityId },
  })

  const hasLegalEntityUpdatePermission = useHasPermission({
    source: FrontendPermissionSource.LegalEntity,
    module: FrontendPermissionModule.LegalEntities,
    flag: JWTPermissionFlag.Update,
  })

  return React.useCallback(() => {
    // If an activation request is in-flight, return immediately.
    if (loadingRef.current) return Promise.resolve()

    // Doppelgangers should not activate or change the user's employment.
    if (doppelganger) {
      return Promise.resolve()
    }

    // Return immediately if we don't have a current LE, or if we're currently in a
    // sharing context.
    if (!currentLegalEntity || isSharingContextActive) {
      return Promise.resolve()
    }

    TrackJS?.console.info("Counting Machine Activating Entity")
    const promises: Promise<FetchResult>[] = []
    if (currentLegalEntity.isApproved && hasLegalEntityUpdatePermission) {
      promises.push(
        activateLegalEntity()
          .then((activation) => {
            TrackJS?.console.info(`Successfully activated legal entity ${currentLegalEntityId}`)
            return activation
          })
          .catch((e) => {
            TrackJS?.console.info(`Failed to activate legal entity ${currentLegalEntityId}`, e)
            return e
          })
      )
    }

    if (!currentOrganization?.employmentActivated) {
      promises.push(
        activateEmployment()
          .then((val) => {
            TrackJS?.console.info(
              `User (${user.id}) employment successfully activated (${currentOrganizationId})`
            )
            return val
          })
          .catch((e) => {
            TrackJS?.console.info(`Failed to activate employment for user (${user.id})`, e)
            return e
          })
      )
    }

    loadingRef.current = !!promises.length
    return Promise.all(promises)
      .then(() => {
        if (loadingRef.current) {
          TrackJS?.console.info("Activations completed.")
        }
      })
      .finally(() => {
        loadingRef.current = false
      })
  }, [
    doppelganger,
    currentLegalEntity,
    isSharingContextActive,
    currentOrganization?.employmentActivated,
    activateLegalEntity,
    currentLegalEntityId,
    activateEmployment,
    user.id,
    currentOrganizationId,
    hasLegalEntityUpdatePermission,
  ])
}

export function usePerformActivateLegalEntityAndEmployment(skip: boolean) {
  const { currentLegalEntity, currentOrganization, isSharingContextActive } =
    useSession<FrontendSession>()
  const performActivations = useActivateLegalEntityAndEmployment()

  React.useEffect(() => {
    if (!currentLegalEntity || skip || isSharingContextActive) return
    performActivations().catch((e) => TrackJS?.track(e))
  }, [skip, currentLegalEntity, currentOrganization, isSharingContextActive, performActivations])
}
