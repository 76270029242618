import objectHelper from "@digits-shared/helpers/objectHelper"
import colors from "@digits-shared/themes/colors"
import DOMPurify from "dompurify"

// Specify CSS property whitelist
// NOTES:
//  NO backgrounds!: copy/paste issues
const ALLOWED_STYLES = [
  "align-items",
  "color",
  "display",
  "font-family",
  "font-size",
  "font-style",
  "font-weight",
  "letter-spacing",
  "margin",
  "margin-bottom",
  "margin-left",
  "margin-right",
  "margin-top",
  "padding",
  "padding-bottom",
  "padding-left",
  "padding-right",
  "padding-top",
  "text-align",
  "text-decoration-color",
  "text-decoration-style",
  "text-decoration-line",
  "text-decoration-thickness",
  "text-indent",
  "text-transform",
  "text-wrap",
  "white-space-collapse",
  "word-spacing",
]

const FORBIDDEN_STYLES: Record<string, string[]> = {
  color: [colors.white.toLowerCase(), "white", "#fff", "rgb(255, 255, 255)"],
}

const IGNORED_VALUES = ["initial", "0px", "normal"]

/**
 *  Take CSS property-value pairs and validate against white-list,
 *  then add the styles to an array of property-value pairs
 */
function validateStyles(output: string[], styles: CSSStyleDeclaration) {
  objectHelper.keysOf(styles).forEach((key) => {
    const normalizedKey = styles[key]
      ?.toString()
      ?.replace(/([A-Z])/g, "-$1")
      .toLowerCase()

    if (normalizedKey && ALLOWED_STYLES.includes(normalizedKey)) {
      const value = styles[normalizedKey as keyof CSSStyleDeclaration]
      if (typeof value !== "string") return

      const isForbidden = FORBIDDEN_STYLES[normalizedKey]?.includes(value.toLowerCase().trim())
      if (!IGNORED_VALUES.includes(value) && !isForbidden) {
        output.push(`${normalizedKey}:${value}`)
      }
    }
  })
}

// Add a hook to enforce CSS attribute sanitization
DOMPurify.addHook("afterSanitizeAttributes", (node: HTMLElement) => {
  // Nasty hack to fix baseURI + CSS problems in Chrome
  if (!node.ownerDocument.baseURI) {
    const base = document.createElement("base")
    base.href = document.baseURI
    node.ownerDocument.head.appendChild(base)
  }

  // Check all style attribute values and validate them
  if (node.hasAttribute("style")) {
    const output: string[] = []
    validateStyles(output, node.style)

    // re-add styles in case any are left
    if (output.length) {
      node.setAttribute("style", output.join(";"))
    } else {
      node.removeAttribute("style")
    }
  }
})

/**
 * This hook removes the anchor elements and replaces them with a placeholder
 */
const removeAnchors = (node: HTMLElement) => {
  if (node.tagName === "A") {
    const el = document.createElement("span")
    el.setAttribute("style", `color:${colors.orange}; font-weight: bold;`)
    el.innerText = "<LINK HERE>"
    node.replaceWith(el)
  }
}

interface SanitizeOptions {
  removeAnchors?: boolean
}

export default {
  sanitize(source: string | Node, opts: SanitizeOptions = {}) {
    if (opts.removeAnchors) {
      DOMPurify.addHook("uponSanitizeElement", removeAnchors)
    } else {
      DOMPurify.removeHooks("uponSanitizeElement")
    }

    return DOMPurify.sanitize(source, {
      RETURN_DOM_FRAGMENT: true,
      // allow all safe HTML elements but neither SVG nor MathML
      USE_PROFILES: { html: true },
      FORBID_TAGS: ["button", "input", "textarea", "script", "style"],

      CUSTOM_ELEMENT_HANDLING: {
        tagNameCheck: /^digits-entity/, // allow all tags starting with
        allowCustomizedBuiltInElements: true, // customized built-ins are allowed
      },
    })
  },
}
