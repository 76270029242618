import * as React from "react"
import { CategoryType } from "@digits-graphql/frontend/graphql-bearer"
import { InvertValuesContext } from "@digits-shared/components/Contexts/InvertValuesContext"
import { svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { DeltaChevron, DeltaContainer } from "@digits-shared/components/UI/Elements/Delta"
import { Amount } from "@digits-shared/components/UI/Table/Content"
import styled from "styled-components"
import { ComponentSize } from "src/frontend/components/Shared/Layout/ComponentSize"
import { useRemoveConfig } from "src/frontend/components/Shared/Portals/ConfigsSidebar/ComponentConfigContext"
import {
  Title,
  type TopEntitiesProps,
} from "src/frontend/components/Shared/Portals/ConfigsSidebar/Configs/Lists/shared"
import { useCategoriesListCountDisplay } from "src/frontend/components/Shared/Portals/hooks/useCategoriesListCountDisplay"
import { useTopCategoriesLiveData } from "src/frontend/components/Shared/Portals/hooks/useTopCategoriesLiveData"
import {
  CategoryIconStyled,
  TopCategoriesList,
} from "src/frontend/components/Shared/Reports/Report/Viewer/Layout/Shared/TopCategoriesList"
import { type CategoryTransactionSummary } from "src/frontend/types"
import {
  adjectiveForCategoryType,
  invertValuesForCategoryType,
} from "src/shared/helpers/categoryHelper"

const StyledTopCategoriesList = styled(TopCategoriesList)`
  ${CategoryIconStyled} {
    ${svgPathStyles("#0c404b", 1.5)}
  }

  ${Amount} {
    color: #0c404b;
  }

  ${DeltaContainer} {
    color: #0c404b99;
    ${DeltaChevron} {
      border-bottom-color: #0c404b99;
      border-top-color: #0c404b99;
    }
  }
`

/*
  COMPONENTS
*/

export const TopCategoriesConfig: React.FC<TopEntitiesProps> = ({ config, componentType }) => {
  const removeConfig = useRemoveConfig()

  const categoryTypeName = React.useMemo(
    () => adjectiveForCategoryType(config.categoryType),
    [config.categoryType]
  )

  const { data, loading } = useTopCategoriesLiveData(config, componentType)

  const invertValues = React.useMemo(
    () => invertValuesForCategoryType(config.categoryType ?? CategoryType.UnknownType),
    [config.categoryType]
  )

  const categorySummaries: CategoryTransactionSummary[] | undefined = React.useMemo(() => {
    if (!data) return undefined

    return data.entities.category.map((c) => ({
      category: c.categoryObject,
      summary: c.summary,
    }))
  }, [data])

  const countDisplay = useCategoriesListCountDisplay(
    categorySummaries,
    categoryTypeName,
    config.sort,
    invertValues
  )

  // remove this component if the results are empty
  React.useEffect(() => {
    if (categorySummaries && !categorySummaries?.length) {
      removeConfig()
    }
  }, [categorySummaries, removeConfig])

  if (!countDisplay) return null

  return (
    <InvertValuesContext.Provider value={invertValues}>
      <Title componentSize={ComponentSize.Small}>Top {countDisplay} </Title>
      <StyledTopCategoriesList categorySummaries={categorySummaries} loading={loading} />
    </InvertValuesContext.Provider>
  )
}
