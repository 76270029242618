import * as React from "react"

type TimerPause = () => number // returns remaining time in timer
type TimeoutTuple = [
  (...args: unknown[]) => void,
  React.RefObject<NodeJS.Timeout | null>,
  TimerPause,
]
type TimerCallback = (...args: unknown[]) => void

/**
 * This hook sets up a timer and clears it after unmounting.
 * It’s a combo of setTimeout and clearTimeout tied to the component lifecycle.
 *
 * Can only be used in React Functional Components since it uses `React.useEffect`
 * hook to tap into the components lifecycle.
 */
export default function useLazyTimeout(callback: TimerCallback, delay: number): TimeoutTuple {
  const start = React.useRef<number | null>(null)
  const remaining = React.useRef<number>(delay)
  const timerRef = React.useRef<NodeJS.Timeout | null>(null)
  const savedCallback = React.useRef<TimerCallback>(undefined)

  React.useEffect(() => {
    // Remember the latest callback.
    savedCallback.current = callback
  }, [callback])

  React.useEffect(
    () =>
      // Clear the timeout if unmounted
      () => {
        if (timerRef.current) clearTimeout(timerRef.current)
        timerRef.current = null
        start.current = null
      },
    []
  )

  const resume = React.useCallback((...args: unknown[]) => {
    start.current = Date.now()
    function tick() {
      savedCallback.current?.(...args)
    }
    if (timerRef.current) clearTimeout(timerRef.current)

    // Set up the timeout.
    timerRef.current = setTimeout(tick, remaining.current)
  }, [])

  const pause = React.useCallback(() => {
    if (timerRef.current) clearTimeout(timerRef.current)
    if (start.current) remaining.current -= Date.now() - start.current
    return remaining.current
  }, [])

  return [resume, timerRef, pause]
}
