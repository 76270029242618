import * as React from "react"
import {
  DirectionFromOrigin,
  type EntityCategory,
  type EntityLegalEntity,
  Interval,
  useSummarizeTransactionsByTimeQuery,
  type ViewType,
} from "@digits-graphql/frontend/graphql-bearer"
import { InvertValuesContext } from "@digits-shared/components/Contexts/InvertValuesContext"
import { LoadingBlock } from "@digits-shared/components/Loaders"
import { svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgChevronRight } from "@digits-shared/components/SVGIcons/line/ChevronRight.svg"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled from "styled-components"
import {
  SharedDimensionSummaryRow,
  SharedDimensionSummaryRowLink,
  SharedDimensionSummaryRowLoading,
} from "src/frontend/components/OS/Applications/SharedWithMe/Entities/EntityRows/Shared"
import { SparkSummaryChart } from "src/frontend/components/OS/Shared/Charts/SparkSummaryChart"
import {
  DimensionRowDelta,
  DimensionRowSparkChartSizing,
  DimensionRowTotals,
} from "src/frontend/components/OS/Shared/Dimensions/DimensionRow"
import { useFrontendPathGenerator } from "src/frontend/hooks/useFrontendPathGenerator"
import routes from "src/frontend/routes"
import { SVGIconComponent } from "src/shared/components/Icons/SVGIcon"
import { invertValuesForCategory } from "src/shared/helpers/categoryHelper"
import { DEFAULT_INTERVAL_COUNT_MONTH } from "src/shared/hooks/useIntervalOrigin"

/*
  STYLES
*/

const Icon = styled(SVGIconComponent)`
  width: 24px;
  height: 24px;
  ${svgPathStyles(colors.secondary, 1.5)};
  margin-right: 20px;
  margin-left: 20px;
`

const Name = styled.div`
  flex: 1;
  font-size: 13px;
  font-weight: ${fonts.weight.medium};
`

const PointingRightChevron = styled(SvgChevronRight)`
  width: 15px;
  height: 15px;
  ${svgPathStyles(colors.translucentSecondary20, 1.5)};
`

const RowAdditionalSummary = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50%;
  margin-right: 15px;
`

/*
  COMPONENTS
*/

export const SharedCategoryRow: React.FC<{
  legalEntity: EntityLegalEntity
  category: EntityCategory
  viewType: ViewType | null | undefined
}> = ({ legalEntity, category, viewType }) => {
  const generatePath = useFrontendPathGenerator()
  const { summaries, loading } = useCategoryTransactionSummary(
    legalEntity.id,
    category.id,
    viewType
  )

  if (!summaries && loading) {
    return <SharedCategoryRowLoading />
  }

  const currentSummary = summaries && summaries[summaries.length - 1]

  return (
    <InvertValuesContext.Provider value={invertValuesForCategory(category)}>
      <SharedDimensionSummaryRow>
        <SharedDimensionSummaryRowLink
          to={generatePath(routes.categoryDetails, {
            leSlug: legalEntity.slug,
            categoryId: category.id,
          })}
        >
          <Icon subjectDisplayKey={category.displayKey} />
          <Name>{category.name}</Name>

          <RowAdditionalSummary>
            <DimensionRowSparkChartSizing>
              <SparkSummaryChart summaries={summaries || []} animateChart />
            </DimensionRowSparkChartSizing>

            <DimensionRowDelta summary={currentSummary} />

            <DimensionRowTotals summary={currentSummary} />
          </RowAdditionalSummary>

          <PointingRightChevron />
        </SharedDimensionSummaryRowLink>
      </SharedDimensionSummaryRow>
    </InvertValuesContext.Provider>
  )
}

const SharedCategoryRowLoading: React.FC = () => (
  <SharedDimensionSummaryRow>
    <SharedDimensionSummaryRowLoading>
      <LoadingBlock width="24px" height="24px" margin="0px 20px 0px 0px" />
      <LoadingBlock width="400px" height="24px" margin="0px 20px 0px 0px" />

      <RowAdditionalSummary>
        <LoadingBlock width="145px" height="24px" />
        <DimensionRowDelta isLoading />
        <DimensionRowTotals isLoading />
      </RowAdditionalSummary>

      <PointingRightChevron />
    </SharedDimensionSummaryRowLoading>
  </SharedDimensionSummaryRow>
)

const useCategoryTransactionSummary = (
  legalEntityId: string,
  categoryId: string,
  viewType: ViewType | null | undefined
) => {
  const intervalOrigin = dateTimeHelper.todayIntervalOrigin(
    Interval.Month,
    DEFAULT_INTERVAL_COUNT_MONTH
  )
  const { loading, data } = useSummarizeTransactionsByTimeQuery({
    variables: {
      origin: intervalOrigin,
      filter: {
        viewKey: {
          legalEntityId,
          viewType: viewType,
        },
        categoryId,
        withoutCategoryAncestors: false,
      },
      direction: DirectionFromOrigin.Past,
    },
    context: { avsBatch: true },
  })

  const summaries = React.useMemo(
    () =>
      data?.dimensionalTransactionSummary.time
        .map((t) => t.summary)
        .sort((s1, s2) => s1.period.startedAt - s2.period.startedAt),
    [data?.dimensionalTransactionSummary.time]
  )

  return React.useMemo(() => ({ loading, summaries }), [loading, summaries])
}
