import React from "react"
import { SvgCheckCircle } from "@digits-shared/components/SVGIcons/line/CheckCircle.svg"
import { DigitsButton } from "@digits-shared/DesignSystem/Button"
import styled from "styled-components"
import { useResolveThread } from "src/shared/components/Comments/threadMutationHooks"
import { useStopClickPropagation } from "src/shared/hooks/useStopClickPropagation"
import useThreadContext from "./ThreadContext"

/*
 STYLES
*/

const ResolveRow = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`

const ResolveIcon = styled(SvgCheckCircle)`
  height: 22px;
  width: 22px;

  path {
    stroke: var(--main-color);
  }
`

const ResolveButton = styled(DigitsButton)`
  padding: 0 8px;
`

interface ResolveProps {
  threadId?: string
  className?: string
  disabled?: boolean | null
  buttonText?: string
}

export const Resolve: React.FC<React.PropsWithChildren<ResolveProps>> = ({
  threadId,
  className,
  buttonText,
  disabled,
  children,
}) => {
  const { onBeforeResolve, canComment } = useThreadContext()
  const { resolveThread, called } = useResolveThread()
  const stopPropagation = useStopClickPropagation({ preventDefault: true })

  const onClick = React.useCallback(
    async (event: React.MouseEvent<HTMLElement>) => {
      stopPropagation(event)
      if (threadId) {
        // transaction review relies on onBeforeResolve being called *before* resolveThread
        const result = await onBeforeResolve?.(threadId)
        if (result?.skipResolve) return
        await resolveThread(threadId)
      }
    },
    [stopPropagation, threadId, onBeforeResolve, resolveThread]
  )

  if (!canComment) return null

  return (
    <ResolveRow className={className}>
      {children}
      <ResolveButton
        $variant="secondary-dark"
        size="small"
        onClick={onClick}
        disabled={disabled || called}
      >
        <ResolveIcon /> {buttonText || "Resolve Thread"}
      </ResolveButton>
    </ResolveRow>
  )
}
